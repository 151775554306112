import {ContentContainer} from "../styledcomponents/Containers";
import React, {useEffect, useRef, useState} from "react";
import Text from "../styledcomponents/Text";
import {Colors, Fonts} from "../styledcomponents/Styles";
import {useMediaQuery} from "react-responsive";
import Input from "../styledcomponents/Input";
import Button from "../styledcomponents/Button";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import {UsersAPI} from "../network/UsersAPI";
import {ChangePasswordWithKeyRequestBody} from "../model/ChangePasswordWithKeyRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {RegexUtils} from "../constants/RegexUtils";
import {useTranslation} from "react-i18next";

/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
const InputErrorText = styled(Text)`
  margin-top: 4px;
  font-size: 12px;
  text-align: left;
  color: ${Colors.INPUT_ERROR};
`
const PasswordResetFragment = () => {
    const isMobile = useMediaQuery({
        query: "(max-width:767px)",
    });
    const {key} = useParams();
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const passwordRef = useRef<HTMLInputElement>(null)
    const [passwordCheck, setPasswordCheck] = useState("")
    const [passwordCheckError, setPasswordCheckError] = useState(false)
    const passwordCheckRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        if (!key || key.length === 0) {
            alert(t('alert_invalid_access'))
            navigate("/")
            return
        }
        UsersAPI.checkPasswordKey(key).then(response => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isSuccess()) {
                //유효 키
            } else if (responseCode.isPasswordResetKeyExpired()) {
                alert(t('alert_password_change_expired'))
                navigate("/")
            } else {
                alert(t('alert_invalid_access'))
                navigate("/")
            }

        }).catch((e) => {
            alert(t('alert_a_temporary_error_has_occurred'))
            navigate("/")
        })
    }, [key])

    function handleChangePassword() {
        if (!key || key.length === 0) {
            alert(t('alert_invalid_access'))
            navigate("/")
            return
        }
        if (!RegexUtils.checkPassword(password)) {
            setPasswordError(true)
            passwordRef?.current?.focus()
            return
        }
        if (password !== passwordCheck) {
            setPasswordCheckError(true)
            passwordCheckRef?.current?.focus()
            return;
        }
        UsersAPI.changePasswordWithKey(new ChangePasswordWithKeyRequestBody(
            key,
            password,
            passwordCheck
        )).then(response => {
            const responseCode = ResponseCodes.of(response.data.code)
            if (responseCode.isSuccess()) {
                alert(t('alert_password_has_been_changed'))
                navigate("/login")
            } else if (responseCode.isPasswordResetKeyExpired()) {
                alert(t('alert_password_change_expired'))
                navigate("/password-find")
            } else if (responseCode.isNotMatchingNewPasswordCheck()) {
                setPasswordCheckError(true)
            } else {
                alert(t('alert_a_temporary_error_has_occurred'))
            }

        }).catch((e) => {
            alert(t('alert_a_temporary_error_has_occurred'))
        })
    }

    return (
        <ContentContainer
            width={"100%"}
            maxWidth={"480px"}
            paddingLeft={"20px"}
            paddingRight={"20px"}
            alignItems={"flex-start"}
            onChange={() => {
                if (passwordError) {
                    setPasswordError(false)
                }
                if (passwordCheckError) {
                    setPasswordCheckError(false)
                }
            }}>
            <Text
                marginTop={"160px"}
                fontWeight={Fonts.WEIGHT_700}
                fontSize={"16px"}
                color={Colors.GRAY_900}>
                비밀번호 재설정
            </Text>
            <Text
                marginTop={"20px"}
                fontSize={isMobile ? "24px" : "32px"}
                color={Colors.GRAY_900}
                fontWeight={Fonts.WEIGHT_700}
                textAlign={"left"}>
                변경하실 비밀번호를<br/>입력하세요.
            </Text>
            <Text
                marginTop={"40px"}
                fontSize={"16px"}
                color={Colors.GRAY_900}
                textAlign={"left"}>
                새 비밀번호
            </Text>
            <Input
                marginTop={"20px"}
                width={"100%"}
                alignSelf={"center"}
                ref={passwordRef}
                error={passwordError}
                value={password}
                type="password"
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleChangePassword()
                    }
                }}
                onChange={(e) => {
                    setPassword(e.target.value)
                }}/>
            {passwordError && <InputErrorText>비밀번호 영문 대소문자, 숫자, 특수문자(.!@#$%)를 혼합하여 8~20자로입력해주세요.</InputErrorText>}
            <Text
                marginTop={"40px"}
                fontSize={"16px"}
                color={Colors.GRAY_900}
                textAlign={"left"}>
                새 비밀번호 확인
            </Text>
            <Input
                marginTop={"20px"}
                width={"100%"}
                ref={passwordCheckRef}
                error={passwordCheckError}
                alignSelf={"center"}
                type="password"
                value={passwordCheck}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleChangePassword()
                    }
                }}
                onChange={(e) => {
                    setPasswordCheck(e.target.value)
                }}/>
            {passwordCheckError && <InputErrorText>새 비밀번호와 일치하지 않습니다.</InputErrorText>}
            <Button
                marginTop={"56px"}
                width={"100%"}
                marginBottom={"200px"}
                alignSelf={"center"}
                onClick={handleChangePassword}>
                변경완료
            </Button>
        </ContentContainer>
    )
}
export default PasswordResetFragment;