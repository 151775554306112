import React, {MouseEvent, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../modules";
import {ContentContainer} from "../styledcomponents/Containers";
import FlexBox from "styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import styled from "styled-components";
import CommonComponent from "../styledcomponents/CommonComponent";
import {Colors, Fonts} from "../styledcomponents/Styles";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {useTranslation} from "react-i18next";
import {FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Shadows} from "@mui/material";
import {Configurations} from "../modules/Configurations";
import LanguageIcon from "@mui/icons-material/Language";
import NewH6 from "../styledcomponents/text/NewH6";
import NewButton from "../styledcomponents/NewButton";
import {Users} from "../modules/Users";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ExpandMoreOutlined} from "@mui/icons-material";

/**
 * @author jaeho.lee104 on 2023. 04. 08..
 */
const theme = createTheme({
    shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
    components: {
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: Colors.WHITE,
                    padding: "4px",
                    marginTop: "4px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
                    border: `1px solid ${Colors.GRAY_3}`
                }
            }
        },
        MuiList: {
            defaultProps: {
                sx: {
                    padding: "0px",
                }
            }
        },
    },
    palette: {
        action: {
            selected: Colors.MAIN,
        }
    }
});

interface NavigationItemProps {
    selected: boolean
}

const NavigationItem = styled(CommonComponent)<NavigationItemProps>`
    min-width: 60px;
    font-weight: ${({selected}) => selected ? Fonts.WEIGHT_700 : Fonts.WEIGHT_500};
    color: ${({selected}) => selected ? Colors.MAIN : Colors.GRAY_8};
    font-size: 16px;
    cursor: pointer;
    margin-right: 32px;

    &:hover {
        color: ${Colors.MAIN_LIGHT}
    }
`;

const PcAppBar = () => {
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const componentName = "PcAppBar"
    const {t} = useTranslation()

    function handleLoginOrLogout(event: MouseEvent<any>) {
        if (signedIn) {
            dispatch(Users.logout());
            navigate("/");

        } else {
            if (KeyboardUtils.isNewWindowTriggerKey(event)) {
                window.open("/login")
            } else {
                navigate("/login")
            }
        }
    }

    const LanguageMenu: React.FC = () => {
        return <FlexBox
            alignItems={"center"}
            height={"100%"}
            width={"160px"}>
            <ThemeProvider theme={theme}>
                <FormControl sx={{width: "100%"}}>
                    <Select
                        IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                        input={<OutlinedInput
                            style={{
                                height: "40px"
                            }}/>}
                        value={language}
                        renderValue={(value) => {
                            return <FlexBox
                                alignItems={"center"}>
                                <LanguageIcon
                                    sx={{
                                        marginRight: "4px",
                                        width: "24px",
                                        height: "24px",
                                        color: Colors.GRAY_8
                                    }}
                                />
                                <NewH6
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    overflow={"hidden"}
                                    color={Colors.GRAY_8}
                                    marginLeft={"4px"}>
                                    {t('lang_' + value)}
                                </NewH6>
                            </FlexBox>
                        }}
                        onChange={(e: SelectChangeEvent) => {
                            const selectedLanguage = e.target.value
                            if (language !== selectedLanguage) {
                                dispatch(Configurations.setLanguage(selectedLanguage))
                            }
                        }}
                        sx={{
                            color: Colors.GRAY_8,
                            fontSize: "14px",
                            borderRadius: "5px",
                            "&& fieldset": {
                                border: `1px solid ${Colors.GRAY_3}`,
                            },
                            "&:hover": {
                                "&& fieldset": {
                                    border: `1px solid ${Colors.CTA_HOVER}`
                                }
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `2px solid ${Colors.MAIN}`
                            },

                        }}>
                        <MenuItem
                            sx={{
                                margin: "4px",
                                fontSize: "14px",
                                borderRadius: "8px"
                            }}
                            value={"kr"}>{t('lang_kr')}</MenuItem>
                        <MenuItem
                            sx={{
                                margin: "4px",
                                fontSize: "14px",
                                borderRadius: "8px"
                            }}
                            value={"en"}>{t('lang_en')}</MenuItem>
                    </Select>
                </FormControl>
            </ThemeProvider>
        </FlexBox>
    }

    return (
        <>
            <FlexBox
                width={"100%"}>
                <ContentContainer
                    width={"100%"}
                    minWidth={"1680px"}
                    maxWidth={"1680px"}
                    paddingLeft={"24px"}
                    paddingRight={"24px"}>
                    <FlexBox alignItems={"center"}
                             justifyContent={"space-between"}
                             minHeight={"64px"}>
                        <Image
                            width={"205px"}
                            height={"20px"}
                            cursor={"pointer"}
                            src={require("assets/images/app-bar-logo.svg").default}
                            onClick={() => {
                                navigate("/")
                            }}
                            alignSelf={"center"}
                            alt="app-bar-logo"/>
                        <FlexBox
                            alignItems={"center"}>
                            <NewButton
                                width={"fit-content"}
                                marginRight={"20px"}
                                padding={"10px 16px"}
                                colorType={0}
                                borderRadius={"8px"}
                                minHeight={"44px"}
                                alignSelf={"center"}
                                height={"44px"}
                                onClick={(e) => handleLoginOrLogout(e)}>
                                {signedIn ? t("logout") : t("login")}
                            </NewButton>
                            <LanguageMenu/>
                        </FlexBox>
                    </FlexBox>
                </ContentContainer>
            </FlexBox>
        </>
    )
}
export default PcAppBar;