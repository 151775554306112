/**
 * @author jaeho.lee104 on 2024. 03. 22..
 */
export const BusinessUserState = {
    INIT: "INIT",
    ACQUIRED: "ACQUIRED",
    REVIEWING: "REVIEWING",
    NOT_REGISTERED: "NOT_REGISTERED",
    UNKNOWN: "UNKNOWN"
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BusinessUserState = typeof BusinessUserState[keyof typeof BusinessUserState];