/**
 * @author jaeho.lee104 on 2023. 08. 25..
 */
import React, {useEffect, useRef, useState} from "react";
import {Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import NewButton from "../styledcomponents/NewButton";
import {useTranslation} from "react-i18next";
import NewP1 from "../styledcomponents/text/NewP1";
import {Colors} from "../styledcomponents/Styles";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Input from "../styledcomponents/Input";
import styled from "styled-components";
import Text from "../styledcomponents/Text";
import {RegexUtils} from "../constants/RegexUtils";
import {UsersAPI} from "../network/UsersAPI";
import {SignInRequestBody} from "../model/SignInRequestBody";
import {ResponseCodes} from "../network/ResponseCodes";
import {SolutionAPI} from "../network/SolutionAPI";
import {Users} from "../modules/Users";
import NewH3 from "../styledcomponents/text/NewH3";
import {LocalStorageManager} from "../LocalStorageManager";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface LoginDialogProps {
    isOpen: boolean
    onClose: (isLoginSuccess: Boolean) => void
}

const lockClosePath = require(`assets/images/ic-lock-close.svg`).default
const lockOpenPath = require(`assets/images/ic-lock-open.svg`).default

const InputErrorText = styled(Text)`
  margin-top: 6px;
  font-size: 12px;
  text-align: left;
  color: ${Colors.INPUT_ERROR};
`

const LoginDialog: React.FC<{
    dialogProps: LoginDialogProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [password, setPassword] = useState("")
    const [lockClose, setLockClose] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const passwordRef = useRef<HTMLInputElement>(null)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const emailRef = useRef<HTMLInputElement>(null)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();


    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
        if (!dialogProps.isOpen) {
            setPassword("")
            setPasswordError(false)
            setLockClose(false)
            setEmail("")
            setEmailError(false)
        }
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        handleLoginButtonClicked()
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    function handleLoginButtonClicked() {
        if (!RegexUtils.checkEmail(email)) {
            setEmailError(true)
            emailRef?.current?.focus()
            return;
        }
        UsersAPI.signIn(new SignInRequestBody(email, password))
            .then(async (response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                const result = response.data.data
                const accessToken = result?.accessToken
                const userId = result?.userId
                if (responseCode.isSuccess()) {
                    if (!userId || !accessToken) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }
                    LocalStorageManager.setAccessToken(accessToken)
                    const user = await UsersAPI.getUserOrNull()
                    if (!user) {
                        alert(t('alert_a_temporary_error_has_occurred'))
                        return
                    }

                    const businessUserState = await SolutionAPI.getBusinessUserState()
                    dispatch(Users.loginSuccess(
                        "dialog",
                        user,
                        businessUserState,
                        accessToken
                    ))
                    dialogProps.onClose(true);

                } else if (responseCode.isNotMatchingPassword()) {
                    alert(t('alert_password_is_incorrect'))
                } else if (responseCode.isNotFoundUserId()) {
                    alert(t('alert_email_is_not_registered'))
                } else {
                    alert(t('alert_failed_to_log_in'))
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    return <>
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    padding={"32px"}
                    flexDirection={"column"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            {t('do_login')}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP1
                        color={Colors.GRAY_7}
                        marginTop={"24px"}>{t('business_email')}</NewP1>
                    <FlexBox
                        width={"100%"}
                        alignSelf={"center"}
                        marginTop={"8px"}>
                        <Input
                            paddingRight={"48px"}
                            width={"100%"}
                            fontWeight={700}
                            lineHeight={"150%"}
                            ref={emailRef}
                            error={emailError}
                            value={email}
                            type="text"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                        {email && <Image
                            width={"24px"}
                            height={"24px"}
                            position={"absolute"}
                            right={"16px"}
                            alignSelf={"center"}
                            src={require("assets/images/ic-close-circle.svg").default}
                            cursor={"pointer"}
                            onClick={() => {
                                setEmail("")
                            }}
                            alt="email-clear-button"/>}
                    </FlexBox>
                    {emailError && <InputErrorText>{t('invalid_email_format')}</InputErrorText>}
                    <NewP1
                        color={Colors.GRAY_7}
                        marginTop={"20px"}>{t('password')}</NewP1>
                    <FlexBox
                        width={"100%"}
                        alignSelf={"center"}
                        marginTop={"8px"}>
                        <Input
                            paddingRight={"48px"}
                            width={"100%"}
                            fontWeight={700}
                            lineHeight={"150%"}
                            ref={passwordRef}
                            error={passwordError}
                            value={password}
                            type={lockClose ? "text" : "password"}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                        <Image
                            maxWidth={"24px"}
                            width={"24px"}
                            maxHeight={"24px"}
                            height={"24px"}
                            position={"absolute"}
                            right={"16px"}
                            alignSelf={"center"}
                            cursor={"pointer"}
                            src={lockClose ? lockOpenPath : lockClosePath}
                            onClick={() => {
                                setLockClose(!lockClose)
                            }}
                            alt="password-lock-icon"/>

                    </FlexBox>
                    {passwordError && <InputErrorText>{t('invalid_password_format')}</InputErrorText>}
                    <NewP1
                        color={Colors.MAIN}
                        alignSelf={"flex-end"}
                        cursor={"pointer"}
                        onClick={() => {
                            navigate("/password-find")
                        }}
                        marginTop={"8px"}>{t('forget_password')}</NewP1>
                    <NewButton
                        borderRadius={"12px"}
                        colorType={0}
                        marginTop={"24px"}
                        fontSize={"16px"}
                        width={"100%"}
                        height={"52px"}
                        marginBottom={"16px"}
                        onClick={() => handleOk()}>
                        {t('login')}
                    </NewButton>
                </FlexBox>
            </Dialog>
        </ThemeProvider>
    </>
}

export default LoginDialog