import {BusinessType} from "./BusinessType";
import {CategoryModel} from "./CategoryModel";
import {CertificationModel} from "./CertificationModel";
import {CustomAuthNumber} from "./CustomAuthNumber";
import {ExportHistory} from "./ExportHistory";
import {MainMarket} from "./MainMarket";
import {CompanyStatus} from "./CompanyStatus";

/**
 * @author jaeho.lee104 on 2023. 11. 28..
 */
export type CompanyDetailSaveModel = {
    statusCode: string,
    address: string,
    annualRevenue: string,
    annualOutputCapacity: string,
    businessTypeList: Array<BusinessType>,
    capacityCountry: string,
    categoryList: Array<CategoryModel>,
    certificatesList: Array<CertificationModel>,
    companyNm: string,
    companyOverview: string,
    country: string,
    customAuthNumList: Array<CustomAuthNumber>,
    customAuthYn: string,
    employeeCount: number,
    establishedYear: string,
    exportHistoryList: Array<ExportHistory>,
    incoterms: string,
    mainMarketList: Array<MainMarket>,
    nearestPort: string,
    productionCapacity: string,
    productionCapacityUnitCd: string,
    productionFactoryCountryCd: string,
    productionFactoryNm: string,
    rdCapacity: string,
    exportExperience: boolean,
    termsOfPayment: string
}

export const EMPTY_COMPANY_DETAIL_SAVE_MODEL: CompanyDetailSaveModel = {
    statusCode: CompanyStatus.TEMP_DRAFT,
    address: "",
    annualRevenue: "",
    annualOutputCapacity: "",
    businessTypeList: [],
    capacityCountry: "",
    categoryList: [],
    certificatesList: [],
    companyNm: "",
    companyOverview: "",
    country: "",
    customAuthNumList: [],
    customAuthYn: "N",
    employeeCount: 0,
    establishedYear: "",
    exportHistoryList: [
        {
            countryCd: "",
            exportPercent: ""
        },
        {
            countryCd: "",
            exportPercent: ""
        },
        {
            countryCd: "",
            exportPercent: ""
        },
        {
            countryCd: "",
            exportPercent: ""
        },
        {
            countryCd: "",
            exportPercent: ""
        }
    ],
    incoterms: "",
    mainMarketList: [],
    nearestPort: "",
    productionCapacity: "",
    productionCapacityUnitCd: "",
    productionFactoryCountryCd: "",
    productionFactoryNm: "",
    rdCapacity: "",
    exportExperience: false,
    termsOfPayment: ""
}