import React, {useEffect} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import "App.css";
import "Common.css";
import NotFoundFragment from "./fragments/NotFoundFragment";
import PcAppBar from "./components/PcAppBar";
import "bootstrap/dist/css/bootstrap.css";
import NewLoginFragment from "./fragments/NewLoginFragment";
import MainFooter from "./components/MainFooter";
import MultiLanguageGateway from "./MultiLanguageGateway";
import CopyrightPolicyFragment from "./fragments/CopyrightPolicyFragment";
import EmailPolicyFragment from "./fragments/EmailPolicyFragment";
import NewHomeFragment from "./fragments/NewHomeFragment";
import {useDispatch} from "react-redux";
import CompanyUserSignUpFirstFragment from "./fragments/CompanyUserSignUpFirstFragment";
import CompanyUserSignUpSecondFragment from "./fragments/CompanyUserSignUpSecondFragment";
import CompanySignUpDoneFragment from "./fragments/CompanySignUpDoneFragment";
import PasswordFindFragment from "./fragments/PasswordFindFragment";
import PasswordResetFragment from "./fragments/PasswordResetFragment";
import {NetworkCommon} from "./network/NetworkCommon";

const App = () => {

    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    useEffect(() => {
        NetworkCommon.initialize(dispatch, navigate)
    }, [])

    return (
        <div id="app-root" className="App">
            <PcAppBar/>
            <div className="App-content">
                <Routes>
                    <Route path="/home" element={<NewHomeFragment/>}/>
                    <Route path="/terms-of-service" element={<MultiLanguageGateway/>}/>
                    <Route path="/privacy-policy" element={<MultiLanguageGateway/>}/>
                    <Route path="/copyright-policy" element={<CopyrightPolicyFragment/>}/>
                    <Route path="/email-policy" element={<EmailPolicyFragment/>}/>
                    <Route path="/login" element={<NewLoginFragment/>}/>
                    <Route path="/signup1" element={<CompanyUserSignUpFirstFragment/>}/>
                    <Route path="/signup2" element={<CompanyUserSignUpSecondFragment/>}/>
                    <Route path="/company-signup-done" element={<CompanySignUpDoneFragment/>}/>
                    <Route path="/password-find" element={<PasswordFindFragment/>}/>
                    <Route path="/password_reset/:key" element={<PasswordResetFragment/>}/>
                    <Route path="/" element={<NewHomeFragment/>}/>
                    <Route path="*" element={<NotFoundFragment/>}/>
                </Routes>
            </div>
            <MainFooter/>
        </div>
    );
};

export default App;
