import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {ContentContainer} from "../styledcomponents/Containers";
import Text from "../styledcomponents/Text";
import HorizontalDivider from "../styledcomponents/HorizontalDivider";
import styled from "styled-components";
import {Colors} from "../styledcomponents/Styles";
import Input from "../styledcomponents/Input";
import FlexBox from "../styledcomponents/FlexBox";
import useMobile from "../uses/UseMobile";
import Button from "../styledcomponents/Button";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {ResponseCodes} from "../network/ResponseCodes";
import {CountryModel, DEFAULT_COUNTRIES} from "../model/CountryModel";
import {CommonApi} from "../network/CommonApi";
import {useTranslation} from "react-i18next";
import NewH2 from "../styledcomponents/text/NewH2";
import NewP2 from "../styledcomponents/text/NewP2";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import NewH5 from "../styledcomponents/text/NewH5";
import TextField from "../components/TextField";
import {NewRadioItem} from "../components/NewRadioItem";
import {Checkbox, Popover} from "antd";
import Li from "../styledcomponents/Li";
import Image from "../styledcomponents/Image";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {StringConstants} from "../constants/StringConstants";
import NewButton from "../styledcomponents/NewButton";
import {FileUtils} from "../utils/FileUtils";
import {UsersAPI} from "../network/UsersAPI";
import {CompanyUserModel} from "../model/CompanyUserModel";
import {useScript} from "../uses/UseScript";
import {AuthenticateCompanyModel} from "../model/AuthenticateCompanyModel";
import {CompanyAPI} from "../network/CompanyAPI";

const InputErrorText = styled(Text)`
    margin-top: 4px;
    font-size: 12px;
    text-align: left;
    color: ${Colors.INPUT_ERROR};
`

const CompanyUserSignUpSecondFragment = () => {

    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);

    const [countryCode, setCountryCode] = useState<string>("KR")
    const [companyName, setCompanyName] = useState("")
    const [companyNameError, setCompanyNameError] = useState(false)
    const companyNameRef = useRef<HTMLInputElement>(null)

    const [businessNumber, setBusinessNumber] = useState("")
    const [businessNumberError, setBusinessNumberError] = useState(false)
    const businessNumberRef = useRef<HTMLInputElement>(null)

    const [businessLicenseType, setBusinessLicenseType] = useState("COR")

    const [authenticatedTradeNumberSelectedRadio, setAuthenticatedTradeNumberSelectedRadio] = useState('N');
    const [authenticatedTradeNumberType, setAuthenticatedTradeNumberType] = useState('P')
    const [authenticatedTradeNumber, setAuthenticatedTradeNumber] = useState("")

    const [representName, setRepresentName] = useState("")
    const [representNameError, setRepresentNameError] = useState(false)

    const [websiteAddress, setWebsiteAddress] = useState("")
    const [websiteAddressError, setWebsiteAddressError] = useState(false)

    const [companyAddress, setCompanyAddress] = useState("")
    const [companyAddressError, setCompanyAddressError] = useState(false)
    const companyAddressRef = useRef<HTMLInputElement>(null)
    const [detailAddress, setDetailAddress] = useState("")

    const [tradeExperienceSelectedRadio, setTradeExperienceSelectedRadio] = useState(1);

    const [termsOfServiceAgreeChecked, setTermsOfServiceAgreeChecked] = useState(false);

    const [majorTradeCountry, setMajorTradeCountry] = useState("")

    const [countries, setCountries] = useState<Array<CountryModel>>(DEFAULT_COUNTRIES)

    const {state} = useLocation();
    const navigate = useNavigate()
    const isMobile = useMobile()

    const {t} = useTranslation()
    const countryLabel = t('country')
    const typeLabel = t('type')

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);

    const searchAddressScript = useScript('//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js')

    const dragRef = useRef<HTMLLabelElement | null>(null);
    const onChangeFiles = (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFile: File | null;
        if (e.type === "drop") {
            selectFile = e.dataTransfer && e.dataTransfer.files ? e.dataTransfer.files[0] : null;
        } else {
            selectFile = e.target.files ? e.target.files[0] : null;
            e.target.value = null
        }
        if (selectFile) {
            setFile(selectFile)
        }
    }

    const handleDragIn = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const handleDragOut = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(false);
    }, []);

    const handleDragOver = useCallback((e: DragEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer!.files) {
            setIsDragging(true);
        }
    }, []);

    const handleDrop = useCallback(
        (e: DragEvent): void => {
            e.preventDefault();
            e.stopPropagation();

            onChangeFiles(e);
            setIsDragging(false);
        },
        []
    );

    const initDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.addEventListener("dragenter", handleDragIn);
            dragRef.current.addEventListener("dragleave", handleDragOut);
            dragRef.current.addEventListener("dragover", handleDragOver);
            dragRef.current.addEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    const resetDragEvents = useCallback((): void => {
        if (dragRef.current !== null) {
            dragRef.current.removeEventListener("dragenter", handleDragIn);
            dragRef.current.removeEventListener("dragleave", handleDragOut);
            dragRef.current.removeEventListener("dragover", handleDragOver);
            dragRef.current.removeEventListener("drop", handleDrop);
        }
    }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

    useEffect(() => {
        initDragEvents();

        return () => resetDragEvents();
    }, [initDragEvents, resetDragEvents]);

    useEffect(() => {
        CommonApi.getCountries(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries)
                }
            })
    }, [language])

    function openSearchAddress() {
        //카카오 지도 발생
        // @ts-ignore
        new daum.Postcode({
            // @ts-ignore
            oncomplete: function (data) { //선택시 입력값 세팅
                setCompanyAddress(data.address)
            }
        }).open();
    }

    function handleRegisterCompleteClicked() {
        if (companyName.length === 0) {
            setCompanyNameError(true)
            companyNameRef?.current?.focus()
            return;
        }
        if (businessNumber.length === 0) {
            setBusinessNumberError(true)
            businessNumberRef?.current?.focus()
            return;
        }
        if (representName.length === 0) {
            setRepresentNameError(true)
            return;
        }
        if (companyAddress.length === 0) {
            setCompanyAddressError(true)
            companyAddressRef?.current?.focus()
            return;
        }
        if (!file) {
            alert("required add file")
            return;
        }
        if (!termsOfServiceAgreeChecked) {
            alert(t('alert_please_agree_to_the_terms_of_service_and_privacy_policy'))
            return
        }
        if (signedIn) {
            let authenticateCompanyModel = new AuthenticateCompanyModel(
                companyName,
                companyAddress + (detailAddress.length > 0 ? " " + detailAddress : ""),
                authenticatedTradeNumberType,
                authenticatedTradeNumber,
                authenticatedTradeNumberSelectedRadio,
                (tradeExperienceSelectedRadio === 0),
                countryCode,
                businessLicenseType,
                businessNumber,
                majorTradeCountry,
                websiteAddress
            )
            CompanyAPI.authenticateCompany(authenticateCompanyModel, file).then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    navigate("/company-signup-done")
                } else {
                    alert(response.data.code + response.data.message)
                }
            })
        } else {
            let companyUserModel = new CompanyUserModel(
                state.userId,
                state.password,
                state.userName,
                state.phoneNumber,
                state.countryNumber,
                companyName,
                companyAddress + (detailAddress.length > 0 ? " " + detailAddress : ""),
                authenticatedTradeNumberType,
                authenticatedTradeNumber,
                authenticatedTradeNumberSelectedRadio,
                (tradeExperienceSelectedRadio === 0),
                countryCode,
                businessLicenseType,
                businessNumber,
                majorTradeCountry,
                websiteAddress
            )
            UsersAPI.signUpCompany(companyUserModel, file).then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    navigate("/company-signup-done")
                } else {
                    alert(response.data.code + response.data.message)
                }
            })
        }
    }

    return (
        <ContentContainer width={"100%"}
                          padding={"0px 20px"}
                          alignItems={"center"}>
            <FlexBox
                maxWidth={"480px"}
                width={"100%"}
                flexDirection={"column"}>

                <NewH2 marginTop={"64px"}
                       color={Colors.GRAY_8}>
                    {signedIn ? t('company_user_auth') : t('company_user_register')}
                </NewH2>
                <FlexBox
                    marginTop={"40px"}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.GRAY_7}>
                            1
                        </NewH2>
                    </FlexBox>
                    <HorizontalDivider
                        width={"100%"}
                        margin={"0px 16px"}
                        height={"1px"}
                        color={Colors.GRAY_3}/>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.MAIN}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.WHITE}>
                            2
                        </NewH2>
                    </FlexBox>
                    <HorizontalDivider
                        width={"100%"}
                        margin={"0px 16px"}
                        height={"1px"}
                        backgroundColor={Colors.GRAY_3}/>
                    <FlexBox
                        minWidth={"64px"}
                        minHeight={"64px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        borderRadius={"100px"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <NewH2
                            color={Colors.GRAY_7}>
                            3
                        </NewH2>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginTop={"12px"}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {t('user_info')}
                    </NewH5>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {t('company_info')}
                    </NewH5>
                    <NewH5
                        width={"64px"}
                        color={Colors.GRAY_7}>
                        {signedIn ? t('request_finish') : t('register_finish')}
                    </NewH5>
                </FlexBox>
                <NewH5
                    marginTop={"34px"}
                    color={Colors.GRAY_7}>
                    <span style={{color: Colors.RED}}>*</span> {t('company_name')}</NewH5>
                <FlexBox width={"100%"}
                         marginTop={"20px"}
                         alignItems={"center"}
                         flexDirection={isMobile ? "column" : "row"}>

                    <FormControl sx={{
                        minWidth: 120,
                        width: isMobile ? "100%" : undefined,
                        alignSelf: "flex-start"
                    }}>
                        <InputLabel
                            sx={{fontSize: "16px"}}
                            id="select-country-label-id">{countryLabel}</InputLabel>
                        <Select
                            IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                            labelId={"select-country-label-id"}
                            input={<OutlinedInput
                                sx={{fontSize: "16px"}}
                                label={countryLabel}/>}
                            value={countryCode}
                            onChange={(e: SelectChangeEvent<typeof countryCode>) => {
                                setCountryCode(e.target.value)
                            }}
                            sx={{
                                fontSize: "16px",
                                borderRadius: "12px",
                                color: Colors.GRAY_8,
                                fontWeight: 500,
                                height: "52px",
                                "&& fieldset": {
                                    border: `1px solid ${Colors.GRAY_4}`
                                },
                                "&:hover": {
                                    "&& fieldset": {
                                        border: `1px solid ${Colors.CTA_HOVER}`
                                    }
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: `2px solid ${Colors.MAIN}`
                                },

                            }}>
                            {countries.map((country) => (
                                <MenuItem
                                    sx={{fontSize: "16px"}}
                                    value={country.countryCode}>{country.countryName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Input
                        autoComplete={"off"}
                        ref={companyNameRef}
                        width={"100%"}
                        alignSelf={"center"}
                        error={companyNameError}
                        marginLeft={isMobile ? "0px" : "10px"}
                        marginTop={isMobile ? "10px" : "0px"}
                        onChange={(e) => {
                            if (companyNameError) {
                                setCompanyNameError(false)
                            }
                            setCompanyName(e.target.value)
                        }
                        }
                        type={"text"}
                        value={companyName}
                    />
                </FlexBox>
                <NewP2
                    color={companyNameError ? Colors.INPUT_ERROR : Colors.GRAY_6}
                    marginTop={"8px"}
                    marginLeft={isMobile ? "0px" : "132px"}
                    textAlign={"left"}>{t('enter_your_company_name_from_business_registration')}</NewP2>
                <NewH5
                    marginTop={"34px"}
                    color={Colors.GRAY_7}>
                    <span style={{color: Colors.RED}}>*</span> {t('business_number')}</NewH5>
                <FlexBox width={"100%"}
                         marginTop={"20px"}
                         alignItems={"center"}
                         flexDirection={isMobile ? "column" : "row"}>

                    <FormControl sx={{
                        minWidth: 120,
                        width: isMobile ? "100%" : undefined,
                        alignSelf: "flex-start"
                    }}>
                        <InputLabel
                            sx={{fontSize: "16px"}}
                            id="select-company-type-label-id">{typeLabel}</InputLabel>
                        <Select
                            IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                            labelId={"select-company-type-label-id"}
                            input={<OutlinedInput
                                sx={{fontSize: "16px"}}
                                label={typeLabel}/>}
                            value={businessLicenseType}
                            onChange={(e: SelectChangeEvent<typeof countryCode>) => {
                                setBusinessLicenseType(e.target.value)
                            }}
                            sx={{
                                fontSize: "16px",
                                borderRadius: "12px",
                                color: Colors.GRAY_8,
                                fontWeight: 500,
                                height: "52px",
                                "&& fieldset": {
                                    border: `1px solid ${Colors.GRAY_4}`
                                },
                                "&:hover": {
                                    "&& fieldset": {
                                        border: `1px solid ${Colors.CTA_HOVER}`
                                    }
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: `2px solid ${Colors.MAIN}`
                                },

                            }}>
                            <MenuItem
                                sx={{fontSize: "16px"}}
                                value={"COR"}>{t('corporation')}</MenuItem>
                            <MenuItem
                                sx={{fontSize: "16px"}}
                                value={"PER"}>{t('person')}</MenuItem>
                        </Select>
                    </FormControl>
                    <Input
                        autoComplete={"off"}
                        ref={businessNumberRef}
                        width={"100%"}
                        alignSelf={"center"}
                        error={businessNumberError}
                        marginLeft={isMobile ? "0px" : "10px"}
                        marginTop={isMobile ? "10px" : "0px"}
                        onChange={(e) => {
                            if (businessNumberError) {
                                setBusinessNumberError(false)
                            }
                            setBusinessNumber(KeyboardUtils.clearNumber(e.target.value))
                        }
                        }
                        type={"text"}
                        value={businessNumber}
                    />
                </FlexBox>
                {businessNumberError && <InputErrorText
                    marginLeft={isMobile ? "0px" : "132px"}>{t('enter_business_number')}</InputErrorText>}
                <TextField
                    required={true}
                    width={"100%"}
                    label={`${t('represent_name')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    error={representNameError}
                    errorText={`${t('please_enter_represent_name')}`}
                    value={representName}
                    onChange={(e) => {
                        if (representNameError) {
                            setRepresentNameError(false)
                        }
                        setRepresentName(e.target.value)
                    }}
                    type={"text"}
                />
                <NewH5
                    marginTop={"34px"}
                    color={Colors.GRAY_7}><span style={{color: Colors.RED}}>* </span>{t('company_address')}</NewH5>
                <FlexBox width={"100%"}
                         marginTop={"16px"}
                         alignItems={"center"}
                         flexDirection={isMobile ? "column" : "row"}>
                    <Input
                        autoComplete={"off"}
                        ref={companyAddressRef}
                        width={"100%"}
                        alignSelf={"center"}
                        error={companyAddressError}
                        onClick={() => {
                            if (language === 'kr') {
                                openSearchAddress()
                            }
                        }}
                        onChange={(e) => {
                            if (companyAddressError) {
                                setCompanyAddressError(false)
                            }
                            setCompanyAddress(e.target.value)
                        }}
                        type={"text"}
                        value={companyAddress}
                    />
                    <Button
                        visible={language === 'kr'}
                        width={isMobile ? "100%" : undefined}
                        minWidth={"90px"}
                        fontSize={"16px"}
                        paddingLeft={"10px"}
                        paddingRight={"10px"}
                        backgroundColor={Colors.SECONDARY_HOVER}
                        border={`1px solid ${Colors.SECONDARY_PRESSED}`}
                        backgroundOnDisabled={Colors.GRAY_1}
                        colorOnDisabled={Colors.GRAY_5}
                        fontWeight={500}
                        color={Colors.MAIN}
                        marginTop={isMobile ? "10px" : "0px"}
                        alignSelf={"flex-start"}
                        marginLeft={isMobile ? "0px" : "10px"}
                        onClick={() => {
                            openSearchAddress()
                        }}>
                        {t('search_address')}
                    </Button>
                </FlexBox>
                <TextField
                    visible={companyAddress.length > 0 && language === 'kr'}
                    width={"100%"}
                    label={`${t('detail_address')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"20px"}
                    value={detailAddress}
                    onChange={(e) => {
                        setDetailAddress(e.target.value)
                    }}
                    type={"text"}
                />
                <NewP2
                    color={companyAddressError ? Colors.INPUT_ERROR : Colors.GRAY_6}
                    marginTop={"8px"}
                    textAlign={"left"}>{t('hint_enter_authenticated_address')}</NewP2>
                <TextField
                    width={"100%"}
                    label={`${t('website_address')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    error={websiteAddressError}
                    errorText={`${t('please_enter_website_address')}`}
                    value={websiteAddress}
                    onChange={(e) => {
                        if (websiteAddressError) {
                            setWebsiteAddressError(false)
                        }
                        setWebsiteAddress(e.target.value)
                    }}
                    type={"text"}
                />
                <NewH5
                    marginTop={"34px"}
                    color={Colors.GRAY_7}>{t('trading_experience_yn')}</NewH5>
                <FlexBox
                    marginTop={"16px"}>
                    <NewRadioItem
                        title={t('any')}
                        checked={tradeExperienceSelectedRadio === 0}
                        onClick={() => {
                            setTradeExperienceSelectedRadio(0)
                        }}/>
                    <NewRadioItem
                        marginLeft={"16px"}
                        title={t('none')}
                        onClick={() => {
                            setTradeExperienceSelectedRadio(1)
                        }}
                        checked={tradeExperienceSelectedRadio === 1}/>
                </FlexBox>
                <TextField
                    visible={tradeExperienceSelectedRadio === 0}
                    width={"100%"}
                    label={`${t('major_trade_country')}`}
                    labelFontWight={700}
                    labelFontSize={"16px"}
                    marginTop={"34px"}
                    value={majorTradeCountry}
                    hint={`${t('hint_major_trade_country')}`}
                    onChange={(e) => {
                        setMajorTradeCountry(e.target.value)
                    }}
                    type={"text"}
                />
                <FlexBox
                    flexDirection={"row"}
                    alignItems={"center"}
                    marginTop={"34px"}>
                    <NewH5 color={Colors.GRAY_7}>{t('authenticated_trader_yn')}</NewH5>
                    <Popover
                        placement={"top"}
                        trigger="click"
                        content={
                            <FlexBox flexDirection={"column"}
                                     margin={"10px"}
                                     color={Colors.MAIN}>
                                <Text>
                                    {t('authenticated_trade_number_description')}
                                </Text>
                                <ul
                                    style={{
                                        paddingLeft: "20px"
                                    }}>
                                    <Li
                                        fontSize={"14px"}
                                        marginTop={"10px"}>
                                        {t('authenticated_trade_number_yes_description')}
                                    </Li>
                                    <Li
                                        fontSize={"14px"}
                                        marginTop={"10px"}>
                                        {t('authenticated_trade_number_no_description')}
                                    </Li>
                                    <Li
                                        fontSize={"14px"}
                                        marginTop={"10px"}>
                                        {t('authenticated_trade_number_na_description')}
                                    </Li>
                                </ul>
                            </FlexBox>}>
                        <Image
                            cursor={"pointer"}
                            marginLeft={"8px"}
                            width={"20px"}
                            height={"20px"}
                            src={require(`assets/images/ic-info-20.svg`).default}
                            alt="ic-info"/>
                    </Popover>
                </FlexBox>
                <FlexBox
                    marginTop={"16px"}
                    flexDirection={isMobile ? "column" : "row"}>
                    <NewRadioItem
                        title={t('any')}
                        checked={authenticatedTradeNumberSelectedRadio === 'Y'}
                        onClick={() => {
                            setAuthenticatedTradeNumberSelectedRadio('Y')
                        }}/>
                    <NewRadioItem
                        marginLeft={isMobile ? "0px" : "16px"}
                        marginTop={isMobile ? "16px" : "0px"}
                        title={t('none')}
                        onClick={() => {
                            setAuthenticatedTradeNumberSelectedRadio('N')
                        }}
                        checked={authenticatedTradeNumberSelectedRadio === 'N'}/>
                    <NewRadioItem
                        marginLeft={isMobile ? "0px" : "16px"}
                        marginTop={isMobile ? "16px" : "0px"}
                        title={t('no_relation')}
                        onClick={() => {
                            setAuthenticatedTradeNumberSelectedRadio('NA')
                        }}
                        checked={authenticatedTradeNumberSelectedRadio === 'NA'}/>
                </FlexBox>
                <NewH5
                    visible={authenticatedTradeNumberSelectedRadio === 'Y'}
                    marginTop={"34px"}
                    color={Colors.GRAY_7}>{t('authenticated_trader_number')}</NewH5>
                <FlexBox width={"100%"}
                         visible={authenticatedTradeNumberSelectedRadio === 'Y'}
                         marginTop={"20px"}
                         alignItems={"center"}
                         flexDirection={isMobile ? "column" : "row"}>

                    <FormControl sx={{
                        minWidth: 120,
                        width: isMobile ? "100%" : undefined,
                        alignSelf: "flex-start"
                    }}>
                        <InputLabel
                            sx={{fontSize: "16px"}}
                            id="select-auth-trader-type-label-id">{typeLabel}</InputLabel>
                        <Select
                            IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                            labelId={"select-auth-trader-type-label-id"}
                            input={<OutlinedInput
                                sx={{fontSize: "16px"}}
                                label={typeLabel}/>}
                            value={authenticatedTradeNumberType}
                            onChange={(e: SelectChangeEvent<typeof countryCode>) => {
                                setAuthenticatedTradeNumberType(e.target.value)
                            }}
                            sx={{
                                fontSize: "16px",
                                borderRadius: "12px",
                                color: Colors.GRAY_8,
                                fontWeight: 500,
                                height: "52px",
                                "&& fieldset": {
                                    border: `1px solid ${Colors.GRAY_4}`
                                },
                                "&:hover": {
                                    "&& fieldset": {
                                        border: `1px solid ${Colors.CTA_HOVER}`
                                    }
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: `2px solid ${Colors.MAIN}`
                                },

                            }}>
                            <MenuItem
                                sx={{fontSize: "16px"}}
                                value={"P"}>{t('by_product')}</MenuItem>
                            <MenuItem
                                sx={{fontSize: "16px"}}
                                value={"C"}>{t('by_company')}</MenuItem>
                        </Select>
                    </FormControl>
                    <Input
                        autoComplete={"off"}
                        width={"100%"}
                        alignSelf={"center"}
                        marginLeft={isMobile ? "0px" : "10px"}
                        marginTop={isMobile ? "10px" : "0px"}
                        onChange={(e) => {
                            setAuthenticatedTradeNumber(KeyboardUtils.clearNumber(e.target.value))
                        }
                        }
                        type={"text"}
                        value={authenticatedTradeNumber}
                    />
                </FlexBox>
                <NewH5
                    marginTop={"34px"}
                    color={Colors.GRAY_7}>{t('business_auth_paper_upload')}</NewH5>
                <label
                    ref={dragRef}
                    htmlFor={"fileUpload"}>
                    <FlexBox
                        id={'file-upload-container'}
                        height={"230px"}
                        padding={"40px 0px"}
                        flexDirection={"column"}
                        marginTop={"20px"}
                        justifyContent={"center"}
                        border={`2px dashed ${Colors.GRAY_4}`}
                        backgroundColor={isDragging ? Colors.GRAY_3 : Colors.GRAY_1}
                        borderRadius={"12px"}
                        alignItems={"center"}>
                        <Image
                            width={"48px"}
                            height={"48px"}
                            src={require(`assets/images/ic-photo-48.svg`).default}
                            alt="ic-photo"/>
                        <NewP2
                            textAlign={"center"}
                            color={Colors.GRAY_7}
                            marginTop={"8px"}>
                            {t('file_upload_description')}
                        </NewP2>
                        <input
                            type="file"
                            id="fileUpload"
                            style={{display: "none"}}
                            multiple={true}
                            onChange={onChangeFiles}
                        />
                        <NewButton
                            marginTop={"8px"}
                            padding={'10px 16px'}
                            width={"fit-content"}
                            height={"fit-content"}>
                            {t('upload')}
                        </NewButton>
                    </FlexBox>
                </label>
                {file &&
                    <FlexBox
                        marginTop={"20px"}
                        borderRadius={"4px"}
                        padding={"8px 16px"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        border={`1px solid ${Colors.GRAY_4}`}>
                        <NewH5
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            color={Colors.GRAY_7}>
                            {file.name}
                        </NewH5>
                        <FlexBox
                            alignItems={"center"}>
                            <NewH5
                                color={Colors.GRAY_7}>
                                {`(${FileUtils.convertBytesToMegaBytes(file.size)}MB)`}
                            </NewH5>
                            <Image
                                cursor={"pointer"}
                                marginLeft={"8px"}
                                onClick={() => {
                                    setFile(null)
                                }}
                                width={"20px"}
                                height={"20px"}
                                src={require("assets/images/ic-close-20.svg").default}
                                alt="ic-remove"/>
                        </FlexBox>
                    </FlexBox>}
                <FlexBox marginTop={"34px"}>
                    <Checkbox
                        checked={termsOfServiceAgreeChecked}
                        onChange={(e: CheckboxChangeEvent) => {
                            setTermsOfServiceAgreeChecked(e.target.checked);
                        }}/>
                    <NewP2
                        color={Colors.GRAY_7}
                        marginLeft={"6px"}
                        fontSize={"14px"}>
                        {t('check_terms_of_service_and_privacy_policy_head')}{StringConstants.SPACE}
                        <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                           onClick={() => {
                               window.open("/terms-of-service")
                           }}>{t('terms_of_service')}</a>{StringConstants.SPACE}{t('and')}{StringConstants.SPACE}
                        <a style={{fontWeight: "700", cursor: "pointer", borderBottom: "1px solid #000"}}
                           onClick={() => {
                               window.open("/privacy-policy")
                           }}>{t('privacy_policy')}</a>
                        {t('check_terms_of_service_and_privacy_policy_tail')}</NewP2>
                </FlexBox>
                <Button
                    alignSelf={"center"}
                    marginTop={"34px"}
                    marginBottom={"64px"}
                    width={"100%"}
                    onClick={() => handleRegisterCompleteClicked()}>
                    {signedIn ? t('sign_up_request_completed') : t('sign_up_complete')}</Button>
            </FlexBox>
        </ContentContainer>
    )
}
export default CompanyUserSignUpSecondFragment;