import {NetworkCommon} from "./NetworkCommon";
import {AxiosResponse} from "axios";
import {CountryModel} from "../model/CountryModel";
import {CategoryModel} from "../model/CategoryModel";
import {CertificationModel} from "../model/CertificationModel";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class CommonApi {
    static getCountries(langCode: string): Promise<AxiosResponse<BaseResponse<Array<CountryModel>>>> {
        return instance.get("/common/country", {
            params: {
                langCode: langCode
            },
        });
    }

    static getCategories(langCode: string): Promise<AxiosResponse<BaseResponse<Array<CategoryModel>>>> {
        return instance.get("/common/categories", {
            params: {
                langCode: langCode
            },
        });
    }

    static getCertifications(langCode: string): Promise<AxiosResponse<BaseResponse<Array<CertificationModel>>>> {
        return instance.get("/common/certifications", {
            params: {
                langCode: langCode
            },
        });
    }
}