import styled from "styled-components";
import Text from "../Text";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewH7 = styled(Text)`
    text-align: ${({textAlign}) => textAlign || "left"};
    font-feature-settings: 'clig' off, 'liga';
    font-size: 16px;
    font-style: normal;
    font-weight: ${({fontWeight}) => fontWeight || 400};
    line-height: 150%;
`;

export default NewH7;