import styled from "styled-components";
import {TextField} from "@mui/material";
import {Colors} from "../styledcomponents/Styles";

/**
 * @author jaeho.lee104 on 2023. 11. 24..
 */
const NTextArea = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: "12px",
        color: Colors.GRAY_8,
        padding: "16px",
        alignItems: "stretch",
        fontSize: "16px",
        width: "100%",
        overflow: 'auto',
        height: "100%",
        '& fieldset': {
            borderColor: Colors.GRAY_4,
        },
        '&:hover fieldset': {
            borderColor: Colors.CTA_HOVER,
        },
        '&.Mui-focused fieldset': {
            borderColor: Colors.MAIN,
        },
    },
});

export default NTextArea