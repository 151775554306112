/**
 * @author jaeho.lee104 on 2023. 11. 01..
 */
import {createTheme, ThemeProvider} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import useMobile from "../uses/UseMobile";
import {useTranslation} from "react-i18next";
import {Dialog} from "@mui/material";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import {BottomSheet} from "react-spring-bottom-sheet";
import NewH6 from "../styledcomponents/text/NewH6";
import NewH5 from "../styledcomponents/text/NewH5";
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import {useNavigate} from "react-router-dom";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface SignUpOptionsDialog {
    isOpen: boolean
    onClose: (positive: boolean) => void
}

const SignUpOptionsDialog: React.FC<{
    dialogProps: SignUpOptionsDialog
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMobile()
    const {t} = useTranslation()
    const navigate = useNavigate();

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(true);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    function handleCheckBenefitClicked() {
        navigate("/signup-intro")
    }

    function handleNormalUserClicked() {
        navigate("/normal-user-signup")
    }

    function handleCompanyUserClicked() {
        navigate("/company-user-signup-1")
    }

    return <>{!isMobile &&
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3
                            color={Colors.GRAY_9}>
                            {t('sign_up_option_dialog_title')}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            marginBottom={"8px"}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}
                        marginTop={"16px"}
                        justifyContent={"space-between"}>
                        <NewH6
                            color={Colors.GRAY_7}>
                            {t('sign_up_option_dialog_subtitle')}
                        </NewH6>
                        <NewH5
                            color={Colors.MAIN}
                            fontWeight={700}
                            cursor={"pointer"}
                            onClick={handleCheckBenefitClicked}>
                            {t('sign_up_benefit_check')}
                        </NewH5>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        height={"94px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        cursor={"pointer"}
                        alignItems={"center"}
                        marginTop={'32px'}
                        borderRadius={"16px"}
                        onClick={handleNormalUserClicked}
                        padding={"16px"}>
                        <Image
                            onClick={handleCancel}
                            width={"48px"}
                            height={"48px"}
                            src={require("assets/images/normal-user-signup.svg").default}
                            alt="ic-normal-user"/>
                        <FlexBox
                            height={'100%'}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <NewH3
                                color={Colors.GRAY_8}>
                                {t('normal_user')}
                            </NewH3>
                            <NewP2
                                color={Colors.GRAY_6}>
                                {t('only_view_trading_data')}
                            </NewP2>
                        </FlexBox>
                        <Image
                            position={"absolute"}
                            right={"16px"}
                            width={"24px"}
                            height={"24px"}
                            src={require("assets/images/ic-arrow-right-24.svg").default}
                            alt="ic-normal-user-arrow"/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        height={"94px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        cursor={"pointer"}
                        alignItems={"center"}
                        marginTop={'16px'}
                        borderRadius={"16px"}
                        padding={"16px"}
                        onClick={handleCompanyUserClicked}>
                        <Image
                            onClick={handleCancel}
                            width={"48px"}
                            height={"48px"}
                            src={require("assets/images/company-user-signup.svg").default}
                            alt="ic-company-user"/>
                        <FlexBox
                            height={'100%'}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <NewH3
                                color={Colors.GRAY_8}>
                                {t('company_user')}
                            </NewH3>
                            <NewP2
                                color={Colors.GRAY_6}>
                                {t('view_trading_data_and_solution')}
                            </NewP2>
                        </FlexBox>
                        <Image
                            position={"absolute"}
                            right={"16px"}
                            width={"24px"}
                            height={"24px"}
                            src={require("assets/images/ic-arrow-right-24.svg").default}
                            alt="ic-company-user-arrow"/>
                    </FlexBox>
                </FlexBox>
            </Dialog>
        </ThemeProvider>}
        {isMobile &&
            <BottomSheet
                open={isOpen}
                onDismiss={handleCancel}
                defaultSnap={({maxHeight}) => maxHeight / 2}
                snapPoints={({maxHeight}) => [
                    maxHeight / 2
                ]}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"16px 20px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3
                            color={Colors.GRAY_9}>
                            {t('sign_up_option_dialog_title')}
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            marginBottom={"8px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={"16px"}
                        flexDirection={isMobile ? "column" : "row"}
                        justifyContent={"space-between"}>
                        <NewH6
                            color={Colors.GRAY_7}>
                            {t('sign_up_option_dialog_subtitle')}
                        </NewH6>
                        <NewH5
                            cursor={"pointer"}
                            marginTop={"8px"}
                            color={Colors.MAIN}
                            fontWeight={700}
                            onClick={handleCheckBenefitClicked}>
                            {t('sign_up_benefit_check')}
                        </NewH5>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        height={"94px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        cursor={"pointer"}
                        alignItems={"center"}
                        marginTop={'28px'}
                        borderRadius={"16px"}
                        onClick={handleNormalUserClicked}
                        padding={"16px"}>
                        <Image
                            onClick={handleCancel}
                            width={"48px"}
                            height={"48px"}
                            src={require("assets/images/normal-user-signup.svg").default}
                            alt="ic-normal-user"/>
                        <FlexBox
                            height={'100%'}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <NewH3
                                color={Colors.GRAY_8}>
                                {t('normal_user')}
                            </NewH3>
                            <NewP2
                                color={Colors.GRAY_6}>
                                {t('only_view_trading_data')}
                            </NewP2>
                        </FlexBox>
                        <Image
                            position={"absolute"}
                            right={"16px"}
                            width={"24px"}
                            height={"24px"}
                            src={require("assets/images/ic-arrow-right-24.svg").default}
                            alt="ic-normal-user-arrow"/>
                    </FlexBox>
                    <FlexBox
                        width={"100%"}
                        height={"94px"}
                        border={`1px solid ${Colors.GRAY_4}`}
                        cursor={"pointer"}
                        alignItems={"center"}
                        marginTop={'16px'}
                        borderRadius={"16px"}
                        padding={"16px"}
                        onClick={handleCompanyUserClicked}>
                        <Image
                            onClick={handleCancel}
                            width={"48px"}
                            height={"48px"}
                            src={require("assets/images/company-user-signup.svg").default}
                            alt="ic-company-user"/>
                        <FlexBox
                            height={'100%'}
                            marginLeft={"16px"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}>
                            <NewH3
                                color={Colors.GRAY_8}>
                                {t('company_user')}
                            </NewH3>
                            <NewP2
                                color={Colors.GRAY_6}>
                                {t('view_trading_data_and_solution')}
                            </NewP2>
                        </FlexBox>
                        <Image
                            position={"absolute"}
                            right={"16px"}
                            width={"24px"}
                            height={"24px"}
                            src={require("assets/images/ic-arrow-right-24.svg").default}
                            alt="ic-company-user-arrow"/>
                    </FlexBox>
                </FlexBox>
            </BottomSheet>}
    </>
}

export default SignUpOptionsDialog