export class AuthenticateCompanyModel {
    companyNm: string
    companyAddress: string
    customsAuthType: string//C, P
    customsAuthNum: string
    customsAuthNumYn: string//Y, N, NA
    impExpExperience: boolean
    countryCode: string//KR, ...
    businessLicenseType: string//PER, COR
    businessLicenseNum: string
    majorTradingCountries: string
    website: string

    constructor(companyNm: string, companyAddress: string, customsAuthType: string,
                customsAuthNum: string, customsAuthNumYn: string, impExpExperience: boolean, countryCode: string,
                businessLicenseType: string, businessLicenseNum: string, majorTradingCountries: string, website: string) {
        this.companyNm = companyNm;
        this.companyAddress = companyAddress;
        this.customsAuthType = customsAuthType;
        this.customsAuthNum = customsAuthNum;
        this.customsAuthNumYn = customsAuthNumYn;
        this.impExpExperience = impExpExperience;
        this.countryCode = countryCode;
        this.businessLicenseType = businessLicenseType;
        this.businessLicenseNum = businessLicenseNum;
        this.majorTradingCountries = majorTradingCountries;
        this.website = website;
    }
}