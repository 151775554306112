import {KeyboardEvent, MouseEvent} from "react";
import {RegexUtils} from "../constants/RegexUtils";

export class KeyboardUtils {
    static filterOnlyNumber(e: KeyboardEvent<HTMLInputElement>): boolean {
        return RegexUtils.checkOnlyNumber(e.key) || e.key === "Backspace";
    }

    static clearNumber(value: string): string {
        return value.replace(/\D+/g, "");
    }

    static convertPriceNumber(origin: string): string {
        let value = origin.replace(",", "")
        let split = value.split(".")
        let number = split[0]
        let result: string = ""
        let cnt = 0
        for (let i = number.length - 1; i > 0; i--) {
            cnt++
            result += number.charAt(i)
            if (cnt % 3 === 0) {
                result += ","
            }
        }
        result += number.charAt(0)
        return result.split("").reverse().join("") + (split.length > 1 ? "." + split[1] : "")
    }

    static isNewWindowTriggerKey(event: MouseEvent<any>): boolean {
        return event.ctrlKey || event.metaKey
    }
}