import styled from "styled-components";
import Text from "../Text";

/**
 * @author jaeho.lee104 on 2023. 08. 08..
 */
const NewP1 = styled(Text)`
  text-align: ${({textAlign}) => textAlign || "center"};
  font-feature-settings: 'clig' off, 'liga';
  font-size: ${({fontSize}) => fontSize || "14px"};
  font-style: normal;
  font-weight: ${({fontWeight}) => fontWeight || "500"};
  line-height: 150%;
`;

export default NewP1;