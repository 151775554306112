/**
 * @author jaeho.lee104 on 2023. 12. 01..
 */
import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import React, {ChangeEvent, useEffect, useState} from "react";
import NewH1 from "../styledcomponents/text/NewH1";
import {Colors} from "../styledcomponents/Styles";
import NewH3 from "../styledcomponents/text/NewH3";
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import NewH4 from "../styledcomponents/text/NewH4";
import TextField from "../components/TextField";
import SelectBox from "../components/SelectBox";
import {
    CodeModel,
    NewProductUnitTypes,
    ProductBuyerTypes,
    ProductGradeTypes,
    ProductLoadingCapacityUnit,
    ProductUnitTypes
} from "../model/CodeModel";
import {NewRadioItem} from "../components/NewRadioItem";
import NewP1 from "../styledcomponents/text/NewP1";
import {DEFAULT_COUNTRIES} from "../model/CountryModel";
import {CommonApi} from "../network/CommonApi";
import {ResponseCodes} from "../network/ResponseCodes";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import NewH6 from "../styledcomponents/text/NewH6";
import NTextField from "../components/NTextField";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH5 from "../styledcomponents/text/NewH5";
import {FileUtils} from "../utils/FileUtils";
import NewButton from "../styledcomponents/NewButton";
import NAutoComplete from "../components/NAutoComplete";
import NTextArea from "../components/NTextArea";
import {useNavigate, useSearchParams} from "react-router-dom";
import {NavigationMenu} from "../model/NavigationMenu";
import {EMPTY_PRODUCT_ADD_MODEL, ProductAddModel} from "../model/ProductAddModel";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import {CertificationModel} from "../model/CertificationModel";
import {SolutionAPI} from "../network/SolutionAPI";
import {ImageModel} from "../model/ImageModel";
import {TextUtils} from "../utils/TextUtils";
import {ProductStatus} from "../model/ProductStatus";
import {RegexUtils} from "../constants/RegexUtils";
import {FormatUtils} from "../utils/FormatUtils";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters
                  elevation={0}
                  square={true}
                  {...props} />
))(({theme}) => ({
    backgroundColor: Colors.WHITE,
    borderRadius: "16px",
    border: `1px solid ${Colors.GRAY_3}`,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.08);`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => {
        return <MuiAccordionSummary
            {...props}/>
    }
)(({theme}) => ({
    height: '92px',
    padding: "0px 32px",
    borderRadius: "16px",
    backgroundColor: Colors.WHITE,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0)',
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: '16px 32px 32px 32px',
}));

const ProductAddFragment = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const [productItemId, setProductItemId] = useState("")
    const [countries, setCountries] = useState<Array<CodeModel>>(DEFAULT_COUNTRIES.map(item => {
        return {
            code: item.countryCode,
            name: item.countryName
        }
    }))

    const productUploadImageKey = 'productUploadImage'
    const specificationUploadKey = 'specificationUploadKey'
    const catalogUploadKey = "catalogUploadKey"
    const packingPictureUploadKey = "packingPictureUploadKey"

    const navigate = useNavigate()
    const {t} = useTranslation()
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [adminCategories, setAdminCategories] = useState<Array<CodeModel>>([])
    const [certifications, setCertifications] = useState<Array<CertificationModel>>([])

    const [step1Checked, setStep1Checked] = useState(false)
    const [step2Checked, setStep2Checked] = useState(false)
    const [step3Checked, setStep3Checked] = useState(false)
    const [step1Completed, setStep1Completed] = useState(false)
    const [step2Completed, setStep2Completed] = useState(false)
    const [step3Completed, setStep3Completed] = useState(false)

    const [productAddModel, setProductAddModel] = useState<ProductAddModel>(EMPTY_PRODUCT_ADD_MODEL)

    const [productImageFiles, setProductImageFiles] = useState<Array<File>>([])
    const [specificationFiles, setSpecificationFiles] = useState<Array<File>>([])
    const [catalogFiles, setCatalogFiles] = useState<Array<File>>([])
    const [packingPictureFiles, setPackingPictureFiles] = useState<Array<File>>([])

    const [itemPrice, setItemPrice] = useState("")
    const [samplePrice, setSamplePrice] = useState("")

    useEffect(() => {
        let itemId = searchParams.get('itemId')
        if (itemId) {
            setProductItemId(itemId)
            setStep1Checked(true)
            setStep2Checked(true)
            setStep3Checked(true)
        }
    }, [searchParams])

    useEffect(() => {
        if (!isValidProductId()) {
            return
        }
        SolutionAPI.getProduct(productItemId)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (!responseCode.isSuccess()) {
                    return
                }
                const productItem = response.data.data
                if (!productItem) {
                    return;
                }
                setProductAddModel({
                    categoryList: productItem.categoryList,
                    certificationList: productItem.certifications.map((item) => {
                        return {
                            certificationId: item.certificationId,
                            certificationName: item.certificationName
                        }
                    }),
                    info: productItem.info,
                    itemStatus: ProductStatus.TEMP_DRAFT,
                    keyword: productItem.keyword,
                    packing: productItem.packing,
                    sourcing: productItem.sourcing,
                    specification: productItem.specification,
                    summary: productItem.summary
                })
                setItemPrice(productItem.info.itemPrice.toString())
                setSamplePrice(productItem.info.samplePrice.toString())
                updateImageFiles(productItem.itemImages, (files) => {
                    setProductImageFiles(files)
                })
                updateImageFiles(productItem.specificationImages, (files) => {
                    setSpecificationFiles(files)
                })
                updateImageFiles(productItem.catalogs, (files) => {
                    setCatalogFiles(files)
                })
                updateImageFiles(productItem.packingPictures, (files) => {
                    setPackingPictureFiles(files)
                })
            })

    }, [productItemId])

    useEffect(() => {
        CommonApi.getCertifications(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const certifications = response.data.data
                if (responseCode.isSuccess() && certifications && certifications.length > 0) {
                    setCertifications(certifications)
                }
            })
    }, [language])

    useEffect(() => {
        CommonApi.getCategories(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const categories = response.data.data
                if (responseCode.isSuccess() && categories && categories.length > 0) {
                    setAdminCategories(categories.map(item => {
                        return {
                            code: item.categoryId,
                            name: item.categoryName
                        }
                    }))
                }
            })
    }, [language])

    useEffect(() => {
        CommonApi.getCountries(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries.map(item => {
                        return {
                            code: item.countryCode,
                            name: item.countryName
                        }
                    }))
                }
            })
    }, [language])

    async function updateImageFiles(images: Array<ImageModel>, onSetAction: (newArray: Array<File>) => void) {
        const newArray: Array<File> = []
        for (let i = 0; i < images.length; i++) {
            const image = images[i]
            const response = await fetch(image.url)
            const data = await response.blob();
            const metadata = {type: `image/png`};
            newArray.push(new File([data], image.name, metadata))
        }
        onSetAction(newArray)
    }

    const isValidProductId = () => {
        return productItemId && productItemId.length > 0
    }

    const onChangeFiles = (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFile: File | null;
        selectFile = e.target.files ? e.target.files[0] : null;
        const targetId = e.target.id
        e.target.value = null
        if (selectFile) {
            if (!checkValidationFile(selectFile)) {
                return
            }
            if (targetId === productUploadImageKey) {
                if (productImageFiles.length === 5) {
                    alert(t('check_maximum_five_files'))
                    return;
                }
                setProductImageFiles(productImageFiles.concat(selectFile))
            } else if (targetId === specificationUploadKey) {
                if (specificationFiles.length === 5) {
                    alert(t('check_maximum_five_files'))
                    return;
                }
                setSpecificationFiles(specificationFiles.concat(selectFile))
            } else if (targetId === catalogUploadKey) {
                if (catalogFiles.length === 5) {
                    alert(t('check_maximum_five_files'))
                    return;
                }
                setCatalogFiles(catalogFiles.concat(selectFile))
            } else if (targetId === packingPictureUploadKey) {
                if (packingPictureFiles.length === 5) {
                    alert(t('check_maximum_five_files'))
                    return;
                }
                setPackingPictureFiles(packingPictureFiles.concat(selectFile))
            }
        }
    }

    function checkValidationFile(file: File): boolean {
        let split = file.name.split('.')
        if (split.length < 2) {
            alert(t('check_add_png_jpg'))
            return false
        }
        let extension = split.pop()
        if (extension !== 'png' && extension !== 'jpg' && extension !== 'jpeg') {
            alert(t('check_add_png_jpg'))
            return false
        }
        if (file.size > 1 * 1024 * 1024) {
            alert(t('check_maximum_size_1_mega'))
            return false
        }
        return true
    }

    function handleDeleteFileInArray(targetId: string, deleteFile: File) {
        let checked = 0
        const compareFileName = (item: File) => {
            if (item.name === deleteFile.name) {
                checked++
            }
            return checked > 1 || item.name !== deleteFile.name
        }
        if (targetId === productUploadImageKey) {
            setProductImageFiles(productImageFiles.filter(compareFileName))
        } else if (targetId === specificationUploadKey) {
            setSpecificationFiles(specificationFiles.filter(compareFileName))
        } else if (targetId === catalogUploadKey) {
            setCatalogFiles(catalogFiles.filter(compareFileName))
        } else if (targetId === packingPictureUploadKey) {
            setPackingPictureFiles(packingPictureFiles.filter(compareFileName))
        }
    }

    function handleSaveClick(status: string) {
        if (productAddModel.info.itemName.length === 0) {
            alert(t('enter_product_name'))
            return;
        }
        if (itemPrice.length === 0) {
            alert(t('enter_product_price'))
            return;
        }
        SolutionAPI.saveProduct(
            productItemId,
            {
                ...productAddModel,
                info: {
                    ...productAddModel.info,
                    itemPrice: Number(itemPrice.replace(/,+/g, "")),
                    samplePrice: Number(samplePrice.replace(/,+/g, "")),
                },
                itemStatus: status
            }, productImageFiles, catalogFiles, packingPictureFiles, specificationFiles)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    alert(`${t(TextUtils.itemStatusToUiText(status))} ${t('completed')}`)
                    navigate(`?page=${NavigationMenu.PRODUCT_MANAGE}`)
                } else {
                    alert(`${response.data?.code} ${response.data?.message}`)
                }
            })
            .catch((e) => {
                alert(`${t(TextUtils.itemStatusToUiText(status))} ${t('fail')}`)
            })
    }

    return <FlexBox
        width={"1200px"}
        flexDirection={"column"}
        margin={"64px auto"}>
        <FlexBox
            alignItems={"center"}>
            <Image
                cursor={"pointer"}
                onClick={() => {
                    navigate(`?page=${NavigationMenu.PRODUCT_MANAGE}`)
                }}
                width={"40px"}
                height={"40px"}
                src={require("assets/images/ic-arrow-sharp-left.svg").default}
                alt="ic-back-arrow"/>
            <NewH1
                marginLeft={'24px'}
                color={Colors.GRAY_9}>
                {t('add_product')}
            </NewH1>
        </FlexBox>
        <Accordion
            sx={{
                marginTop: "40px"
            }}
            expanded={!step1Completed}>
            <AccordionSummary
                onClick={() => {
                    if (!step1Checked) {
                        return
                    }
                    setStep1Completed(!step1Completed)
                }}
                expandIcon={step1Completed ?
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-checked-circle-32px.svg").default}
                        alt="ic-checked-step1"/> :
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-unchecked-circle-32px.svg").default}
                        alt="ic-unchecked-step1"/>}>
                <FlexBox
                    alignItems={"center"}>
                    <NewH3
                        color={step1Completed ? Colors.GRAY_5 : Colors.MAIN}>
                        1.
                    </NewH3>
                    <NewH3
                        marginLeft={"8px"}
                        color={step1Completed ? Colors.GRAY_5 : Colors.GRAY_8}>
                        {t('product_overview')}
                    </NewH3>
                </FlexBox>
            </AccordionSummary>
            <AccordionDetails>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH4
                        color={Colors.GRAY_8}>
                        {t('product_details')}
                    </NewH4>
                    <FlexBox
                        marginTop={"24px"}>
                        <TextField
                            width={"100%"}
                            value={productAddModel.info.itemName}
                            label={`${t('product_detail_name')}`}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        info: {
                                            ...prevState.info,
                                            itemName: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        info: {
                                            ...prevState.info,
                                            itemName: e.target.value
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                        <FlexBox
                            marginLeft={"24px"}
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                width={"100%"}
                                label={`${t('selling_price')}(FOB, US$)`}
                                value={FormatUtils.toCurrencyFormat(itemPrice)}
                                onChange={(e) => {
                                    const replaced = e.target.value.replace(/,+/g, "")
                                    setItemPrice(RegexUtils.checkFloatNumber(replaced) ? replaced : itemPrice)
                                }}
                                onClear={() => {
                                    setItemPrice("")
                                }}
                                labelFontWight={500}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                type={"text"}
                            />
                            <SelectBox items={Object.values(NewProductUnitTypes)}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Unit'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.info.itemPriceUnitCode}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   info: {
                                                       ...prevState.info,
                                                       itemPriceUnitCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <FlexBox
                            flexDirection={"column"}
                            width={"100%"}>
                            <NewP1
                                fontSize={"16px"}
                                color={Colors.GRAY_7}>
                                {t('sample_availability')}
                            </NewP1>
                            <FlexBox
                                width={"100%"}
                                marginTop={"8px"}
                                height={"52px"}>
                                <NewRadioItem
                                    title={`${t('sample_available')}`}
                                    checked={productAddModel.info.sampleAvailable}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                info: {
                                                    ...prevState.info,
                                                    sampleAvailable: true
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                                <NewRadioItem
                                    marginLeft={"16px"}
                                    title={`${t('sample_unavailable')}`}
                                    checked={!productAddModel.info.sampleAvailable}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                info: {
                                                    ...prevState.info,
                                                    sampleAvailable: false
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                width={"100%"}
                                label={`${t('sample_price')}(FOB, US$)`}
                                labelFontWight={500}
                                value={FormatUtils.toCurrencyFormat(samplePrice)}
                                onChange={(e) => {
                                    const replaced = e.target.value.replace(/,+/g, "")
                                    setSamplePrice(RegexUtils.checkFloatNumber(replaced) ? replaced : samplePrice)
                                }}
                                onClear={() => {
                                    setSamplePrice("")
                                }}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                type={"text"}
                            />
                            <SelectBox items={Object.values(NewProductUnitTypes)}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Unit'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.info.samplePriceUnitCode}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   info: {
                                                       ...prevState.info,
                                                       samplePriceUnitCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                    </FlexBox>
                    <NewH4
                        marginTop={'32px'}
                        color={Colors.GRAY_8}>
                        {t('product_summary')}
                    </NewH4>
                    <FlexBox
                        marginTop={"24px"}>
                        <SelectBox items={countries}
                                   width={"100%"}
                                   label={'Country Of Origin'}
                                   labelFontWight={500}
                                   labelFontSize={"16px"}
                                   value={productAddModel.summary?.originCode || ""}
                                   onChange={(e) => {
                                       setProductAddModel((prevState) => {
                                           return {
                                               ...prevState,
                                               summary: {
                                                   ...prevState.summary,
                                                   originCode: e.target.value
                                               }
                                           }
                                       })
                                   }}
                        />
                        <FlexBox
                            marginLeft={"24px"}
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                width={"100%"}
                                label={'Minimum of quantity(MOQ)'}
                                value={productAddModel.summary?.moq || ""}
                                onChange={(e) => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            summary: {
                                                ...prevState.summary,
                                                moq: KeyboardUtils.clearNumber(e.target.value)
                                            }
                                        }
                                    })
                                }}
                                onClear={() => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            summary: {
                                                ...prevState.summary,
                                                moq: ""
                                            }
                                        }
                                    })
                                }}
                                labelFontWight={500}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                type={"text"}
                            />
                            <SelectBox items={ProductUnitTypes}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Unit'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.summary?.moqUnitCode || ""}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   summary: {
                                                       ...prevState.summary,
                                                       moqUnitCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <NTextField
                            width={"100%"}
                            label={'Lead Time'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_7}
                                                 width={"40px"}>Days</NewH6>}
                            value={productAddModel.summary?.leadTime || ""}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        summary: {
                                            ...prevState.summary,
                                            leadTime: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                        <FlexBox
                            marginLeft={"24px"}
                            width={"100%"}/>
                    </FlexBox>
                    <FlexBox
                        marginTop={"12px"}>
                        <NewP1
                            width={"100%"}
                            color={Colors.MAIN}
                            textAlign={"left"}
                            fontWeight={700}>
                            after the order confirmation
                        </NewP1>
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"32px"}>
                        {t('product_image')}
                    </NewH4>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_7}>
                        {t('maximum_five_files_upload')}
                    </NewP2>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={"16px"}>
                        {productImageFiles.map((item, index) => {
                            return <FlexBox
                                minWidth={"164px"}
                                maxWidth={"164px"}
                                paddingRight={"8px"}
                                paddingTop={"8px"}
                                flexWrap={"wrap"}
                                flexDirection={"column"}
                                marginRight={"12px"}>
                                <Image
                                    width={"160px"}
                                    height={"160px"}
                                    borderRadius={"24px"}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    backgroundColor={Colors.GRAY_0}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    src={URL.createObjectURL(item)}
                                    alt={`product-image-${index}`}/>
                                <NewH5
                                    marginTop={"8px"}
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {item.name}
                                </NewH5>
                                <NewH5
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`(${FileUtils.convertBytesToMegaBytes(item.size)}MB)`}
                                </NewH5>
                                <Image
                                    cursor={"pointer"}
                                    position={"absolute"}
                                    right={"0px"}
                                    top={"0px"}
                                    width={"24px"}
                                    height={"24px"}
                                    onClick={() => {
                                        handleDeleteFileInArray(productUploadImageKey, item)
                                    }}
                                    borderRadius={"100px"}
                                    backgroundColor={Colors.GRAY_8}
                                    src={require("assets/images/ic-close-24px.svg").default}
                                    alt="remove-file-icon"/>
                            </FlexBox>
                        })}
                        <label
                            htmlFor={productUploadImageKey}>
                            <FlexBox
                                marginTop={"8px"}
                                width={"160px"}
                                height={"160px"}
                                borderRadius={"24px"}
                                cursor={"pointer"}
                                border={`1px solid ${Colors.GRAY_2}`}
                                backgroundColor={Colors.GRAY_0}
                                justifyContent={"center"}
                                alignItems={"center"}>
                                <Image
                                    src={require("assets/images/ic-add-box-40px.svg").default}
                                    alt="add-icon"/>
                                <input
                                    type="file"
                                    id={productUploadImageKey}
                                    style={{display: "none"}}
                                    multiple={true}
                                    onChange={onChangeFiles}
                                />
                            </FlexBox>
                        </label>
                    </FlexBox>
                    <FlexBox
                        alignSelf={"center"}
                        width={"600px"}
                        marginTop={"60px"}>
                        <NewButton
                            onClick={() => {
                                setStep1Checked(true)
                                setStep1Completed(true)
                            }}>
                            {t('next')}
                        </NewButton>
                        <NewButton
                            fontWeight={500}
                            border={`1px solid ${Colors.GRAY_4}`}
                            marginLeft={"24px"}
                            colorType={5}
                            onClick={() => {
                                handleSaveClick(ProductStatus.TEMP_DRAFT)
                            }}>
                            {t('temp_draft')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </AccordionDetails>
        </Accordion>
        <Accordion
            sx={{
                display: step1Checked ? "block" : "none",
                marginTop: "40px"
            }}
            expanded={!step2Completed}>
            <AccordionSummary
                onClick={() => {
                    if (!step2Checked) {
                        return
                    }
                    setStep2Completed(!step2Completed)
                }}
                expandIcon={step2Completed ?
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-checked-circle-32px.svg").default}
                        alt="ic-checked-step1"/> :
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-unchecked-circle-32px.svg").default}
                        alt="ic-unchecked-step1"/>}>
                <FlexBox
                    alignItems={"center"}>
                    <NewH3
                        color={step2Completed ? Colors.GRAY_5 : Colors.MAIN}>
                        2.
                    </NewH3>
                    <NewH3
                        marginLeft={"8px"}
                        color={step2Completed ? Colors.GRAY_5 : Colors.GRAY_8}>
                        {t('product_categorize')}
                    </NewH3>
                </FlexBox>
            </AccordionSummary>
            <AccordionDetails>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"16px"}>
                        <NAutoComplete
                            width={"100%"}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            label={`${t('product_category')}`}
                            items={adminCategories}
                            values={productAddModel.categoryList.map((item) => {
                                return {
                                    code: item.categoryId,
                                    name: item.categoryName
                                }
                            })}
                            onChangedValues={(values) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        categoryList: values.map((item) => {
                                            return {
                                                categoryId: item.code,
                                                categoryName: item.name
                                            }
                                        })
                                    }
                                })
                            }}
                        />

                        <TextField
                            width={"100%"}
                            marginLeft={'24px'}
                            label={'HS CODE(6)'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            value={productAddModel.info.itemHsCode}
                            enabledClearButton={true}
                            type={"text"}
                            maxLength={6}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        info: {
                                            ...prevState.info,
                                            itemHsCode: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        info: {
                                            ...prevState.info,
                                            itemHsCode: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    }
                                })
                            }}
                        />
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <TextField
                            width={"100%"}
                            label={`${t('keyword')}`}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            value={productAddModel.keyword}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        keyword: ""
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        keyword: e.target.value
                                    }
                                })
                            }}
                            type={"text"}
                        />
                        <FlexBox
                            marginLeft={"24px"}
                            width={"100%"}/>
                    </FlexBox>
                    <NewP2
                        marginTop={"12px"}
                        color={Colors.GRAY_5}>
                        {t('keyword_input_guide')}
                    </NewP2>
                    <FlexBox
                        alignSelf={"center"}
                        width={"600px"}
                        marginTop={"60px"}>
                        <NewButton
                            onClick={() => {
                                setStep2Checked(true)
                                setStep2Completed(true)
                            }}>
                            {t('next')}
                        </NewButton>
                        <NewButton
                            fontWeight={500}
                            border={`1px solid ${Colors.GRAY_4}`}
                            marginLeft={"24px"}
                            colorType={5}
                            onClick={() => {
                                handleSaveClick(ProductStatus.TEMP_DRAFT)
                            }}>
                            {t('temp_draft')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </AccordionDetails>
        </Accordion>
        <Accordion
            sx={{
                display: step2Checked ? "block" : "none",
                marginTop: "40px"
            }}
            expanded={!step3Completed}>
            <AccordionSummary
                onClick={() => {
                    if (!step3Checked) {
                        return
                    }
                    setStep3Completed(!step3Completed)
                }}
                expandIcon={step3Completed ?
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-checked-circle-32px.svg").default}
                        alt="ic-checked-step1"/> :
                    <Image
                        width={"32px"}
                        height={"32px"}
                        src={require("assets/images/ic-unchecked-circle-32px.svg").default}
                        alt="ic-unchecked-step1"/>}>
                <FlexBox
                    alignItems={"center"}>
                    <NewH3
                        color={step3Completed ? Colors.GRAY_5 : Colors.MAIN}>
                        3.
                    </NewH3>
                    <NewH3
                        marginLeft={"8px"}
                        color={step3Completed ? Colors.GRAY_5 : Colors.GRAY_8}>
                        {t('product_description')}
                    </NewH3>
                </FlexBox>
            </AccordionSummary>
            <AccordionDetails>
                <FlexBox
                    flexDirection={"column"}>
                    <NewH4
                        color={Colors.GRAY_8}>
                        Specification
                    </NewH4>
                    <NewP1
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        Ingredients(%)
                    </NewP1>
                    <NTextArea
                        placeholder={''}
                        value={productAddModel.specification?.ingredients || ""}
                        multiline={true}
                        maxRows={3}
                        onChange={(e) => {
                            setProductAddModel((prevState) => {
                                return {
                                    ...prevState,
                                    specification: {
                                        ...prevState.specification,
                                        ingredients: e.target.value
                                    }
                                }
                            })
                        }}
                        sx={{
                            height: "78px",
                            marginTop: "6px",
                        }}/>

                    <FlexBox
                        marginTop={"24px"}>
                        <NTextField
                            width={"100%"}
                            label={'Shelf Life'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            value={productAddModel.specification?.shelfLife || ""}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_7}>Months</NewH6>}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            shelfLife: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                        <TextField
                            hint={'e.g.) Soft and Exquisite'}
                            marginLeft={"24px"}
                            width={"100%"}
                            label={'Taste'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            value={productAddModel.specification?.taste || ""}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            taste: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            taste: e.target.value
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <TextField
                            width={"100%"}
                            label={'Appearance'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            hint={'e.g.)  Light yellow'}
                            enabledClearButton={true}
                            value={productAddModel.specification?.appearance || ""}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            appearance: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            appearance: e.target.value
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                        <TextField
                            hint={'e.g.) Baking food, other formula'}
                            marginLeft={"24px"}
                            width={"100%"}
                            label={'Application'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            value={productAddModel.specification?.application || ""}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            application: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            application: e.target.value
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <FlexBox
                            flexDirection={"column"}
                            width={"100%"}>
                            <NewP1
                                fontSize={"16px"}
                                color={Colors.GRAY_7}>
                                Allergens
                            </NewP1>
                            <FlexBox
                                width={"100%"}
                                marginTop={"8px"}
                                height={"52px"}>
                                <NewRadioItem
                                    title={`${t('allergens_yes')}`}
                                    checked={productAddModel.specification?.allergens || false}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                specification: {
                                                    ...prevState.specification,
                                                    allergens: true
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                                <NewRadioItem
                                    marginLeft={"16px"}
                                    title={`${t('allergens_no')}`}
                                    checked={!productAddModel.specification?.allergens}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                specification: {
                                                    ...prevState.specification,
                                                    allergens: false
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                            </FlexBox>
                        </FlexBox>
                        <TextField
                            hint={'e.g.) Milk and milk product(lactose included)'}
                            marginLeft={"24px"}
                            width={"100%"}
                            label={'Let us know more about the allergens'}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            value={productAddModel.specification?.allergensMore || ""}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            allergensMore: ""
                                        }
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        specification: {
                                            ...prevState.specification,
                                            allergensMore: e.target.value
                                        }
                                    }
                                })
                            }}
                            type={"text"}
                        />
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"32px"}>
                        Specification
                    </NewH4>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_7}>
                        {t('maximum_five_files_upload')}
                    </NewP2>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={"16px"}>
                        {specificationFiles.map((item, index) => {
                            return <FlexBox
                                minWidth={"164px"}
                                maxWidth={"164px"}
                                paddingRight={"8px"}
                                paddingTop={"8px"}
                                flexWrap={"wrap"}
                                flexDirection={"column"}
                                marginRight={"12px"}>
                                <Image
                                    width={"160px"}
                                    height={"160px"}
                                    borderRadius={"24px"}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    backgroundColor={Colors.GRAY_0}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    src={URL.createObjectURL(item)}
                                    alt={`company-image-${index}`}/>
                                <NewH5
                                    marginTop={"8px"}
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`${item.name}`}
                                </NewH5>
                                <NewH5
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`(${FileUtils.convertBytesToMegaBytes(item.size)}MB)`}
                                </NewH5>
                                <Image
                                    cursor={"pointer"}
                                    position={"absolute"}
                                    right={"0px"}
                                    top={"0px"}
                                    width={"24px"}
                                    height={"24px"}
                                    onClick={() => {
                                        handleDeleteFileInArray(specificationUploadKey, item)
                                    }}
                                    borderRadius={"100px"}
                                    backgroundColor={Colors.GRAY_8}
                                    src={require("assets/images/ic-close-24px.svg").default}
                                    alt="remove-file-icon"/>
                            </FlexBox>
                        })}
                        <label
                            htmlFor={specificationUploadKey}>
                            <FlexBox
                                marginTop={"8px"}
                                width={"160px"}
                                height={"160px"}
                                borderRadius={"24px"}
                                cursor={"pointer"}
                                border={`1px solid ${Colors.GRAY_2}`}
                                backgroundColor={Colors.GRAY_0}
                                justifyContent={"center"}
                                alignItems={"center"}>
                                <Image
                                    src={require("assets/images/ic-add-box-40px.svg").default}
                                    alt="add-icon"/>
                                <input
                                    type="file"
                                    id={specificationUploadKey}
                                    style={{display: "none"}}
                                    multiple={true}
                                    onChange={onChangeFiles}
                                />
                            </FlexBox>
                        </label>
                    </FlexBox>
                    <NewH4
                        marginTop={"32px"}
                        color={Colors.GRAY_8}>
                        Sourcing Information
                    </NewH4>
                    <FlexBox
                        marginTop={"24px"}>
                        <SelectBox items={ProductGradeTypes}
                                   width={"100%"}
                                   label={'Product Grade'}
                                   labelVisible={true}
                                   labelFontWight={500}
                                   value={productAddModel.sourcing?.productGradeCode || ""}
                                   onChange={(e) => {
                                       setProductAddModel((prevState) => {
                                           return {
                                               ...prevState,
                                               sourcing: {
                                                   ...prevState.sourcing,
                                                   productGradeCode: e.target.value
                                               }
                                           }
                                       })
                                   }}
                                   labelFontSize={"16px"}
                        />
                        <TextField
                            width={"100%"}
                            marginLeft={"24px"}
                            labelFontWight={500}
                            label={'Approved Exporter'}
                            value={productAddModel.sourcing?.approvedExporterId || ""}
                            onChange={(e) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        sourcing: {
                                            ...prevState.sourcing,
                                            approvedExporterId: e.target.value
                                        }
                                    }
                                })
                            }}
                            onClear={() => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        sourcing: {
                                            ...prevState.sourcing,
                                            approvedExporterId: ""
                                        }
                                    }
                                })
                            }}
                            labelFontSize={"16px"}
                            enabledClearButton={true}
                            type={"text"}
                        />
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <FlexBox
                            flexDirection={"column"}
                            width={"100%"}>
                            <NewP1
                                fontSize={"16px"}
                                color={Colors.GRAY_7}>
                                OEM
                            </NewP1>
                            <FlexBox
                                width={"100%"}
                                marginTop={"8px"}
                                height={"52px"}>
                                <NewRadioItem
                                    title={`${t('available')}`}
                                    checked={productAddModel.sourcing?.oem || false}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    oem: true
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                                <NewRadioItem
                                    marginLeft={"16px"}
                                    title={`${t('no_relation')}`}
                                    checked={!productAddModel.sourcing?.oem}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    oem: false
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                            </FlexBox>
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            flexDirection={"column"}
                            width={"100%"}>
                            <NewP1
                                fontSize={"16px"}
                                color={Colors.GRAY_7}>
                                ODM
                            </NewP1>
                            <FlexBox
                                width={"100%"}
                                marginTop={"8px"}
                                height={"52px"}>
                                <NewRadioItem
                                    title={`${t('available')}`}
                                    checked={productAddModel.sourcing?.odm || false}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    odm: true
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                                <NewRadioItem
                                    marginLeft={"16px"}
                                    title={`${t('no_relation')}`}
                                    checked={!productAddModel.sourcing?.odm}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    odm: false
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <FlexBox
                            flexDirection={"column"}
                            width={"100%"}>
                            <NewP1
                                fontSize={"16px"}
                                color={Colors.GRAY_7}>
                                Korean Label
                            </NewP1>
                            <FlexBox
                                width={"100%"}
                                marginTop={"8px"}
                                height={"52px"}>
                                <NewRadioItem
                                    title={`${t('available')}`}
                                    checked={productAddModel.sourcing?.koreanLabel || false}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    koreanLabel: true
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                                <NewRadioItem
                                    marginLeft={"16px"}
                                    title={`${t('no_relation')}`}
                                    checked={!productAddModel.sourcing?.koreanLabel || false}
                                    onClick={() => {
                                        setProductAddModel((prevState) => {
                                            return {
                                                ...prevState,
                                                sourcing: {
                                                    ...prevState.sourcing,
                                                    koreanLabel: false
                                                }
                                            }
                                        })
                                    }}
                                    height={"100%"}/>
                            </FlexBox>
                        </FlexBox>
                        <SelectBox items={ProductBuyerTypes}
                                   marginLeft={"24px"}
                                   width={"100%"}
                                   label={'Korean Label Select'}
                                   labelVisible={true}
                                   labelFontWight={500}
                                   value={productAddModel.sourcing?.buyersCostCode || ""}
                                   onChange={(e) => {
                                       setProductAddModel((prevState) => {
                                           return {
                                               ...prevState,
                                               sourcing: {
                                                   ...prevState.sourcing,
                                                   buyersCostCode: e.target.value
                                               }
                                           }
                                       })
                                   }}
                                   labelFontSize={"16px"}
                        />
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <NAutoComplete
                            width={"100%"}
                            labelFontWight={500}
                            labelFontSize={"16px"}
                            label={'Certificates'}
                            items={certifications.map((item) => {
                                return {
                                    code: item.id,
                                    name: item.name
                                }
                            })}
                            values={productAddModel.certificationList.map((item) => {
                                return {
                                    code: item.certificationId,
                                    name: item.certificationName
                                }
                            })}
                            onChangedValues={(values) => {
                                setProductAddModel((prevState) => {
                                    return {
                                        ...prevState,
                                        certificationList: values.map((item) => {
                                            return {
                                                certificationId: item.code,
                                                certificationName: item.name
                                            }
                                        })
                                    }
                                })
                            }}
                        />
                        <FlexBox
                            marginLeft={"24px"}
                            width={"100%"}
                        />
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"32px"}>
                        Product Catalog
                    </NewH4>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_7}>
                        {t('maximum_five_files_upload')}
                    </NewP2>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={"16px"}>
                        {catalogFiles.map((item, index) => {
                            return <FlexBox
                                minWidth={"164px"}
                                maxWidth={"164px"}
                                paddingRight={"8px"}
                                paddingTop={"8px"}
                                flexWrap={"wrap"}
                                flexDirection={"column"}
                                marginRight={"12px"}>
                                <Image
                                    width={"160px"}
                                    height={"160px"}
                                    borderRadius={"24px"}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    backgroundColor={Colors.GRAY_0}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    src={URL.createObjectURL(item)}
                                    alt={`company-image-${index}`}/>
                                <NewH5
                                    marginTop={"8px"}
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`${item.name}`}
                                </NewH5>
                                <NewH5
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`(${FileUtils.convertBytesToMegaBytes(item.size)}MB)`}
                                </NewH5>
                                <Image
                                    cursor={"pointer"}
                                    position={"absolute"}
                                    right={"0px"}
                                    top={"0px"}
                                    width={"24px"}
                                    height={"24px"}
                                    onClick={() => {
                                        handleDeleteFileInArray(catalogUploadKey, item)
                                    }}
                                    borderRadius={"100px"}
                                    backgroundColor={Colors.GRAY_8}
                                    src={require("assets/images/ic-close-24px.svg").default}
                                    alt="remove-file-icon"/>
                            </FlexBox>
                        })}
                        <label
                            htmlFor={catalogUploadKey}>
                            <FlexBox
                                marginTop={"8px"}
                                width={"160px"}
                                height={"160px"}
                                borderRadius={"24px"}
                                cursor={"pointer"}
                                border={`1px solid ${Colors.GRAY_2}`}
                                backgroundColor={Colors.GRAY_0}
                                justifyContent={"center"}
                                alignItems={"center"}>
                                <Image
                                    src={require("assets/images/ic-add-box-40px.svg").default}
                                    alt="add-icon"/>
                                <input
                                    type="file"
                                    id={catalogUploadKey}
                                    style={{display: "none"}}
                                    multiple={true}
                                    onChange={onChangeFiles}
                                />
                            </FlexBox>
                        </label>
                    </FlexBox>
                    <FlexBox
                        alignSelf={"center"}
                        width={"600px"}
                        marginTop={"60px"}>
                        <NewButton
                            onClick={() => {
                                setStep3Checked(true)
                                setStep3Completed(true)
                            }}>
                            {t('next')}
                        </NewButton>
                        <NewButton
                            fontWeight={500}
                            border={`1px solid ${Colors.GRAY_4}`}
                            marginLeft={"24px"}
                            colorType={5}
                            onClick={() => {
                                handleSaveClick(ProductStatus.TEMP_DRAFT)
                            }}>
                            {t('temp_draft')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </AccordionDetails>
        </Accordion>
        <Accordion
            sx={{
                display: step3Checked ? "block" : "none",
                marginTop: "40px"
            }}
            expanded={true}>
            <AccordionSummary
                expandIcon={<Image
                    width={"32px"}
                    height={"32px"}
                    src={require("assets/images/ic-unchecked-circle-32px.svg").default}
                    alt="ic-unchecked-step1"/>}>
                <FlexBox
                    alignItems={"center"}>
                    <NewH3
                        color={Colors.MAIN}>
                        4.
                    </NewH3>
                    <NewH3
                        marginLeft={"8px"}
                        color={Colors.GRAY_8}>
                        {t('last_packing_info')}
                    </NewH3>
                </FlexBox>
            </AccordionSummary>
            <AccordionDetails>
                <FlexBox
                    flexDirection={"column"}>
                    <FlexBox
                        marginTop={"16px"}>
                        <FlexBox
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                hint={'예) 12ea x 500g'}
                                width={"100%"}
                                label={'Product packing'}
                                labelFontWight={500}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                value={productAddModel.packing?.productPacking || ""}
                                onClear={() => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                productPacking: ""
                                            }
                                        }
                                    })
                                }}
                                onChange={(e) => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                productPacking: e.target.value
                                            }
                                        }
                                    })
                                }}
                                type={"text"}
                            />
                            <SelectBox items={ProductUnitTypes}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Unit'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.packing?.packingUnitCode || ""}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   packing: {
                                                       ...prevState.packing,
                                                       packingUnitCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                hint={'예) 1,584'}
                                width={"100%"}
                                label={'Loading Capacity'}
                                labelFontWight={500}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                value={productAddModel.packing?.loadingCapacity || ""}
                                onClear={() => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                loadingCapacity: ""
                                            }
                                        }
                                    })
                                }}
                                onChange={(e) => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                loadingCapacity: e.target.value
                                            }
                                        }
                                    })
                                }}
                                type={"text"}
                            />
                            <SelectBox items={ProductLoadingCapacityUnit}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Unit'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.packing?.loadingCapacityUnitCode || ""}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   packing: {
                                                       ...prevState.packing,
                                                       loadingCapacityUnitCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        marginTop={"24px"}>
                        <FlexBox
                            width={'100%'}
                            alignItems={"flex-end"}>
                            <TextField
                                hint={'예) Normandy Port'}
                                width={"100%"}
                                label={'Loading Port'}
                                labelFontWight={500}
                                labelFontSize={"16px"}
                                enabledClearButton={true}
                                value={productAddModel.packing?.loadingPort || ""}
                                onClear={() => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                loadingPort: ""
                                            }
                                        }
                                    })
                                }}
                                onChange={(e) => {
                                    setProductAddModel((prevState) => {
                                        return {
                                            ...prevState,
                                            packing: {
                                                ...prevState.packing,
                                                loadingPort: e.target.value
                                            }
                                        }
                                    })
                                }}
                                type={"text"}
                            />
                            <SelectBox items={countries}
                                       width={"200px"}
                                       marginLeft={"16px"}
                                       label={'Country'}
                                       labelVisible={false}
                                       labelFontWight={500}
                                       value={productAddModel.packing?.loadingPortCountryCode || ""}
                                       onChange={(e) => {
                                           setProductAddModel((prevState) => {
                                               return {
                                                   ...prevState,
                                                   packing: {
                                                       ...prevState.packing,
                                                       loadingPortCountryCode: e.target.value
                                                   }
                                               }
                                           })
                                       }}
                                       labelFontSize={"16px"}
                            />
                        </FlexBox>
                        <FlexBox
                            marginLeft={"24px"}
                            width={'100%'}
                            alignItems={"flex-end"}/>
                    </FlexBox>
                    <NewH4
                        color={Colors.GRAY_8}
                        marginTop={"32px"}>
                        Pictures
                    </NewH4>
                    <NewP2
                        marginTop={"16px"}
                        color={Colors.GRAY_7}>
                        {t('maximum_five_files_upload')}
                    </NewP2>
                    <FlexBox
                        alignItems={"flex-start"}
                        marginTop={"16px"}>
                        {packingPictureFiles.map((item, index) => {
                            return <FlexBox
                                minWidth={"164px"}
                                maxWidth={"164px"}
                                paddingRight={"8px"}
                                paddingTop={"8px"}
                                flexWrap={"wrap"}
                                flexDirection={"column"}
                                marginRight={"12px"}>
                                <Image
                                    width={"160px"}
                                    height={"160px"}
                                    borderRadius={"24px"}
                                    border={`1px solid ${Colors.GRAY_2}`}
                                    backgroundColor={Colors.GRAY_0}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    src={URL.createObjectURL(item)}
                                    alt={`company-image-${index}`}/>
                                <NewH5
                                    marginTop={"8px"}
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`${item.name}`}
                                </NewH5>
                                <NewH5
                                    maxWidth={"160px"}
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                    textOverflow={"ellipsis"}
                                    flexWrap={"wrap"}>
                                    {`(${FileUtils.convertBytesToMegaBytes(item.size)}MB)`}
                                </NewH5>
                                <Image
                                    cursor={"pointer"}
                                    position={"absolute"}
                                    right={"0px"}
                                    top={"0px"}
                                    width={"24px"}
                                    height={"24px"}
                                    onClick={() => {
                                        handleDeleteFileInArray(packingPictureUploadKey, item)
                                    }}
                                    borderRadius={"100px"}
                                    backgroundColor={Colors.GRAY_8}
                                    src={require("assets/images/ic-close-24px.svg").default}
                                    alt="remove-file-icon"/>
                            </FlexBox>
                        })}
                        <label
                            htmlFor={packingPictureUploadKey}>
                            <FlexBox
                                marginTop={"8px"}
                                width={"160px"}
                                height={"160px"}
                                borderRadius={"24px"}
                                cursor={"pointer"}
                                border={`1px solid ${Colors.GRAY_2}`}
                                backgroundColor={Colors.GRAY_0}
                                justifyContent={"center"}
                                alignItems={"center"}>
                                <Image
                                    src={require("assets/images/ic-add-box-40px.svg").default}
                                    alt="add-icon"/>
                                <input
                                    type="file"
                                    id={packingPictureUploadKey}
                                    style={{display: "none"}}
                                    multiple={true}
                                    onChange={onChangeFiles}
                                />
                            </FlexBox>
                        </label>
                    </FlexBox>
                    <FlexBox
                        alignSelf={"center"}
                        width={"600px"}
                        marginTop={"60px"}>
                        <NewButton
                            onClick={() => {
                                handleSaveClick(ProductStatus.APPROVAL_PENDING)
                            }}>
                            {t('new_product_submit')}
                        </NewButton>
                        <NewButton
                            fontWeight={500}
                            border={`1px solid ${Colors.GRAY_4}`}
                            marginLeft={"24px"}
                            colorType={5}
                            onClick={() => {
                                handleSaveClick(ProductStatus.TEMP_DRAFT)
                            }}>
                            {t('temp_draft')}
                        </NewButton>
                    </FlexBox>
                </FlexBox>
            </AccordionDetails>
        </Accordion>
    </FlexBox>
}
export default ProductAddFragment