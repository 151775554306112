import React, {ChangeEventHandler, FC, HTMLInputTypeAttribute, useEffect, useRef} from "react";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Text from "../styledcomponents/Text";
import Input from "../styledcomponents/Input";
import {CommonProps} from "../styledcomponents/CommonComponent";
import Image from "../styledcomponents/Image";

export interface TextFieldProps extends CommonProps {
    required?: boolean
    hint?: string;
    error?: boolean;
    errorText?: string;
    errorTextGap?: string;
    errorFontSize?: string;
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    labelVisible?: boolean;
    maxLength?: number;
    disabled?: boolean;
    enabledClearButton?: boolean;
    onClear?: () => void

    // input
    type?: HTMLInputTypeAttribute | undefined;
    value?: string | ReadonlyArray<string> | number | undefined;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

const TextField: FC<TextFieldProps> = ({
                                           required = false,
                                           disabled = undefined,
                                           maxLength = undefined,
                                           labelVisible = true,
                                           label = "",
                                           enabledClearButton = false,
                                           error = false,
                                           errorText = "",
                                           errorTextGap = "12px",
                                           errorFontSize = "14px",
                                           labelGap = "8px",
                                           labelFontSize = "14px",
                                           labelColor = Colors.GRAY_7,
                                           labelFontWight = 500,
                                           hint = "",
                                           onClear = () => {
                                           },
                                           ...props
                                       }) => {
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (error) {
            inputRef?.current?.focus()
        }
    }, [error])

    return <FlexBox flexDirection={"column"}
                    alignItems={"flex-start"}
                    width={"100%"}
                    {...props}>
        {labelVisible
            && <Text fontSize={labelFontSize}
                     color={labelColor}
                     lineHeight={"150%"}
                     fontWeight={labelFontWight}>
                {required ? <span style={{color: Colors.RED}}>* </span> : ""}{label}</Text>}
        <FlexBox
            width={"100%"}
            marginTop={labelVisible ? labelGap : undefined}
            alignItems={"center"}>
            <Input
                autoComplete={"off"}
                disabled={disabled}
                ref={inputRef}
                width={"100%"}
                alignSelf={"center"}
                placeholder={hint}
                maxLength={maxLength}
                error={error}
                paddingRight={enabledClearButton ? "48px" : "16px"}
                onChange={props.onChange}
                type={props.type}
                value={props.value}
            />
            {enabledClearButton && <Image
                visible={props.value ? props.value.toString().length > 0 : false}
                cursor={"pointer"}
                position={"absolute"}
                width={"24px"}
                height={"24px"}
                right={"16px"}
                onClick={() => {
                    onClear()
                }}
                src={require("assets/images/ic-input-clear-24px.svg").default}
                alt="ic-clear-button"/>}
        </FlexBox>
        {error && <Text
            color={Colors.INPUT_ERROR}
            marginTop={errorTextGap}
            fontSize={errorFontSize}>{errorText}</Text>}
    </FlexBox>
}

export default TextField