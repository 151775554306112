import {ProductStatus} from "../model/ProductStatus";
import {ProductUnitTypes} from "../model/CodeModel";

export class TextUtils {
    static itemStatusToUiText(itemStatus: string): string {
        switch (itemStatus) {
            case ProductStatus.TEMP_DRAFT:
                return "temp_draft"
            case ProductStatus.PRODUCT_DISPLAY:
                return "product_display"
            case ProductStatus.APPROVAL_APPROVED:
                return "completed_approval"
            case ProductStatus.APPROVAL_PENDING:
                return "request_approval"
            case ProductStatus.APPROVAL_REJECTED:
                return "reject_approval"
            case ProductStatus.PUBLICATION_SUSPENDED:
                return "stop_product_display"
            default:
                return "unknown"
        }
    }

    static packagingUnitCodeToUiText(unitCode: string): string {
        return ProductUnitTypes.find(item => item.code === unitCode)?.name || unitCode
    }
}