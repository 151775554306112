/**
 * @author jaeho.lee104 on 2023. 08. 25..
 */
import React, {useEffect, useState} from "react";
import {Dialog} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "../styledcomponents/text/NewH3";
import Image from "../styledcomponents/Image";
import NewButton from "../styledcomponents/NewButton";
import {useTranslation} from "react-i18next";
import NewP1 from "../styledcomponents/text/NewP1";
import {Colors} from "../styledcomponents/Styles";
import {CertificationModel} from "../model/CertificationModel";
import SelectBox from "../components/SelectBox";

const theme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: "480px",
                    borderRadius: "16px"
                }
            }
        }
    },
    palette: {
        background: {
            paper: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#46505A',
        },
        action: {
            active: '#001E3C',
        },
    },
});

export interface SelectCertificationProps {
    isOpen: boolean
    items: Array<CertificationModel>
    onClose: (positive: boolean, selected?: CertificationModel) => void
}

const SelectCertificationDialog: React.FC<{
    dialogProps: SelectCertificationProps
}> = ({dialogProps}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedCertification, setSelectedCertification] = useState<string>("")
    const {t} = useTranslation()

    useEffect(() => {
        setIsOpen(dialogProps.isOpen);
        if (!dialogProps.isOpen) {
            setSelectedCertification("")
        }
    }, [dialogProps.isOpen]);

    const handleOk = () => {
        if (!isOpen) {
            return;
        }
        if (!selectedCertification) {
            alert(t('select_certification'))
            return;
        }
        let found = dialogProps.items.find((item) => item.id === selectedCertification)
        if (!found) {
            alert(t('not_found_certification'))
            return;
        }
        dialogProps.onClose(true, found);
    };

    const handleCancel = () => {
        if (!isOpen) {
            return;
        }
        dialogProps.onClose(false);
    };

    return <>
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleCancel}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleOk();
                    }
                }}>
                <FlexBox
                    flexDirection={"column"}
                    padding={"32px"}>
                    <FlexBox
                        alignItems={"center"}
                        justifyContent={"space-between"}>
                        <NewH3>
                            인증서 추가
                        </NewH3>
                        <Image
                            cursor={"pointer"}
                            onClick={handleCancel}
                            width={"20px"}
                            height={"20px"}
                            src={require("assets/images/ic-close-20.svg").default}
                            alt="ic-close"/>
                    </FlexBox>
                    <NewP1
                        marginTop={"24px"}
                        color={Colors.GRAY_7}>
                        인증서를 선택해주세요.
                    </NewP1>
                    <SelectBox
                        marginTop={"24px"}
                        items={dialogProps.items.map((item) => {
                            return {
                                code: item.id,
                                name: item.name
                            }
                        })}
                        width={"100%"}
                        label={'Certification'}
                        labelVisible={false}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        value={selectedCertification}
                        onChange={(e) => {
                            setSelectedCertification(e.target.value)
                        }}
                    />
                    <NewButton
                        marginTop={"32px"}
                        height={"52px"}
                        colorType={0}
                        width={"100%"}
                        fontSize={"16px"}
                        fontWeight={500}
                        borderRadius={"12px"}
                        onClick={handleOk}>
                        추가하기
                    </NewButton>
                </FlexBox>
            </Dialog>
        </ThemeProvider>
    </>
}

export default SelectCertificationDialog