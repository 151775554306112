import {NetworkCommon} from "network/NetworkCommon";
import {ChangePasswordRequestBody} from "../model/ChangePasswordRequestBody";
import {SignInRequestBody} from "../model/SignInRequestBody";
import {SignUpRequestBody} from "../model/SignUpRequestBody";
import {VerifyEmailCodeRequestBody} from "../model/VerifyEmailCodeRequestBody";
import {RequestEmailCodeRequestBody} from "../model/RequestEmailCodeRequestBody";
import {UpdateUserInfoRequestBody} from "../model/UpdateUserInfoRequestBody";
import {AxiosResponse} from "axios";
import {RefreshTokenResult} from "../model/RefreshTokenResult";
import {SignInResult} from "../model/SignInResult";
import {FindPasswordRequestBody} from "../model/FindPasswordRequestBody";
import {ChangePasswordWithKeyRequestBody} from "../model/ChangePasswordWithKeyRequestBody";
import {User} from "../model/User";
import {UsingServiceResult} from "../model/UsingServiceResult";
import {CompanyUserModel} from "../model/CompanyUserModel";
import {GoogleUserInfo} from "../model/GoogleUserInfo";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class UsersAPI {

    static getUserOrNull(): Promise<User | null> {
        return UsersAPI.getUser()
            .then((response: AxiosResponse<BaseResponse<User>>) => {
                return response.data.data
            })
            .catch(e => {
                return null
            })
    }

    static getUser(): Promise<AxiosResponse<BaseResponse<User>>> {
        return instance.get("/user");
    }

    static getUsingService(): Promise<AxiosResponse<BaseResponse<UsingServiceResult>>> {
        return instance.get("/user/billing/service");
    }

    static updateUser(userInfo: UpdateUserInfoRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put("/user", userInfo);
    }

    static requestEmailCode(requestEmailCodeRequestBody: RequestEmailCodeRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post("/user/mailCheck", requestEmailCodeRequestBody);
    }

    static verifyEmailCode(verifyEmailCodeRequestBody: VerifyEmailCodeRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post("/user/mailCheck/confirm", verifyEmailCodeRequestBody);
    }

    static signUp(signUpRequestBody: SignUpRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post("/user/signUp", signUpRequestBody);
    }

    static signIn(signInRequestBody: SignInRequestBody): Promise<AxiosResponse<BaseResponse<SignInResult>>> {
        return instance.post("/user/signIn", signInRequestBody);
    }

    static refreshToken(): Promise<AxiosResponse<BaseResponse<RefreshTokenResult>>> {
        return instance.post("/user/refreshToken");
    }

    static changePassword(changePasswordRequestBody: ChangePasswordRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put("/user/password", changePasswordRequestBody);
    }

    static findPassword(email: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post("/user/password_reset", new FindPasswordRequestBody(email));
    }

    static checkPasswordKey(key: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.get(`/user/password_reset/${key}`);
    }

    static changePasswordWithKey(changePasswordWithKeyRequestBody: ChangePasswordWithKeyRequestBody): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/user/password_reset/`, changePasswordWithKeyRequestBody);
    }

    static signOut(): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post("/user/signOut");
    }

    static deleteUser(): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete("/user");
    }

    static signUpCompany(companyUserModel: CompanyUserModel, businessLicenseFile: File): Promise<AxiosResponse<BaseResponse<null>>> {
        let formData = new FormData()
        formData.append("businessLicense", businessLicenseFile)
        formData.append("user", new Blob([JSON.stringify(companyUserModel)], {
            type: "application/json"
        }))
        return instance.post("/user/company/signUp", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static signInWithGoogleOauth(code: string): Promise<AxiosResponse<BaseResponse<GoogleUserInfo>>> {
        return instance.get("/user/oauth2/google/info", {
            params: {
                code: code
            },
        });
    }
}