/**
 * @author jaeho.lee104 on 2023. 11. 27..
 */
import {NetworkCommon} from "./NetworkCommon";
import {AuthenticateCompanyModel} from "../model/AuthenticateCompanyModel";
import {AxiosResponse} from "axios";
import {UserCompanyModel} from "../model/UserCompanyModel";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class CompanyAPI {

    static getCompany(): Promise<AxiosResponse<BaseResponse<Array<UserCompanyModel>>>> {
        return instance.get("/companies");
    }

    static authenticateCompany(companyModel: AuthenticateCompanyModel, businessLicenseFile: File) {
        let formData = new FormData()
        formData.append("businessLicense", businessLicenseFile)
        formData.append("company", new Blob([JSON.stringify(companyModel)], {
            type: "application/json"
        }))
        return instance.post("/companies", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}