import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import Image from "../styledcomponents/Image";
import React, {ChangeEvent, useEffect, useState} from "react";
import NewH2 from "../styledcomponents/text/NewH2";
import Flag from "react-flagkit";
import NewH7 from "../styledcomponents/text/NewH7";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import Text from "../styledcomponents/Text";
import NewH4 from "../styledcomponents/text/NewH4";
import TextField from "../components/TextField";
import NewP1 from "../styledcomponents/text/NewP1";
import {NewRadioItem} from "../components/NewRadioItem";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH5 from "../styledcomponents/text/NewH5";
import NewButton from "../styledcomponents/NewButton";
import {CommonApi} from "../network/CommonApi";
import {ResponseCodes} from "../network/ResponseCodes";
import {DEFAULT_COUNTRIES} from "../model/CountryModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import SelectBox from "../components/SelectBox";
import {KeyboardUtils} from "../utils/KeyboardUtils";
import NAutoComplete from "../components/NAutoComplete";
import {BusinessTypes, CodeModel, MainMarketTypes, ProductCapacityTypes, RnDCapacityTypes} from "../model/CodeModel";
import NTextArea from "../components/NTextArea";
import NTextField from "../components/NTextField";
import NewH6 from "../styledcomponents/text/NewH6";
import AddExporterNumberDialog from "../dialog/AddExporterNumberDialog";
import {Chip} from "@mui/material";
import {CertificationModel} from "../model/CertificationModel";
import SelectCertificationDialog from "../dialog/SelectCertificationDialog";
import {SolutionAPI} from "../network/SolutionAPI";
import {CompanyDetailSaveModel, EMPTY_COMPANY_DETAIL_SAVE_MODEL} from "../model/CompanyDetailSaveModel";
import {CustomAuthNumber} from "../model/CustomAuthNumber";
import {useTranslation} from "react-i18next";
import {FileUtils} from "../utils/FileUtils";
import {CommonFileModel} from "../model/CommonFileModel";
import {CompanyStatus} from "../model/CompanyStatus";
import {BusinessUserState} from "model/BusinessUserState";

/**
 * @author jaeho.lee104 on 2023. 11. 20..
 */
const CompanyManageFragment = () => {

    const {signedIn, businessUserState} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const payUnits: Array<CodeModel> = [
        // {
        //     code: "KRW",
        //     name: "KRW"
        // },
        {
            code: "USD",
            name: "USD"
        }]
    const {t} = useTranslation()
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);
    const [countries, setCountries] = useState<Array<CodeModel>>(DEFAULT_COUNTRIES.map(item => {
        return {
            code: item.countryCode,
            name: item.countryName
        }
    }))
    const [adminCategories, setAdminCategories] = useState<Array<CodeModel>>([])
    const [isOpenAddCustomAuthNumberDialog, setOpenAddCustomAuthNumberDialog] = useState(false)
    const [certifications, setCertifications] = useState<Array<CertificationModel>>([])
    const [isOpenSelectCertificationDialog, setOpenSelectCertificationDialog] = useState(false)
    const [companyDetailModel, setCompanyDetailModel] = useState<CompanyDetailSaveModel>(EMPTY_COMPANY_DETAIL_SAVE_MODEL)

    //TODO update field
    const [payUnit, setPayUnit] = useState("USD")
    const [webSite, setWebSite] = useState("")
    //TODO end

    const [totalRatio, setTotalRatio] = useState(0)
    const [companyImageFiles, setCompanyImageFiles] = useState<Array<File>>([])
    const [iconFile, setIconFile] = useState<File | null>(null)

    useEffect(() => {
        if (businessUserState !== BusinessUserState.ACQUIRED) {
            return
        }
        SolutionAPI.getCompanyDetail()
            .then(async (response) => {
                let companyDetail = response.data?.data
                let responseCode = ResponseCodes.of(response.data?.code)
                if (!responseCode.isSuccess()) {
                    return
                }
                if (!companyDetail) {
                    return
                }
                let validExportHistory = companyDetail.exportHistoryList
                if (!validExportHistory) {
                    validExportHistory = [
                        {
                            countryCd: "",
                            exportPercent: ""
                        },
                        {
                            countryCd: "",
                            exportPercent: ""
                        },
                        {
                            countryCd: "",
                            exportPercent: ""
                        },
                        {
                            countryCd: "",
                            exportPercent: ""
                        },
                        {
                            countryCd: "",
                            exportPercent: ""
                        }
                    ]
                } else {
                    let addCount = 5 - validExportHistory.length
                    if (addCount > 0) {
                        for (let i = 0; i < addCount; i++) {
                            validExportHistory.push({
                                countryCd: "",
                                exportPercent: ""
                            })
                        }
                    }
                }
                setCompanyDetailModel({
                    address: companyDetail.address || "",
                    annualRevenue: companyDetail.annualRevenue || "",
                    annualOutputCapacity: companyDetail.annualOutputCapacity || "",
                    businessTypeList: companyDetail.businessTypeList || [],
                    capacityCountry: companyDetail.capacityCountry || "",
                    categoryList: companyDetail.categoryList || [],
                    certificatesList: companyDetail.certificatesList || [],
                    companyNm: companyDetail.companyName || "",
                    companyOverview: companyDetail.companyOverview || "",
                    country: companyDetail.country || "",
                    customAuthNumList: companyDetail.customAuthNumList || [],
                    customAuthYn: companyDetail.customAuthYn || "N",
                    employeeCount: companyDetail.employeeCount || 0,
                    establishedYear: companyDetail.establishedYear || "",
                    exportHistoryList: validExportHistory,
                    incoterms: companyDetail.incoterms || "",
                    mainMarketList: companyDetail.mainMarketList || [],
                    nearestPort: companyDetail.nearestPort || "",
                    productionCapacity: companyDetail.productionCapacity || "",
                    productionCapacityUnitCd: companyDetail.productionCapacityUnitCd || "",
                    productionFactoryCountryCd: companyDetail.productionFactoryCountryCd || "",
                    productionFactoryNm: companyDetail.productionFactoryNm || "",
                    rdCapacity: companyDetail.rdCapacity || "",
                    exportExperience: companyDetail.exportExperience || false,
                    termsOfPayment: companyDetail.termsOfPayment || "",
                    statusCode: companyDetail.statusCode || CompanyStatus.TEMP_DRAFT
                })
                await updateIcon(companyDetail.iconFile)
                await updateImageFiles(companyDetail.companyShowList || [])

            })
    }, [businessUserState])

    async function updateImageFiles(companyShowList: Array<CommonFileModel>) {
        const newArray: Array<File> = []
        for (let i = 0; i < companyShowList.length; i++) {
            const companyShow = companyShowList[i]
            const response = await fetch(companyShow.url)
            const data = await response.blob();
            const metadata = {type: `image/png`};
            console.log("url", companyShow.url)
            newArray.push(new File([data], companyShow.name || companyShow.id, metadata))
        }
        setCompanyImageFiles(newArray)
    }

    async function updateIcon(iconFile: CommonFileModel | null) {
        if (iconFile == null) {
            return
        }
        const response = await fetch(iconFile.url)
        const data = await response.blob();
        const metadata = {type: `image/png`};
        console.log("url", iconFile.url)
        setIconFile(new File([data], iconFile.name || iconFile.id, metadata))
    }

    useEffect(() => {
        CommonApi.getCountries(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const countries = response.data.data
                if (responseCode.isSuccess() && countries && countries.length > 0) {
                    setCountries(countries.map(item => {
                        return {
                            code: item.countryCode,
                            name: item.countryName
                        }
                    }))
                }
            })
    }, [language])

    useEffect(() => {
        CommonApi.getCategories(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const categories = response.data.data
                if (responseCode.isSuccess() && categories && categories.length > 0) {
                    setAdminCategories(categories.map(item => {
                        return {
                            code: item.categoryId,
                            name: item.categoryName
                        }
                    }))
                }
            })
    }, [language])

    useEffect(() => {
        CommonApi.getCertifications(language)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                const certifications = response.data.data
                if (responseCode.isSuccess() && certifications && certifications.length > 0) {
                    setCertifications(certifications)
                }
            })
    }, [language])

    useEffect(() => {
        let total = 0
        companyDetailModel.exportHistoryList.forEach((item) => {
            total += Number(item.exportPercent)
        })
        setTotalRatio(total)
    }, [companyDetailModel.exportHistoryList])

    function handleExporterNumberDelete(deleteCustomAuthNumber: CustomAuthNumber) {
        let customAuthNumberList = companyDetailModel.customAuthNumList
        let foundIndex = customAuthNumberList.indexOf(deleteCustomAuthNumber)
        if (foundIndex < 0) {
            return
        }
        customAuthNumberList.splice(foundIndex, 1)
        setCompanyDetailModel((prevState) => {
            return {
                ...prevState,
                customAuthNumList: customAuthNumberList
            }
        })
    }

    const onChangeIconFile = (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFile: File | null;
        selectFile = e.target.files ? e.target.files[0] : null;
        e.target.value = null
        if (selectFile) {
            let split = selectFile.name.split('.')
            if (split.length < 2) {
                alert(t('check_add_png'))
                return
            }
            let extension = split.pop()
            if (extension !== 'png') {
                alert(t('check_add_png'))
                return
            }
            if (selectFile.size > 1 * 1024 * 1024) {
                alert(t('check_maximum_size_1_mega'))
                return;
            }
            setIconFile(selectFile)
        }
    }
    const onChangeFiles = (e: ChangeEvent<HTMLInputElement> | any): void => {
        let selectFile: File | null;
        selectFile = e.target.files ? e.target.files[0] : null;
        e.target.value = null
        if (selectFile) {
            let split = selectFile.name.split('.')
            if (split.length < 2) {
                alert(t('check_add_png'))
                return
            }
            let extension = split.pop()
            if (extension !== 'png') {
                alert(t('check_add_png'))
                return
            }
            if (companyImageFiles.length === 5) {
                alert(t('check_maximum_five_files'))
                return;
            }
            if (selectFile.size > 1 * 1024 * 1024) {
                alert(t('check_maximum_size_1_mega'))
                return;
            }
            let newArray: Array<File> = companyImageFiles.slice()
            newArray.push(selectFile)
            setCompanyImageFiles(newArray)
        }
    }

    function handleCompanyImageDelete(deleteFile: File) {
        let newArray: Array<File> = []
        companyImageFiles.forEach((item) => {
            if (item.name === deleteFile.name) {
                return
            }
            newArray.push(item)
        })
        setCompanyImageFiles(newArray)
    }

    function handleCertificationDelete(deleteCertificationModel: CertificationModel) {
        let certificationList = companyDetailModel.certificatesList
        let foundIndex = certificationList.indexOf(deleteCertificationModel)
        if (foundIndex < 0) {
            return
        }
        certificationList.splice(foundIndex, 1)
        setCompanyDetailModel((prevState) => {
            return {
                ...prevState,
                certificatesList: certificationList
            }
        })
    }

    function handleSaveButtonClick(status: string) {
        if (companyImageFiles.length === 0) {
            alert(t('check_just_one_company_show_image'))
            return
        }
        SolutionAPI.saveCompanyDetail({
            ...companyDetailModel,
            statusCode: status
        }, companyImageFiles, iconFile)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    alert(t('completed'))
                } else {
                    alert(`${response.data?.code} ${response.data?.message}`)
                }
            })
            .catch((e) => {
                alert(t('fail'))
            })
    }

    return (
        <FlexBox
            borderRadius={"16px"}
            width={"1200px"}
            boxShadow={"0px 4px 8px 0px rgba(0, 0, 0, 0.08);"}
            border={`1px solid ${Colors.GRAY_3}`}
            backgroundColor={Colors.WHITE}
            padding={"56px 32px"}
            flexDirection={"column"}
            margin={"0 auto"}>
            <FlexBox
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}>
                <FlexBox>
                    <label
                        htmlFor={"fileUpload"}>
                        <FlexBox
                            borderRadius={"100px"}
                            border={`1px solid ${Colors.GRAY_3}`}
                            backgroundColor={Colors.GRAY_1}
                            width={"80px"}
                            height={"80px"}
                            alignItems={"center"}
                            overflow={"hidden"}
                            justifyContent={"center"}>
                            {iconFile && <Image
                                width={"80px"}
                                height={"80px"}
                                cursor={"pointer"}
                                objectFit={"cover"}
                                src={URL.createObjectURL(iconFile)}
                                alt="company-icon"/>}
                            {!iconFile && <Image
                                cursor={"pointer"}
                                src={require("assets/images/ic-add-32px.svg").default}
                                alt="company-icon"/>}
                            <input
                                type="file"
                                id="fileUpload"
                                style={{display: "none"}}
                                multiple={true}
                                onChange={onChangeIconFile}
                            />
                        </FlexBox>
                    </label>
                    <FlexBox
                        marginLeft={"24px"}
                        flexDirection={"column"}>
                        <NewH2
                            color={Colors.GRAY_8}>
                            {companyDetailModel.companyNm}
                        </NewH2>
                        <FlexBox
                            marginTop={"10px"}
                            alignItems={"center"}>
                            <Flag
                                style={{
                                    borderRadius: "4px",
                                    clipPath: "border-box",
                                    marginRight: "12px"
                                }}
                                country={companyDetailModel.country}
                                size={32}/>
                            <NewH7
                                color={Colors.GRAY_6}>
                                {companyDetailModel.country}
                            </NewH7>
                            {companyDetailModel.businessTypeList.map((item, index) => {
                                return <FlexBox
                                    alignItems={"center"}
                                    flexDirection={"row"}>
                                    {((index < 1 && companyDetailModel.country.length > 0) || index > 0) && <VerticalDivider
                                        height={"12px"}
                                        width={"1px"}
                                        backgroundColor={Colors.GRAY_6}
                                        margin={"0 16px"}
                                    />}
                                    <NewH7
                                        color={Colors.GRAY_6}>
                                        {item.name}
                                    </NewH7>
                                </FlexBox>
                            })}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    width={"144px"}
                    height={"55px"}
                    borderRadius={"8px"}
                    background={"linear-gradient(135deg, #007D72 2.61%, #0ED9CD 100%);"}
                    alignItems={"center"}
                    justifyContent={"center"}>
                    <FlexBox
                        flexDirection={"column"}>
                        <Text
                            color={Colors.GRAY_4}
                            fontSize={"8px"}
                            fontWeight={400}
                            lineHeight={"12px"}>
                            Third Works
                        </Text>
                        <NewH4
                            color={Colors.WHITE}>
                            Verified
                        </NewH4>
                    </FlexBox>
                    <Image
                        width={"32px"}
                        height={"32px"}
                        marginLeft={"8px"}
                        src={require("assets/images/ic-verified-32px.svg").default}
                        alt="ic-verified"/>
                </FlexBox>
            </FlexBox>
            <NewH4
                marginTop={"32px"}
                color={Colors.GRAY_8}>
                Company Information
            </NewH4>
            <FlexBox
                marginTop={"24px"}>
                <TextField
                    width={"100%"}
                    enabledClearButton={true}
                    label={'Company Name'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.companyNm}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                companyNm: e.target.value
                            }
                        })
                    }}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                companyNm: ""
                            }
                        })
                    }}
                    type={"text"}
                />
                <TextField
                    marginLeft={"24px"}
                    width={"100%"}
                    label={`${t('number_of_employees')}`}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.employeeCount}
                    enabledClearButton={true}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                employeeCount: 0
                            }
                        })
                    }}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                employeeCount: Number(KeyboardUtils.clearNumber(e.target.value))
                            }
                        })
                    }}
                    type={"text"}
                />
            </FlexBox>
            <FlexBox
                alignItems={"flex-end"}
                marginTop={"24px"}>
                <SelectBox items={countries}
                           width={"100%"}
                           label={`${t('country')}`}
                           labelFontWight={500}
                           labelFontSize={"16px"}
                           value={companyDetailModel.country}
                           onChange={(e) => {
                               setCompanyDetailModel((prevState) => {
                                   return {
                                       ...prevState,
                                       country: e.target.value
                                   }
                               })
                           }}
                />
                <FlexBox
                    marginLeft={"24px"}
                    alignItems={"flex-end"}
                    height={"81px"}
                    width={"100%"}>
                    <TextField
                        width={"100%"}
                        label={`${t('sales')}`}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        value={companyDetailModel.annualRevenue}
                        enabledClearButton={true}
                        onChange={(e) => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    annualRevenue: e.target.value
                                }
                            })
                        }}
                        onClear={() => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    annualRevenue: ""
                                }
                            })
                        }}
                        type={"text"}
                    />
                    <SelectBox items={payUnits}
                               marginLeft={"8px"}
                               width={"100px"}
                               label={'Unit'}
                               labelVisible={false}
                               labelFontWight={500}
                               labelFontSize={"16px"}
                               value={payUnit}
                               onChange={(e) => {
                                   setPayUnit(e.target.value)
                               }}
                    />
                </FlexBox>
            </FlexBox>
            <FlexBox
                marginTop={"24px"}>
                <TextField
                    width={"100%"}
                    label={`${t('address')}`}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.address}
                    enabledClearButton={true}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                address: e.target.value
                            }
                        })
                    }}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                address: ""
                            }
                        })
                    }}
                    type={"text"}
                />
                <NAutoComplete
                    marginLeft={"24px"}
                    width={"100%"}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    label={`${t('main_category_product_label')}`}
                    items={adminCategories}
                    values={companyDetailModel.categoryList.map((item) => {
                        return {
                            code: item.categoryId,
                            name: item.categoryName
                        }
                    })}
                    onChangedValues={(values) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                categoryList: values.map((item) => {
                                    return {
                                        categoryId: item.code,
                                        categoryName: item.name
                                    }
                                })
                            }
                        })
                    }}
                />
            </FlexBox>
            <FlexBox
                marginTop={"24px"}
                alignItems={"flex-end"}>
                <NAutoComplete
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    label={`Business Type(${t('multiple_selection')})`}
                    items={BusinessTypes}
                    values={companyDetailModel.businessTypeList.map((item) => {
                        return {
                            code: item.code,
                            name: item.name
                        }
                    })}
                    onChangedValues={(values) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                businessTypeList: values.map((item) => {
                                    return {
                                        code: item.code,
                                        name: item.name
                                    }
                                })
                            }
                        })
                    }}
                />
                <TextField
                    marginLeft={"24px"}
                    width={"100%"}
                    label={`${t('year_of_establishment')}`}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.establishedYear}
                    enabledClearButton={true}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                establishedYear: KeyboardUtils.clearNumber(e.target.value)
                            }
                        })
                    }}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                establishedYear: ""
                            }
                        })
                    }}
                    type={"text"}
                />
                {/*<TextField*/}
                {/*    marginLeft={"24px"}*/}
                {/*    width={"100%"}*/}
                {/*    label={'Website'}*/}
                {/*    labelFontWight={500}*/}
                {/*    labelFontSize={"16px"}*/}
                {/*    enabledClearButton={true}*/}
                {/*    value={webSite}*/}
                {/*    onChange={(e) => {*/}
                {/*        setWebSite(e.target.value)*/}
                {/*    }}*/}
                {/*    onClear={() => {*/}
                {/*        setWebSite("")*/}
                {/*    }}*/}
                {/*    type={"text"}*/}
                {/*/>*/}
            </FlexBox>
            {/*<FlexBox*/}
            {/*    marginTop={"24px"}>*/}
            {/*    <TextField*/}
            {/*        width={"100%"}*/}
            {/*        label={'설립 연도'}*/}
            {/*        labelFontWight={500}*/}
            {/*        labelFontSize={"16px"}*/}
            {/*        value={companyDetailModel.establishedYear}*/}
            {/*        enabledClearButton={true}*/}
            {/*        onChange={(e) => {*/}
            {/*            setCompanyDetailModel((prevState) => {*/}
            {/*                return {*/}
            {/*                    ...prevState,*/}
            {/*                    establishedYear: KeyboardUtils.clearNumber(e.target.value)*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }}*/}
            {/*        onClear={() => {*/}
            {/*            setCompanyDetailModel((prevState) => {*/}
            {/*                return {*/}
            {/*                    ...prevState,*/}
            {/*                    establishedYear: ""*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }}*/}
            {/*        type={"text"}*/}
            {/*    />*/}
            {/*    <FlexBox*/}
            {/*        height={"10px"}*/}
            {/*        marginLeft={"24px"}*/}
            {/*        width={"100%"}/>*/}
            {/*</FlexBox>*/}
            <NewH4
                marginTop={"32px"}
                color={Colors.GRAY_8}>
                Company Overview
            </NewH4>
            <NTextArea
                placeholder={`${t('company_overview_hint')}`}
                value={companyDetailModel.companyOverview}
                multiline={true}
                maxRows={4}
                onChange={(e) => {
                    setCompanyDetailModel((prevState) => {
                        return {
                            ...prevState,
                            companyOverview: e.target.value
                        }
                    })
                }}
                sx={{
                    height: "128px",
                    marginTop: "24px",
                }}
            />
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                {t('approved_exporter')}
            </NewH4>
            <NewP1
                marginTop={"8px"}
                color={Colors.GRAY_7}>
                {`ⓘ : ${t('approved_exporter_description')}`}
            </NewP1>
            <NewP1
                color={Colors.GRAY_7}
                marginTop={"24px"}>
                {t('applicability')}
            </NewP1>
            <FlexBox
                marginTop={"8px"}
                height={"120px"}>
                <NewRadioItem
                    padding={"20px"}
                    title={`${t('approved_exporter_yes')}`}
                    description={`${t('approved_exporter_yes_description')}`}
                    checked={companyDetailModel.customAuthYn === 'Y'}
                    onClick={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                customAuthYn: 'Y'
                            }
                        })
                    }}
                    height={"100%"}/>
                <NewRadioItem
                    padding={"20px"}
                    marginLeft={"16px"}
                    title={`${t('approved_exporter_no')}`}
                    description={`${t('approved_exporter_no_description')}`}
                    checked={companyDetailModel.customAuthYn === 'N'}
                    onClick={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                customAuthYn: 'N'
                            }
                        })
                    }}
                    height={"100%"}/>
                <NewRadioItem
                    padding={"20px"}
                    marginLeft={"16px"}
                    title={`${t('no_relation')}`}
                    description={`${t('approved_exporter_na_description')}`}
                    checked={companyDetailModel.customAuthYn === 'NA'}
                    onClick={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                customAuthYn: 'NA'
                            }
                        })
                    }}
                    height={"100%"}/>
            </FlexBox>
            <NewP1
                color={Colors.GRAY_7}
                marginTop={"24px"}>
                {t('approved_exporter_list')}
            </NewP1>
            <FlexBox
                alignItems={"center"}
                marginTop={"12px"}>
                {companyDetailModel.customAuthNumList.map((item) => {
                    return <Chip
                        onDelete={() => {
                            handleExporterNumberDelete(item)
                        }}
                        sx={{
                            border: `1px solid ${Colors.GRAY_3}`,
                            marginRight: "16px",
                            borderRadius: "6px",
                            background: Colors.GRAY_1,
                            color: Colors.GRAY_7,
                            fontWeight: 500,
                            fontSize: "14px"
                        }}
                        label={`${item.customAuthNum}(${item.customAuthType === 'C' ? `${t('by_company')}` : `${t('by_product')}`})`}/>
                })}
                <FlexBox
                    cursor={"pointer"}
                    alignItems={"center"}
                    onClick={() => {
                        setOpenAddCustomAuthNumberDialog(true)
                    }}>
                    <Image
                        src={require("assets/images/ic-add-20px.svg").default}
                        alt="add-icon"/>
                    <NewP1
                        color={Colors.MAIN}
                        marginLeft={'8px'}>
                        {t('add')}
                    </NewP1>
                </FlexBox>
            </FlexBox>
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                Trade Capacity
            </NewH4>
            <FlexBox
                marginTop={"24px"}>
                <TextField
                    width={"100%"}
                    label={'Available Incoterms'}
                    hint={'e.g.) FOB, CIF, CFR'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.incoterms}
                    enabledClearButton={true}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                incoterms: e.target.value
                            }
                        })
                    }}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                incoterms: ""
                            }
                        })
                    }}
                    type={"text"}
                />
                <TextField
                    width={"100%"}
                    marginLeft={"24px"}
                    label={'Terms of payment'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    hint={'e.g.) L/C, T/T Payple'}
                    value={companyDetailModel.termsOfPayment}
                    enabledClearButton={true}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                termsOfPayment: e.target.value
                            }
                        })
                    }}
                    onClear={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                termsOfPayment: ""
                            }
                        })
                    }}
                    type={"text"}
                />
            </FlexBox>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    width={"100%"}
                    alignItems={"flex-end"}>
                    <TextField
                        width={"100%"}
                        enabledClearButton={true}
                        label={'Nearest Port'}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        value={companyDetailModel.nearestPort}
                        onChange={(e) => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    nearestPort: e.target.value
                                }
                            })
                        }}
                        onClear={() => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    nearestPort: ""
                                }
                            })
                        }}
                        type={"text"}
                    />
                    <SelectBox items={countries}
                               marginLeft={"16px"}
                               width={"200px"}
                               label={'Country'}
                               labelVisible={false}
                               labelFontWight={500}
                               labelFontSize={"16px"}
                               value={companyDetailModel.capacityCountry}
                               onChange={(e) => {
                                   setCompanyDetailModel((prevState) => {
                                       return {
                                           ...prevState,
                                           capacityCountry: e.target.value
                                       }
                                   })
                               }}
                    />
                </FlexBox>
                <NAutoComplete
                    marginLeft={"24px"}
                    label={`Main Markets(${t('multiple_selection')})`}
                    hint={`${t('search_and_select')}`}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    items={MainMarketTypes}
                    values={companyDetailModel.mainMarketList.map((item) => {
                        return {
                            code: item.continentCode,
                            name: item.continentName
                        }
                    })}
                    onChangedValues={(values) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                mainMarketList: values.map((item) => {
                                    return {
                                        continentCode: item.code,
                                        continentName: item.name
                                    }
                                })
                            }
                        })
                    }}
                />
            </FlexBox>
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                Export History
            </NewH4>
            <NewP2
                marginTop={"16px"}
                color={Colors.GRAY_7}>
                Input the trade ratio(%) for your top 5 exporting countries.
            </NewP2>
            <NewH5
                color={Colors.GRAY_8}
                marginTop={"24px"}>
                Do you have an experience for global trade?
            </NewH5>
            <FlexBox
                width={"40%"}
                marginTop={"16px"}
                height={"52px"}>
                <NewRadioItem
                    title={"Yes, we do."}
                    checked={companyDetailModel.exportExperience}
                    onClick={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                exportExperience: true
                            }
                        })
                    }}
                    height={"100%"}/>
                <NewRadioItem
                    marginLeft={"16px"}
                    title={"No, we don't"}
                    checked={!companyDetailModel.exportExperience}
                    onClick={() => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                exportExperience: false
                            }
                        })
                    }}
                    height={"100%"}/>
            </FlexBox>
            <NewH4
                color={totalRatio <= 100 ? Colors.MAIN : Colors.INDEX_005}
                marginTop={"24px"}>
                {`Total ${totalRatio}%`}
            </NewH4>
            <NewP2
                marginTop={"8px"}
                visible={totalRatio > 100}
                color={Colors.INDEX_005}>
                The sum of your 5 exporting countries cannot exceed 100%.
            </NewP2>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH6>
                        1st Country
                    </NewH6>
                    <FlexBox
                        marginTop={"8px"}>
                        <SelectBox
                            width={"200px"}
                            label={'Country'}
                            labelVisible={false}
                            items={countries}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 0) {
                                        return {
                                            countryCd: e.target.value,
                                            exportPercent: item.exportPercent
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            value={companyDetailModel.exportHistoryList[0].countryCd}/>
                        <NTextField
                            marginLeft={"16px"}
                            width={"100%"}
                            labelVisible={false}
                            value={companyDetailModel.exportHistoryList[0].exportPercent}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_8}>%</NewH6>}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 0) {
                                        return {
                                            countryCd: item.countryCd,
                                            exportPercent: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            type={"number"}
                        />
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginLeft={"24px"}
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH6>
                        2nd Country
                    </NewH6>
                    <FlexBox
                        marginTop={"8px"}>
                        <SelectBox
                            width={"200px"}
                            label={'Country'}
                            labelVisible={false}
                            items={countries}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 1) {
                                        return {
                                            countryCd: e.target.value,
                                            exportPercent: item.exportPercent
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            value={companyDetailModel.exportHistoryList[1].countryCd}/>
                        <NTextField
                            marginLeft={"16px"}
                            width={"100%"}
                            labelVisible={false}
                            value={companyDetailModel.exportHistoryList[1].exportPercent}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_8}>%</NewH6>}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 1) {
                                        return {
                                            countryCd: item.countryCd,
                                            exportPercent: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            type={"number"}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH6>
                        3rd Country
                    </NewH6>
                    <FlexBox
                        marginTop={"8px"}>
                        <SelectBox
                            width={"200px"}
                            label={'Country'}
                            labelVisible={false}
                            items={countries}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 2) {
                                        return {
                                            countryCd: e.target.value,
                                            exportPercent: item.exportPercent
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            value={companyDetailModel.exportHistoryList[2].countryCd}/>
                        <NTextField
                            marginLeft={"16px"}
                            width={"100%"}
                            labelVisible={false}
                            value={companyDetailModel.exportHistoryList[2].exportPercent}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_8}>%</NewH6>}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 2) {
                                        return {
                                            countryCd: item.countryCd,
                                            exportPercent: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            type={"number"}
                        />
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginLeft={"24px"}
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH6>
                        4th Country
                    </NewH6>
                    <FlexBox
                        marginTop={"8px"}>
                        <SelectBox
                            width={"200px"}
                            label={'Country'}
                            labelVisible={false}
                            items={countries}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 3) {
                                        return {
                                            countryCd: e.target.value,
                                            exportPercent: item.exportPercent
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            value={companyDetailModel.exportHistoryList[3].countryCd}/>
                        <NTextField
                            marginLeft={"16px"}
                            width={"100%"}
                            labelVisible={false}
                            value={companyDetailModel.exportHistoryList[3].exportPercent}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_8}>%</NewH6>}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 3) {
                                        return {
                                            countryCd: item.countryCd,
                                            exportPercent: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            type={"number"}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    width={"100%"}
                    flexDirection={"column"}>
                    <NewH6>
                        5th Country
                    </NewH6>
                    <FlexBox
                        marginTop={"8px"}>
                        <SelectBox
                            width={"200px"}
                            label={'Country'}
                            labelVisible={false}
                            items={countries}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 4) {
                                        return {
                                            countryCd: e.target.value,
                                            exportPercent: item.exportPercent
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            value={companyDetailModel.exportHistoryList[4].countryCd}/>
                        <NTextField
                            marginLeft={"16px"}
                            width={"100%"}
                            labelVisible={false}
                            value={companyDetailModel.exportHistoryList[4].exportPercent}
                            enabledEndAdornment={true}
                            endAdornment={<NewH6 color={Colors.GRAY_8}>%</NewH6>}
                            onChange={(e) => {
                                let newArray = companyDetailModel.exportHistoryList.map((item, index) => {
                                    if (index === 4) {
                                        return {
                                            countryCd: item.countryCd,
                                            exportPercent: KeyboardUtils.clearNumber(e.target.value)
                                        }
                                    } else {
                                        return item
                                    }
                                })
                                setCompanyDetailModel((prevState) => {
                                    return {
                                        ...prevState,
                                        exportHistoryList: newArray
                                    }
                                })
                            }}
                            type={"number"}
                        />
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginLeft={"24px"}
                    width={"100%"}
                    flexDirection={"column"}>
                </FlexBox>
            </FlexBox>
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                Production Capacity
            </NewH4>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    alignItems={"flex-end"}
                    width={"100%"}>
                    <TextField
                        width={"100%"}
                        label={'Factory Name'}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        value={companyDetailModel.productionFactoryNm}
                        enabledClearButton={true}
                        onChange={(e) => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    productionFactoryNm: e.target.value
                                }
                            })
                        }}
                        onClear={() => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    productionFactoryNm: ""
                                }
                            })
                        }}
                        type={"text"}
                    />
                    <SelectBox items={countries}
                               width={"200px"}
                               marginLeft={"16px"}
                               label={'Country'}
                               labelVisible={false}
                               labelFontWight={500}
                               labelFontSize={"16px"}
                               value={companyDetailModel.productionFactoryCountryCd}
                               onChange={(e) => {
                                   setCompanyDetailModel((prevState) => {
                                       return {
                                           ...prevState,
                                           productionFactoryCountryCd: e.target.value
                                       }
                                   })
                               }}
                    />
                </FlexBox>
                <NTextField
                    marginLeft={"24px"}
                    width={"100%"}
                    label={'Annual Output Capacity'}
                    labelFontWight={500}
                    labelFontSize={"16px"}
                    value={companyDetailModel.annualOutputCapacity}
                    enabledEndAdornment={true}
                    endAdornment={<NewH6 width={"80px"}>Metic ton</NewH6>}
                    onChange={(e) => {
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                annualOutputCapacity: e.target.value
                            }
                        })
                    }}
                    type={"text"}
                />
            </FlexBox>
            <FlexBox
                marginTop={"24px"}>
                <FlexBox
                    width={'100%'}
                    alignItems={"flex-end"}>
                    <TextField
                        width={"100%"}
                        label={'Production Capacity'}
                        labelFontWight={500}
                        labelFontSize={"16px"}
                        value={companyDetailModel.productionCapacity}
                        enabledClearButton={true}
                        onChange={(e) => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    productionCapacity: e.target.value
                                }
                            })
                        }}
                        onClear={() => {
                            setCompanyDetailModel((prevState) => {
                                return {
                                    ...prevState,
                                    productionCapacity: ""
                                }
                            })
                        }}
                        type={"text"}
                    />
                    <SelectBox items={ProductCapacityTypes}
                               width={"200px"}
                               marginLeft={"16px"}
                               label={'Unit'}
                               labelVisible={false}
                               labelFontWight={500}
                               labelFontSize={"16px"}
                               value={companyDetailModel.productionCapacityUnitCd}
                               onChange={(e) => {
                                   setCompanyDetailModel((prevState) => {
                                       return {
                                           ...prevState,
                                           productionCapacityUnitCd: e.target.value
                                       }
                                   })
                               }}
                    />
                </FlexBox>
                <SelectBox items={RnDCapacityTypes}
                           width={"100%"}
                           marginLeft={"24px"}
                           label={'R&D Capacity'}
                           labelFontWight={500}
                           labelFontSize={"16px"}
                           value={companyDetailModel.rdCapacity}
                           onChange={(e) => {
                               setCompanyDetailModel((prevState) => {
                                   return {
                                       ...prevState,
                                       rdCapacity: e.target.value
                                   }
                               })
                           }}/>
            </FlexBox>
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                Certificates
            </NewH4>
            <FlexBox
                marginTop={"16px"}
                alignItems={"flex-start"}>
                {companyDetailModel.certificatesList.map((item, index) => {
                    return <FlexBox
                        minWidth={"164px"}
                        maxWidth={"164px"}
                        paddingRight={"8px"}
                        paddingTop={"8px"}
                        flexWrap={"wrap"}
                        flexDirection={"column"}
                        marginRight={"12px"}>
                        <Image
                            width={"160px"}
                            height={"160px"}
                            borderRadius={"24px"}
                            border={`1px solid ${Colors.GRAY_2}`}
                            backgroundColor={Colors.GRAY_0}
                            justifyContent={"center"}
                            alignItems={"center"}
                            src={item.fileUrl}
                            alt={`certification-image-${index}`}/>
                        <NewH5
                            marginTop={"8px"}
                            maxWidth={"160px"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            flexWrap={"wrap"}>
                            {`${item.name}`}
                        </NewH5>
                        <Image
                            cursor={"pointer"}
                            position={"absolute"}
                            right={"0px"}
                            top={"0px"}
                            width={"24px"}
                            height={"24px"}
                            onClick={() => {
                                handleCertificationDelete(item)
                            }}
                            borderRadius={"100px"}
                            backgroundColor={Colors.GRAY_8}
                            src={require("assets/images/ic-close-24px.svg").default}
                            alt="remove-certification-icon"/>
                    </FlexBox>
                })}
                <FlexBox
                    marginTop={"8px"}
                    cursor={"pointer"}
                    onClick={() => {
                        setOpenSelectCertificationDialog(true)
                    }}
                    width={"160px"}
                    height={"160px"}
                    borderRadius={"24px"}
                    border={`1px solid ${Colors.GRAY_2}`}
                    backgroundColor={Colors.GRAY_0}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <Image
                        src={require("assets/images/ic-add-box-40px.svg").default}
                        alt="add-box-icon"/>
                </FlexBox>
            </FlexBox>
            <NewH4
                color={Colors.GRAY_8}
                marginTop={"32px"}>
                Company Show
            </NewH4>
            <NewP2
                marginTop={"16px"}
                color={Colors.GRAY_7}>
                {t('maximum_five_files_upload')}
            </NewP2>
            <FlexBox
                alignItems={"flex-start"}
                marginTop={"16px"}>
                {companyImageFiles.map((item, index) => {
                    return <FlexBox
                        minWidth={"164px"}
                        maxWidth={"164px"}
                        paddingRight={"8px"}
                        paddingTop={"8px"}
                        flexWrap={"wrap"}
                        flexDirection={"column"}
                        marginRight={"12px"}>
                        <Image
                            width={"160px"}
                            height={"160px"}
                            borderRadius={"24px"}
                            border={`1px solid ${Colors.GRAY_2}`}
                            backgroundColor={Colors.GRAY_0}
                            justifyContent={"center"}
                            alignItems={"center"}
                            src={URL.createObjectURL(item)}
                            alt={`company-image-${index}`}/>
                        <NewH5
                            marginTop={"8px"}
                            maxWidth={"160px"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            flexWrap={"wrap"}>
                            {`${item.name}`}
                        </NewH5>
                        <NewH5
                            maxWidth={"160px"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                            flexWrap={"wrap"}>
                            {`(${FileUtils.convertBytesToMegaBytes(item.size)}MB)`}
                        </NewH5>
                        <Image
                            cursor={"pointer"}
                            position={"absolute"}
                            right={"0px"}
                            top={"0px"}
                            width={"24px"}
                            height={"24px"}
                            onClick={() => {
                                handleCompanyImageDelete(item)
                            }}
                            borderRadius={"100px"}
                            backgroundColor={Colors.GRAY_8}
                            src={require("assets/images/ic-close-24px.svg").default}
                            alt="remove-file-icon"/>
                    </FlexBox>
                })}
                <label
                    htmlFor={"fileUpload"}>
                    <FlexBox
                        marginTop={"8px"}
                        width={"160px"}
                        height={"160px"}
                        borderRadius={"24px"}
                        cursor={"pointer"}
                        border={`1px solid ${Colors.GRAY_2}`}
                        backgroundColor={Colors.GRAY_0}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <Image
                            src={require("assets/images/ic-add-box-40px.svg").default}
                            alt="add-icon"/>
                        <input
                            type="file"
                            id="fileUpload"
                            style={{display: "none"}}
                            multiple={true}
                            onChange={onChangeFiles}
                        />
                    </FlexBox>
                </label>
            </FlexBox>
            <FlexBox
                alignSelf={"center"}
                width={"400px"}
                marginTop={"60px"}>
                <NewButton
                    onClick={() => {
                        handleSaveButtonClick(CompanyStatus.APPROVAL_PENDING)
                    }}>
                    {t('new_product_submit')}
                </NewButton>
                <NewButton
                    fontWeight={500}
                    border={`1px solid ${Colors.GRAY_4}`}
                    marginLeft={"24px"}
                    colorType={5}
                    onClick={() => {
                        handleSaveButtonClick(CompanyStatus.TEMP_DRAFT)
                    }}>
                    {t('temp_draft')}
                </NewButton>
            </FlexBox>
            <AddExporterNumberDialog dialogProps={{
                isOpen: isOpenAddCustomAuthNumberDialog,
                onClose: (positive, type, number) => {
                    if (positive) {
                        companyDetailModel.customAuthNumList.push({
                            customAuthType: type,
                            customAuthNum: number
                        })
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                customAuthNumList: companyDetailModel.customAuthNumList
                            }
                        })
                    }
                    setOpenAddCustomAuthNumberDialog(false)
                }
            }}/>
            <SelectCertificationDialog dialogProps={{
                items: certifications,
                isOpen: isOpenSelectCertificationDialog,
                onClose: (positive, selected) => {
                    if (positive && selected) {
                        let certificationList = companyDetailModel.certificatesList
                        certificationList.push(selected)
                        setCompanyDetailModel((prevState) => {
                            return {
                                ...prevState,
                                certificatesList: certificationList
                            }
                        })
                    }
                    setOpenSelectCertificationDialog(false)
                }
            }}/>
        </FlexBox>
    )
}
export default CompanyManageFragment