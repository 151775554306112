import {UsersAPI} from "../network/UsersAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {LocalStorageManager} from "../LocalStorageManager";
import {Dispatch} from "redux";
import {User} from "../model/User";
import {SolutionAPI} from "../network/SolutionAPI";
import {Logger} from "../log/Logger";
import {NavigateFunction} from "react-router-dom";
import {BusinessUserState} from "../model/BusinessUserState";

/**
 * @author jaeho.lee104 on 2023. 05. 08..
 */

enum UserActionType {
    LOGIN = "users/LOGIN",
    LOGOUT = "users/LOGOUT",
    DELETE = "users/DELETE",
    REFRESH_TOKEN = "users/REFRESH_TOKEN",
    UPDATE_USER = "users/UPDATE_USER",
    CHECK_BUSINESS_USER = "users/CHECK_BUSINESS_USER"
}

interface UserUpdateInfoAction {
    type: UserActionType.UPDATE_USER
    user: User | null
}

interface UserCheckBusiness {
    type: UserActionType.CHECK_BUSINESS_USER,
    businessUserState: BusinessUserState
}

interface UserRefreshTokenAction {
    type: UserActionType.REFRESH_TOKEN
    businessUserState: BusinessUserState
    signedIn: boolean
    user: User
    accessToken: string
}

interface UserLogoutAction {
    type: UserActionType.LOGOUT
    businessUserState: BusinessUserState
    signedIn: boolean
    user: User | null
    accessToken: string
}

interface UserDeleteAction {
    type: UserActionType.DELETE,
    businessUserState: BusinessUserState
    signedIn: boolean
    user: User | null
    accessToken: string
}

interface UserLoginAction {
    type: UserActionType.LOGIN
    businessUserState: BusinessUserState
    signedIn: boolean
    user: User
    accessToken: string
}

type UserActions = UserRefreshTokenAction
    | UserLogoutAction
    | UserLoginAction
    | UserUpdateInfoAction
    | UserDeleteAction
    | UserCheckBusiness

type UserState = {
    businessUserState: BusinessUserState,
    signedIn: boolean,
    user: User | null,
    accessToken: string,
}

const initialState: UserState = {
    businessUserState: LocalStorageManager.getBusinessUserState(),
    signedIn: (LocalStorageManager.getUserId()?.length || 0) > 0,
    user: User.getEmptyUser(),
    accessToken: LocalStorageManager.getAccessToken() || ""
};

export class Users {

    static isRefreshing = false
    static updateUser = (user: User | null): UserUpdateInfoAction => {
        return {
            type: UserActionType.UPDATE_USER,
            user: user
        }
    }

    static checkBusinessUser = () => {
        return async (dispatch: Dispatch) => {
            let businessUserState = await SolutionAPI.getBusinessUserState()
            dispatch({
                type: UserActionType.CHECK_BUSINESS_USER,
                businessUserState: businessUserState
            })
        };
    }
    static loginSuccess = (
        componentName: string,
        user: User,
        businessUserState: string,
        accessToken: string
    ) => {
        LocalStorageManager.saveUserId(user.userId)
        LocalStorageManager.setBusinessUserState(businessUserState)
        Logger.info(componentName, `login success. userId: ${user.userId}`)
        return {
            type: UserActionType.LOGIN,
            businessUserState: businessUserState,
            signedIn: true,
            user: user,
            accessToken: accessToken
        };
    }
    static logout = (): (dispatch: Dispatch) => Promise<void> => {
        return async (dispatch: Dispatch) => {
            UsersAPI.signOut()
                .then(response => {
                    let responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()
                        || responseCode.isNeedLogin()) {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
                        // noinspection JSIgnoredPromiseFromCall
                        Logger.setUserId(null)
                        dispatch({
                            type: UserActionType.LOGOUT,
                            signedIn: false,
                            user: null,
                            accessToken: ""
                        });
                    }
                })
        }
    };

    static forceLogout = () => {
        LocalStorageManager.removeUserId()
        LocalStorageManager.removeAccessToken()
        LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
        // noinspection JSIgnoredPromiseFromCall
        Logger.setUserId(null)
        return {
            type: UserActionType.LOGOUT,
            signedIn: false,
            user: null,
            accessToken: ""
        }
    };

    static deleteUser = () => {
        // noinspection JSIgnoredPromiseFromCall
        return async (dispatch: Dispatch) => {
            UsersAPI.deleteUser()
                .then(response => {
                    let responseCode = ResponseCodes.of(response.data.code)
                    if (responseCode.isSuccess()) {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
                        dispatch({
                            type: UserActionType.DELETE,
                            signedIn: false,
                            user: null,
                            accessToken: ""
                        })
                    } else {
                        alert("Failed to delete user")
                    }
                })
                .catch(e => {
                    alert("delete error")
                })
        }
    };

    static refreshToken = (navigate: NavigateFunction) => {
        if (Users.isRefreshing) {
            console.log("already refreshing!!!")
            return
        }
        console.log("refreshing start")
        return async (dispatch: Dispatch) => {
            Users.isRefreshing = true
            return UsersAPI.refreshToken()
                .then(async (response) => {
                    const responseCode = ResponseCodes.of(response.data.code);
                    Users.isRefreshing = false
                    if (responseCode.isSuccess()) {
                        const data = response.data.data
                        const accessToken = data?.accessToken
                        if (!accessToken) {
                            LocalStorageManager.removeUserId()
                            LocalStorageManager.removeAccessToken()
                            LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
                            dispatch({
                                type: UserActionType.REFRESH_TOKEN,
                                signedIn: false,
                                user: null,
                                accessToken: "",
                                businessUserState: BusinessUserState.INIT
                            })
                            navigate("/login")
                            return
                        }
                        LocalStorageManager.setAccessToken(accessToken)
                        let user = await UsersAPI.getUserOrNull()
                        if (!user) {
                            LocalStorageManager.removeUserId()
                            LocalStorageManager.removeAccessToken()
                            LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
                            dispatch({
                                type: UserActionType.REFRESH_TOKEN,
                                signedIn: false,
                                user: null,
                                accessToken: "",
                                businessUserState: BusinessUserState.INIT
                            })
                            navigate("/login")
                            return
                        }
                        LocalStorageManager.setAccessToken(accessToken)
                        LocalStorageManager.saveUserId(user.userId)
                        const businessUserState = await SolutionAPI.getBusinessUserState()
                        LocalStorageManager.setBusinessUserState(businessUserState)
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: true,
                            user: user,
                            accessToken: accessToken,
                            businessUserState: businessUserState
                        })
                        if (businessUserState !== BusinessUserState.ACQUIRED) {
                            navigate("/login")
                        }
                    } else {
                        LocalStorageManager.removeUserId()
                        LocalStorageManager.removeAccessToken()
                        LocalStorageManager.setBusinessUserState(BusinessUserState.INIT)
                        dispatch({
                            type: UserActionType.REFRESH_TOKEN,
                            signedIn: false,
                            user: null,
                            accessToken: "",
                            businessUserState: BusinessUserState.INIT
                        })
                        if (responseCode.isRefreshTokenExpired()) {
                            Logger.captureEvent({
                                level: "error",
                                message: `solution refresh token expired.`,
                            })
                        }
                        navigate("/login")
                    }
                })
                .catch((e) => {
                    Users.isRefreshing = false
                    dispatch({
                        type: UserActionType.REFRESH_TOKEN,
                        signedIn: false,
                        user: null,
                        accessToken: "",
                        businessUserState: BusinessUserState.INIT
                    })
                    navigate("/login")
                })
        };
    };

}

function usersReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case UserActionType.LOGIN:
            return {
                businessUserState: action.businessUserState,
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.LOGOUT:
            return {
                businessUserState: action.businessUserState,
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.REFRESH_TOKEN:
            return {
                businessUserState: action.businessUserState,
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.UPDATE_USER:
            return {
                ...state,
                user: action.user
            }
        case UserActionType.DELETE:
            return {
                businessUserState: action.businessUserState,
                signedIn: action.signedIn,
                user: action.user,
                accessToken: action.accessToken
            }
        case UserActionType.CHECK_BUSINESS_USER:
            return {
                ...state,
                businessUserState: action.businessUserState
            }
        default:
            return state;
    }
}

export default usersReducer;
