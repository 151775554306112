/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import {AxiosResponse} from "axios";
import {SignInRequestBody} from "../model/SignInRequestBody";
import {SignInResult} from "../model/SignInResult";
import {NetworkCommon} from "./NetworkCommon";
import {AdminCompanyModel} from "../model/AdminCompanyModel";
import {FileModel} from "../model/FileModel";
import {AdminOsmfgCompanyModel} from "../model/AdminOsmfgCompanyModel";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class AdminAPI {

    static signIn(signInRequestBody: SignInRequestBody): Promise<AxiosResponse<BaseResponse<SignInResult>>> {
        return instance.post("/admin/user/signIn", signInRequestBody);
    }

    static getCompanies(): Promise<AxiosResponse<BaseResponse<Array<AdminCompanyModel>>>> {
        return instance.get("/admin/companies");
    }

    static getRelationCompanies(companyName: string): Promise<AxiosResponse<BaseResponse<Array<AdminOsmfgCompanyModel>>>> {
        return instance.get("/admin/companies/osmfgco", {
            params: {
                companyNm: companyName
            }
        });
    }

    static verifyCompany(companyId: string, osmfgcoId: string | null, verified: boolean, reason: string | null): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/admin/companies/${companyId}`, {
            osmfgcoId: osmfgcoId,
            verified: verified,
            verifiedReason: reason
        });
    }

    static getFile(fileId: string): Promise<AxiosResponse<BaseResponse<FileModel>>> {
        return instance.get(`/common/company/${fileId}`, {
            responseType: "blob",
        });
    }

    static addCategory(categoryName: string, categoryNameEn: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.post(`/admin/category`, {
            categoryName: categoryName,
            categoryNameEn: categoryNameEn
        });
    }

}