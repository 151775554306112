/**
 * @author jaeho.lee104 on 2023. 11. 23..
 */
export class CodeModel {
    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }

    code: string
    name: string
}

export const BusinessTypes: Array<CodeModel> = [
    {
        code: "MNFT",
        name: "Manufacturer"
    },
    {
        code: "EXP",
        name: "Exporter"
    },
    {
        code: "IMP",
        name: "Importer"
    }
]

export const TermsOfPaymentTypes: Array<CodeModel> = [
    {
        code: 'LC',
        name: 'L/C'
    },
    {
        code: 'TT',
        name: "T/T Payple"
    }
]

export const MainMarketTypes: Array<CodeModel> = [
    {
        code: 'AF',
        name: 'Africa'
    },
    {
        code: 'AN',
        name: 'Antarctica'
    },
    {
        code: 'AS',
        name: 'Asia'
    },
    {
        code: 'OC',
        name: 'Oceania'
    },
    {
        code: 'EU',
        name: 'Europe'
    },
    {
        code: 'NA',
        name: 'North America'
    },
    {
        code: 'SA',
        name: 'South America'
    }
]

export const ProductCapacityTypes: Array<CodeModel> = [
    {
        code: 'D',
        name: 'per day'
    },
    {
        code: 'M',
        name: 'per month'
    },
    {
        code: 'Y',
        name: 'per year'
    }
]

export const RnDCapacityTypes: Array<CodeModel> = [
    {
        code: "ODM",
        name: "ODM"
    },
    {
        code: "OEM",
        name: "OEM"
    },
    {
        code: "JDM",
        name: "JDM"
    },
    {
        code: "CMO",
        name: "CMO"
    },
    {
        code: "CDMO",
        name: "CDMO"
    }
]

/**
 * - LCL acceptable
 * - dry (’20/’40)
 * - HC (’20/’40)
 * - HC/RF
 * - ISO tank
 * - others
 */
export const ProductLoadingCapacityUnit: Array<CodeModel> = [
    {
        code: "LCL",
        name: "LCL acceptable"
    },
    {
        code: "DRY",
        name: "dry ('20/'40)"
    },
    {
        code: "HC",
        name: "HC ('20/'40)"
    },
    {
        code: "HCRF",
        name: "HC/RF"
    },
    {
        code: "ISO",
        name: "ISO tank"
    },
    {
        code: "OTHERS",
        name: "Others"
    }
]

export const NewProductUnitTypes = {
    KG: {
        code: 'KG',
        name: 'kg'
    },
    METRIC_TON: {
        code: 'METRIC_TON',
        name: 'metric ton'
    },
    EA: {
        code: 'EA',
        name: 'ea'
    },
    PCS: {
        code: 'PCS',
        name: 'pcs'
    },
    LBS: {
        code: 'LBS',
        name: 'lbs'
    },
    M3: {
        code: 'M3',
        name: 'm3'
    },
    LITER: {
        code: 'LITER',
        name: 'liter'
    },
    ML: {
        code: 'ML',
        name: 'ml'
    }

}
export const ProductUnitTypes: Array<CodeModel> = [
    {//kg, metric ton, ea, pcs, lbs, ㎥, liter
        code: "KG",
        name: "kg"
    },
    {
        code: 'METRIC_TON',
        name: 'metric ton'
    },
    {
        code: 'EA',
        name: 'ea'
    },
    {
        code: 'PCS',
        name: 'pcs'
    },
    {
        code: 'LBS',
        name: 'lbs'
    },
    {
        code: 'M3',
        name: 'm3'
    },
    {
        code: 'LITER',
        name: 'liter'
    },
    {
        code: 'ML',
        name: 'ml'
    }
]
export const ProductGradeTypes: Array<CodeModel> = [
    {
        code: 'FOR_HUMAN',
        name: 'Food Grade for human'
    },
    {
        code: 'FOR_ALL',
        name: 'Food Grade for all'
    },
    {
        code: 'INDUSTRY',
        name: 'Industry Grade'
    },
    {
        code: 'OTHERS',
        name: 'Others'
    },
]
export const ProductBuyerTypes: Array<CodeModel> = [
    {
        code: 'BUYER_COST',
        name: 'At Buyer’s cost'
    },
    {
        code: 'SELLER_COST',
        name: 'At Seller’s cost'
    },
    {
        code: 'NEGOTIABLE',
        name: 'Negotiable'
    },
    {
        code: 'OTHERS',
        name: 'Other'
    },
]

export const CertificationTypes: Array<CodeModel> = [
    {
        code: "CERTIFICATION_COMMON",
        name: "Common"
    },
    {
        code: "CERTIFICATION_COMPANY",
        name: "For Company"
    },
    {
        code: "CERTIFICATION_ITEM",
        name: "For Product"
    }
]

/**
 * TEMP_DRAFT("0", "TEMP_DRAFT"),  //임시저장
 * APPROVAL_PENDING("1", "APPROVAL_PENDING"),  //승인요청
 * APPROVAL_APPROVED("2", "APPROVAL_APPROVED"),   //승인 완료
 * APPROVAL_REJECTED("3", "APPROVAL_REJECTED"),   //승인 반려
 * PUBLICATION_SUSPENDED("4", "PUBLICATION_SUSPENDED"), //게시중단
 * PRODUCT_DISPLAY("5", "PRODUCT_DISPLAY")     //상품전시
 */