/**
 * @author jaeho.lee104 on 2023. 11. 20..
 */

import React from "react";
import FlexBox from "../styledcomponents/FlexBox";
import {Colors} from "../styledcomponents/Styles";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import Image from "../styledcomponents/Image";
import NewP3 from "../styledcomponents/text/NewP3";
import NewP4 from "../styledcomponents/text/NewP4";
import NewP1 from "../styledcomponents/text/NewP1";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import {LanguageUtils} from "../utils/LanguageUtils";

const PolicyText = styled(NewP1)`
  color: ${Colors.GRAY_6};
  cursor: pointer;
`
const ContentText = styled(NewP4)`
  color: ${Colors.GRAY_6};
  margin-left: 6px;
`
const LabelText = styled(NewP3)`
  color: ${Colors.GRAY_6};
`

const MainFooter = () => {
    const navigate = useNavigate()
    const {t} = useTranslation();
    const {language} = useSelector((state: RootState) => state.configurationsReducer, shallowEqual);

    return <FlexBox backgroundColor={Colors.GRAY_0}
                    minWidth={"1680px"}
                    justifyContent={"center"}
                    height={"251px"}
                    paddingLeft={"120px"}
                    paddingRight={"120px"}
                    paddingTop={"56px"}
                    paddingBottom={"64px"}
                    borderTop={`1px solid ${Colors.GRAY_3}`}>
        <FlexBox
            flexDirection={"column"}
            width={"747px"}
            maxWidth={"747px"}>
            <Image
                alt={"footer-logo"}
                width={"194px"}
                style={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    navigate("/")
                }}
                src={require("assets/images/footer-logo.svg").default}/>
            <FlexBox
                flexWrap={"wrap"}
                marginTop={"40px"}
                alignItems={"center"}>
                <FlexBox
                    alignItems={LanguageUtils.isKorean(language) ? "center" : "flex-start"}
                    flexDirection={LanguageUtils.isKorean(language) ? "row" : "column"}>
                    <FlexBox
                        alignItems={"center"}>
                        <FlexBox
                            alignItems={"center"}>
                            <LabelText>
                                {t('footer_company_name')}
                            </LabelText>
                            <ContentText>
                                {t('third_works_company')}
                            </ContentText>
                        </FlexBox>
                        <VerticalDivider backgroundColor={Colors.GRAY_6}
                                         height={"12px"}
                                         marginLeft={"16px"}
                                         marginRight={"16px"}/>
                        <FlexBox
                            alignItems={"center"}>
                            <LabelText>
                                {t('representative')}
                            </LabelText>
                            <ContentText>
                                {t('kim_minji')}
                            </ContentText>
                        </FlexBox>
                        <VerticalDivider backgroundColor={Colors.GRAY_6}
                                         height={"12px"}
                                         marginLeft={"16px"}
                                         marginRight={"16px"}/>
                        <FlexBox
                            alignItems={"center"}>
                            <LabelText>
                                {t('business_registration_number')}
                            </LabelText>
                            <ContentText>
                                784-87-02728
                            </ContentText>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox
                        alignItems={"center"}>
                        <VerticalDivider backgroundColor={Colors.GRAY_6}
                                         visible={LanguageUtils.isKorean(language)}
                                         height={"12px"}
                                         marginLeft={"16px"}
                                         marginRight={"16px"}/>
                        <FlexBox
                            alignItems={"center"}
                            marginTop={LanguageUtils.isKorean(language) ? "0px" : "8px"}>
                            <LabelText>
                                {t('e_commerce_registration_number_label')}
                            </LabelText>
                            <ContentText>
                                {t('e_commerce_registration_number')}
                            </ContentText>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                flexWrap={"wrap"}
                marginTop={"8px"}
                alignItems={"center"}>
                <FlexBox
                    alignItems={"center"}>
                    <LabelText>
                        {t('phone_label')}
                    </LabelText>
                    <ContentText>
                        010-2476-9700
                    </ContentText>
                </FlexBox>
                <VerticalDivider backgroundColor={Colors.GRAY_6}
                                 height={"12px"}
                                 marginLeft={"16px"}
                                 marginRight={"16px"}/>
                <FlexBox
                    alignItems={"center"}>
                    <LabelText>
                        {t('representative_email')}
                    </LabelText>
                    <ContentText>
                        business@thirdworks.co.kr
                    </ContentText>
                </FlexBox>
                <VerticalDivider backgroundColor={Colors.GRAY_6}
                                 height={"12px"}
                                 marginLeft={"16px"}
                                 marginRight={"16px"}/>
                <FlexBox
                    alignItems={"center"}>
                    <LabelText>
                        {t('cpo')}
                    </LabelText>
                    <ContentText>
                        {t('kim_minji')}
                    </ContentText>
                </FlexBox>
            </FlexBox>
            <FlexBox
                marginTop={"8px"}
                alignItems={"center"}>
                <LabelText>
                    {t('address_label')}
                </LabelText>
                <ContentText>
                    {t('thirdworks_address')}
                </ContentText>
            </FlexBox>
        </FlexBox>
        <FlexBox
            width={"500px"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
            flexDirection={"column"}>
            <FlexBox
                alignItems={LanguageUtils.isKorean(language) ? "center" : "flex-start"}
                flexDirection={LanguageUtils.isKorean(language) ? "row" : "column"}>
                <FlexBox
                    alignItems={"center"}>
                    <PolicyText
                        marginLeft={"16px"}
                        onClick={() => {
                            window.open("/terms-of-service")
                        }}>
                        {t('terms_of_service')}
                    </PolicyText>
                    <PolicyText
                        marginLeft={"24px"}
                        onClick={() => {
                            window.open("/privacy-policy")
                        }}>
                        {t('privacy_policy')}
                    </PolicyText>
                </FlexBox>
                <FlexBox
                    alignItems={"center"}
                    marginTop={LanguageUtils.isKorean(language) ? "0px" : "8px"}>
                    <PolicyText
                        marginLeft={LanguageUtils.isKorean(language) ? "24px" : "16px"}
                        onClick={() => {
                            window.open("/copyright-policy")
                        }}>
                        {t('copyright_policy')}
                    </PolicyText>
                    <PolicyText
                        marginLeft={"24px"}
                        onClick={() => {
                            window.open("/email-policy")
                        }}>
                        {t('deny_unauthorized_collection_of_email_addresses')}
                    </PolicyText>
                </FlexBox>

            </FlexBox>
            <NewP4
                color={Colors.GRAY_6}>
                COPYRIGHT 2023 ⓒ THIRDWORKS ALL RIGHTS RESERVED
            </NewP4>
        </FlexBox>
    </FlexBox>
}
export default MainFooter;