export class Sizes {
    static CONTENT_WIDTH = "1680px";
    static FHD_WIDTH = "1920px";
    static FOOTER_GAP = "120px"
    static MEDIA_QUERY_CONTENT_WIDTH = `(max-width: ${Sizes.CONTENT_WIDTH})`
    static MEDIA_QUERY_1200 = `(max-width: 1200px)`
    static MEDIA_QUERY_TABLET = `(max-width: 1024px)`
    static MEDIA_QUERY_MOBILE = `(max-width: 767px)`
    static MEDIA_QUERY_SMALL = `(max-width: 384px)`
}

export class Fonts {
    static P = "1rem"
    static H6 = "1rem"
    static H5 = "1.25rem"
    static H4 = "calc(1.275rem + 0.3vw)"
    static H3 = "calc(1.3rem + 0.6vw)"
    static H2 = "calc(1.325rem + 0.9vw)"
    static H1 = "calc(1.375rem + 1.5vw)"

    static WEIGHT_300 = 300;
    static WEIGHT_400 = "normal";
    static WEIGHT_500 = 500;
    static WEIGHT_700 = 700;
}

export class Colors {

    //raws
    static TRANSPARENT = "#ffffff00";
    static PRIMARY_BLUE = "#3B6BB1";
    static BLACK = "#000000";
    static WHITE = "#FFFFFF";
    static RED = "#E65717";
    static BLUE_100 = "#E9F0FE";
    static GRAY_300 = "#E6E8EA";
    static GRAY_350 = "#DBDDE1";
    static GRAY_400 = "#999999";
    static GRAY_800 = "#4E5968";
    static GRAY_900 = "#2F2F2F";

    //semantic
    static SECONDARY_PRESSED = "#E1EFFF";
    static SECONDARY_HOVER = "#F0F7FF";
    static DESTRUCTIVE_PRIMARY = "#E65717";
    static DESTRUCTIVE_HOVER = "#D72A34";
    static DESTRUCTIVE_PRESSED = "#CA222D";
    static DESTRUCTIVE_DISABLED = "#F09A9D";
    static CTA_DISABLED = "#80BEFE";
    static CTA_HOVER = "#0066DF";
    static CTA_PRESSED = "#0058C0";
    static MAIN = "#017DFD";
    static MAIN_LIGHT = "#279EFF";
    static INPUT_ERROR = Colors.RED;
    static SECONDARY_01 = '#00D9D9';
    static SECONDARY_02 = '#FF48A0';
    static SECONDARY_09 = '#FFBC42';


    //--------------------------------------------------------------------------------------------------디자인 개편시 추가 (장한별님)

    //new raws
    static PURPLE_10 = "#E7E8FD";
    static PURPLE_20 = "#D7DAF8";
    static PURPLE_30 = "#0B18E6";

    static BLUE_10 = "#E6F3FD";
    static BLUE_20 = "#67B1FE";
    static BLUE_30 = "#017DFD";

    static GREEN_10 = "#E6F8F9";
    static GREEN_20 = "#C8EDF0";
    static GREEN_30 = "#87D4D9";
    static GREEN_40 = "#00B3BE";

    static GREY_10 = "#FAFBFC";
    static GREY_20 = "#F7F9FA";
    static GREY_30 = "#EEEEEE";
    static GREY_40 = "#DBDDE1";
    static GREY_50 = "#B3B3B3";
    static GREY_60 = "#7F7F7F";
    static GREY_70 = "#4E4E4E";

    //new semantic
    static KEY = Colors.BLUE_30
    static KEY2 = Colors.BLUE_20
    static PREMIUM = Colors.PURPLE_30
    static STANDARD = Colors.GREEN_40
    static STANDARD2 = Colors.GREEN_30
    static EXTRA1 = "#E65717"
    static EXTRA2 = "#1F4866"

    static BG_GREY = Colors.GREY_20
    static BG1_B = Colors.BLUE_10
    static BG1_G = Colors.GREEN_10
    static BG1_S = Colors.GREEN_20
    static BG1_P = Colors.PURPLE_10
    static BG2_P = Colors.PURPLE_20
    static BG_EXTRA1 = "#FDEFE8"
    static BG_EXTRA2 = "#E9EDF0"
    static BG_PAGE = Colors.GREY_10
    static GREY_TAB = Colors.GREY_30
    static GREY_BORDER = Colors.GREY_40

    static BODY_TXT = Colors.GREY_70
    static SUB_TXT = Colors.GREY_60
    static SUB_TXT2 = Colors.GREY_50

    static GRADIENT_BUTTON_BACKGROUND = `linear-gradient(270deg, ${Colors.BLUE_30} 0%, ${Colors.PURPLE_30} 96.94%)`
    static GRADIENT_TAG_BACKGROUND = `linear-gradient(90deg, ${Colors.PURPLE_30} 53.37%, ${Colors.BLUE_30} 100%)`
    static GRADIENT_BUTTON2_BACKGROUND = `linear-gradient(90deg, ${Colors.BLUE_30} 0%, ${Colors.GREEN_40} 100%)`

    static COLOR_SET = [
        Colors.PREMIUM,
        Colors.KEY,
        Colors.KEY2,
        Colors.STANDARD,
        Colors.STANDARD2
    ]
    static SUPPLY_ANALYSIS_COLOR_SET = [
        Colors.PREMIUM,
        Colors.KEY,
        Colors.STANDARD,
        Colors.EXTRA1,
        Colors.EXTRA2
    ]
    static DEFAULT_BOX_SHADOW = "rgba(0, 0, 0, 0.25) 0px 1px 2px"


    // ---------------------------- 권진석님 팔레트 추가
    static GRAY_0 = "#F8F9FA"
    static GRAY_1 = "#F1F3F4"
    static GRAY_2 = "#E9ECEE"
    static GRAY_3 = "#DEE2E5"
    static GRAY_4 = "#CDD3D8"
    static GRAY_5 = "#ACB4BB"
    static GRAY_6 = "#868D94"
    static GRAY_7 = "#495056"
    static GRAY_8 = "#343A3E"
    static GRAY_9 = "#212528"
    static INDEX_005 = "#E65717"
}

export class Dimensions {
    static CARD_RADIUS = "24px"
    static RADIUS_MEDIUM = "12px"
}