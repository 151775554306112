import FlexBox from "../styledcomponents/FlexBox";
import Image from "../styledcomponents/Image";
import React, {useEffect, useState} from "react";
import NewH6 from "../styledcomponents/text/NewH6";
import {Colors} from "../styledcomponents/Styles";
import NewH1 from "../styledcomponents/text/NewH1";
import NewH2 from "../styledcomponents/text/NewH2";
import NewH5 from "../styledcomponents/text/NewH5";
import NewH3 from "../styledcomponents/text/NewH3";
import NewH4 from "../styledcomponents/text/NewH4";
import {Checkbox, DatePicker, Popover, TimeRangePickerProps} from "antd";
import dayjs, {Dayjs} from "dayjs";
import NewButton from "../styledcomponents/NewButton";
import Input from "../styledcomponents/Input";
import NewP2 from "../styledcomponents/text/NewP2";
import {ResponseCodes} from "../network/ResponseCodes";
import {SolutionAPI} from "../network/SolutionAPI";
import {ProductListItem} from "../model/ProductListItem";
import {NavigationMenu} from "../model/NavigationMenu";
import {useNavigate} from "react-router-dom";
import {ProductStatus} from "../model/ProductStatus";
import {TextUtils} from "../utils/TextUtils";
import {ColorUtils} from "../utils/ColorUtils";
import {useTranslation} from "react-i18next";

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
    {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
    {label: 'Last 1 Year', value: [dayjs().add(-365, 'd'), dayjs()]},
];
const productDetailWidth = "482px"
const productNumberWidth = "96px"
const productContainerImageWidth = "80px"
const productImageWidth = "60px"
const productNameWidth = "402px"
const productPriceWidth = "108px"
const productQuantityWidth = "120px"
const productStatusWidth = "140px"
const productDateWidth = "120px"
const productMoreButtonWidth = "71px"

/**
 * @author jaeho.lee104 on 2023. 11. 30..
 */
const ProductManageFragment = () => {

    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(() => {
        const currentDate = new Date()
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return currentDate
    });
    const [endDate, setEndDate] = useState(new Date());
    const [searchQuery, setSearchQuery] = useState("")

    const [originProductItems, setOriginProductItems] = useState<Array<ProductListItem>>([])
    const [productItems, setProductItems] = useState<Array<ProductListItem>>([])
    const [refresh, setRefresh] = useState(0)
    const [statusFilters, setStatusFilters] = useState<Array<string>>([])
    const {t} = useTranslation()

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            setStartDate(new Date(dateStrings[0]))
            setEndDate(new Date(dateStrings[1]))
        }
    };

    useEffect(() => {
        if (statusFilters.length === 0) {
            setProductItems(originProductItems)
        } else {
            setProductItems(originProductItems.filter((item) => statusFilters.includes(item.itemStatus)))
        }
    }, [statusFilters, originProductItems])

    useEffect(() => {
        SolutionAPI.getProducts()
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (!responseCode.isSuccess()) {
                    return
                }
                const items = response.data.data || []
                setOriginProductItems(items)

            })
    }, [refresh])

    function handleClickProductListItem(item: ProductListItem) {
        navigate(`?page=${NavigationMenu.PRODUCT_ADD}&itemId=${item.itemId}`)
    }

    function handleDeleteItemOnClick(item: ProductListItem) {
        SolutionAPI.deleteProductItem(item.itemId)
            .then((res) => {
                let responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    setRefresh(refresh + 1)
                    alert(t('completed'))
                } else {
                    alert(`${t('fail')}: ${JSON.stringify(responseCode)}`)
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    function handleUpdateProductStatus(item: ProductListItem, toItemStatus: string) {
        const currentItemStatus = item.itemStatus
        if (toItemStatus === ProductStatus.PRODUCT_DISPLAY) {
            if (currentItemStatus !== ProductStatus.PUBLICATION_SUSPENDED && currentItemStatus !== ProductStatus.APPROVAL_APPROVED) {
                alert(t('display_after_approval'))
                return
            }
        } else if (toItemStatus === ProductStatus.PUBLICATION_SUSPENDED) {
            if (currentItemStatus !== ProductStatus.PRODUCT_DISPLAY) {
                alert(t('not_displaying_product'))
                return;
            }
        }
        SolutionAPI.updateProductStatus(item.itemId, toItemStatus)
            .then((res) => {
                let responseCode = ResponseCodes.of(res.data.code)
                if (responseCode.isSuccess()) {
                    setRefresh(refresh + 1)
                    alert(t('completed'))
                } else {
                    alert(`${t('fail')}: ${JSON.stringify(responseCode)}`)
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    return <FlexBox
        width={"100%"}
        flexDirection={"column"}
        margin={"0 auto"}>
        <FlexBox
            justifyContent={"space-between"}>
            <FlexBox
                borderRadius={"24px"}
                border={`1px solid ${Colors.GRAY_2}`}
                background={Colors.WHITE}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                padding={"36px 40px"}
                width={"288px"}
                justifyContent={"space-between"}
                height={"152px"}>
                <Image
                    width={"56px"}
                    height={"56px"}
                    src={require("assets/images/ic-notification-56px.svg").default}
                    alt="ic-requested"/>
                <FlexBox
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}>
                    <NewH6
                        color={Colors.GRAY_6}>
                        {t('request_approval')}
                    </NewH6>
                    <FlexBox
                        alignItems={"center"}>
                        <NewH1
                            color={Colors.GRAY_8}>
                            {productItems.filter((item) => item.itemStatus === ProductStatus.APPROVAL_PENDING).length}
                        </NewH1>
                        <NewH2
                            marginLeft={"4px"}
                            marginTop={"2px"}
                            color={Colors.GRAY_8}>
                            {t('item_case_unit')}
                        </NewH2>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                borderRadius={"24px"}
                border={`1px solid ${Colors.GRAY_2}`}
                background={Colors.WHITE}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                padding={"36px 40px"}
                width={"288px"}
                justifyContent={"space-between"}
                height={"152px"}>
                <Image
                    width={"56px"}
                    height={"56px"}
                    src={require("assets/images/ic-approved-56px.svg").default}
                    alt="ic-apporved"/>
                <FlexBox
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}>
                    <NewH6
                        color={Colors.GRAY_6}>
                        {t('completed_approval')}
                    </NewH6>
                    <FlexBox
                        alignItems={"center"}>
                        <NewH1
                            color={Colors.GRAY_8}>
                            {productItems.filter((item) => item.itemStatus === ProductStatus.APPROVAL_APPROVED).length}
                        </NewH1>
                        <NewH2
                            marginLeft={"4px"}
                            marginTop={"2px"}
                            color={Colors.GRAY_8}>
                            {t('item_case_unit')}
                        </NewH2>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                borderRadius={"24px"}
                border={`1px solid ${Colors.GRAY_2}`}
                background={Colors.WHITE}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                padding={"36px 40px"}
                width={"288px"}
                justifyContent={"space-between"}
                height={"152px"}>
                <Image
                    width={"56px"}
                    height={"56px"}
                    src={require("assets/images/ic-rejected-56px.svg").default}
                    alt="ic-rejected"/>
                <FlexBox
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    alignItems={"flex-end"}>
                    <NewH6
                        color={Colors.GRAY_6}>
                        {t('reject_approval')}
                    </NewH6>
                    <FlexBox
                        alignItems={"center"}>
                        <NewH1
                            color={Colors.GRAY_8}>
                            {productItems.filter((item) => item.itemStatus === ProductStatus.APPROVAL_REJECTED).length}
                        </NewH1>
                        <NewH2
                            marginLeft={"4px"}
                            marginTop={"2px"}
                            color={Colors.GRAY_8}>
                            {t('item_case_unit')}
                        </NewH2>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                borderRadius={"24px"}
                border={`1px solid ${Colors.GRAY_2}`}
                background={Colors.WHITE}
                boxShadow={'0px 4px 8px 0px rgba(0, 0, 0, 0.08);'}
                padding={"24px 40px"}
                width={"288px"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"152px"}>
                <FlexBox
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        alignItems={"center"}>
                        <FlexBox
                            width={"10px"}
                            height={"10px"}
                            borderRadius={"100px"}
                            background={Colors.MAIN}
                        />
                        <NewH5
                            marginLeft={"8px"}
                            color={Colors.GRAY_8}>
                            {t('product_display')}
                        </NewH5>
                    </FlexBox>
                    <NewH3
                        color={Colors.GRAY_7}>
                        {productItems.filter((item) => item.itemStatus === ProductStatus.PRODUCT_DISPLAY).length}
                    </NewH3>
                </FlexBox>
                <FlexBox
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        alignItems={"center"}>
                        <FlexBox
                            width={"10px"}
                            height={"10px"}
                            borderRadius={"100px"}
                            background={Colors.GRAY_4}
                        />
                        <NewH5
                            marginLeft={"8px"}
                            color={Colors.GRAY_8}>
                            {t('stop_product_display')}
                        </NewH5>
                    </FlexBox>
                    <NewH3
                        color={Colors.GRAY_7}>
                        {productItems.filter((item) => item.itemStatus === ProductStatus.PUBLICATION_SUSPENDED).length}
                    </NewH3>
                </FlexBox>
                <FlexBox
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <FlexBox
                        alignItems={"center"}>
                        <FlexBox
                            width={"10px"}
                            height={"10px"}
                            borderRadius={"100px"}
                            border={`1px solid ${Colors.GRAY_4}`}
                        />
                        <NewH5
                            marginLeft={"8px"}
                            color={Colors.GRAY_8}>
                            {t('temp_draft')}
                        </NewH5>
                    </FlexBox>
                    <NewH3
                        color={Colors.GRAY_7}>
                        {productItems.filter((item) => item.itemStatus === ProductStatus.TEMP_DRAFT).length}
                    </NewH3>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        <FlexBox
            marginTop={"40px"}
            minHeight={`1142px`}
            width={"100%"}
            flexDirection={"column"}
            padding={"32px 0px"}
            borderRadius={"24px"}
            background={Colors.WHITE}
            border={`1px solid ${Colors.GRAY_2}`}
            boxShadow={`0px 2px 40px 0px rgba(0, 0, 0, 0.05);`}>
            <FlexBox
                padding={"0px 32px"}
                flexDirection={"column"}>
                <NewH3
                    color={Colors.GRAY_8}>
                    {t('product_list')}
                </NewH3>
                <FlexBox
                    alignItems={"center"}
                    height={"44px"}
                    marginTop={"24px"}>
                    <NewH4
                        color={Colors.GRAY_7}>
                        {t('product_sell_state')}
                    </NewH4>
                    <FlexBox
                        position={"absolute"}
                        left={"120px"}
                        alignItems={"center"}>
                        <Checkbox
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.PRODUCT_DISPLAY]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.PRODUCT_DISPLAY))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('product_display')}
                        </NewH5>
                        <Checkbox
                            style={{
                                marginLeft: "24px"
                            }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.PUBLICATION_SUSPENDED]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.PUBLICATION_SUSPENDED))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('stop_product_display')}
                        </NewH5>
                        <Checkbox
                            style={{
                                marginLeft: "24px"
                            }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.APPROVAL_PENDING]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.APPROVAL_PENDING))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('request_approval')}
                        </NewH5>
                        <Checkbox
                            style={{
                                marginLeft: "24px"
                            }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.APPROVAL_APPROVED]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.APPROVAL_APPROVED))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('completed_approval')}
                        </NewH5>
                        <Checkbox
                            style={{
                                marginLeft: "24px"
                            }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.APPROVAL_REJECTED]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.APPROVAL_REJECTED))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('reject_approval')}
                        </NewH5>
                        <Checkbox
                            style={{
                                marginLeft: "24px"
                            }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setStatusFilters(statusFilters.concat([ProductStatus.TEMP_DRAFT]))
                                } else {
                                    setStatusFilters(statusFilters.filter((status) => status !== ProductStatus.TEMP_DRAFT))
                                }
                            }}
                        />
                        <NewH5
                            color={Colors.GRAY_8}
                            marginLeft={"8px"}>
                            {t('temp_draft')}
                        </NewH5>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginTop={"22px"}
                    height={"44px"}
                    alignItems={"center"}>
                    <NewH4
                        color={Colors.GRAY_7}>
                        {t('product_sell_period')}
                    </NewH4>
                    <FlexBox
                        position={"absolute"}
                        left={"120px"}>
                        <RangePicker
                            separator={"~"}
                            defaultValue={[dayjs(startDate), dayjs(endDate)]}
                            presets={rangePresets}
                            style={{
                                textAlign: "center",
                                width: "335px",
                                height: "44px"
                            }}
                            format={dateFormat}
                            onChange={onRangeChange}
                            size={"large"}/>
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    height={"44px"}
                    marginTop={"22px"}
                    alignItems={"center"}>
                    <NewH4
                        color={Colors.GRAY_7}>
                        {t('product_keyword')}
                    </NewH4>
                    <FlexBox
                        position={"absolute"}
                        alignItems={"center"}
                        left={"120px"}>
                        <Input
                            height={"44px"}
                            width={"560px"}
                            borderRadius={"8px"}
                            border={`1px solid ${Colors.GRAY_4}`}
                            hintFontWeight={500}
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value)
                            }}
                            fontSize={"14px"}
                            hintColor={Colors.GRAY_5}
                            placeholder={`${t('product_search_hint')}`}
                        />
                        <NewButton
                            borderRadius={"8px"}
                            border={`1px solid ${Colors.GRAY_4}`}
                            backgroundColor={Colors.WHITE}
                            fontWeight={500}
                            color={Colors.GRAY_7}
                            marginLeft={"16px"}
                            width={"80px"}>
                            {t('search')}
                        </NewButton>
                        <FlexBox
                            alignItems={"center"}
                            marginLeft={"16px"}
                            onClick={() => {
                                setSearchQuery("")
                            }}
                            cursor={"pointer"}>
                            <Image
                                width={"24px"}
                                height={"24px"}
                                src={require("assets/images/ic-refresh-24px.svg").default}
                                alt="ic-refresh"/>
                            <NewH5
                                marginLeft={"8px"}
                                color={Colors.MAIN}>
                                {t('init_search')}
                            </NewH5>
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                borderTop={`1px solid ${Colors.GRAY_3}`}
                borderBottom={`1px solid ${Colors.GRAY_3}`}
                padding={"0px 32px"}
                alignItems={"center"}
                marginTop={"24px"}
                height={"48px"}>
                <NewP2
                    width={productNumberWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    No.
                </NewP2>
                <NewP2
                    width={productDetailWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    Product Details
                </NewP2>
                <NewP2
                    width={productPriceWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    Price
                </NewP2>
                <NewP2
                    width={productQuantityWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    Quantity
                </NewP2>
                <NewP2
                    width={productStatusWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    Status
                </NewP2>
                <NewP2
                    width={productDateWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}>
                    Date
                </NewP2>
                <NewP2
                    width={productMoreButtonWidth}
                    paddingLeft={"16px"}
                    color={Colors.GRAY_6}/>
            </FlexBox>
            {productItems.map((item, index) => {
                return <FlexBox
                    borderRadius={"8px"}
                    marginTop={"8px"}
                    marginLeft={"32px"}
                    alignItems={"center"}
                    marginRight={"32px"}
                    height={"64px"}
                    backgroundColor={index % 2 === 0 ? Colors.GRAY_0 : Colors.WHITE}>
                    <NewP2
                        cursor={"pointer"}
                        onClick={() => {
                            handleClickProductListItem(item)
                        }}
                        width={productNumberWidth}
                        paddingLeft={"16px"}
                        color={Colors.GRAY_7}>
                        {index + 1}
                    </NewP2>
                    <FlexBox
                        border={`1px solid ${Colors.GRAY_2}`}
                        borderRadius={"8px"}
                        cursor={"pointer"}
                        justifyContent={"center"}
                        onClick={() => {
                            handleClickProductListItem(item)
                        }}
                        alignItems={"center"}
                        width={productContainerImageWidth}>
                        <Image
                            width={productImageWidth}
                            height={productImageWidth}
                            src={item.itemImage?.url || require("assets/images/image-place-holder-48.png")}
                            alt={`product-image-${index}`}/>
                    </FlexBox>
                    <NewP2
                        cursor={"pointer"}
                        onClick={() => {
                            handleClickProductListItem(item)
                        }}
                        width={productNameWidth}
                        padding={"0px 16px"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        color={Colors.GRAY_7}>
                        {item.itemName}
                    </NewP2>
                    <NewP2
                        width={productPriceWidth}
                        paddingLeft={"16px"}
                        color={Colors.GRAY_7}>
                        {item.itemPrice} {TextUtils.packagingUnitCodeToUiText(item.itemPriceUnitCode)}
                    </NewP2>
                    <NewP2
                        width={productQuantityWidth}
                        paddingLeft={"16px"}
                        color={Colors.GRAY_7}>
                        {item.moq} {TextUtils.packagingUnitCodeToUiText(item.moqUnitCode)}
                    </NewP2>
                    <FlexBox
                        paddingLeft={"16px"}
                        alignItems={"center"}
                        width={productStatusWidth}>
                        <FlexBox
                            width={"10px"}
                            height={"10px"}
                            borderRadius={"100px"}
                            background={ColorUtils.getColorByProductStatus(item.itemStatus)}
                        />
                        <NewP2
                            marginLeft={"8px"}
                            color={Colors.GRAY_7}>
                            {t(TextUtils.itemStatusToUiText(item.itemStatus))}
                        </NewP2>
                    </FlexBox>
                    <NewP2
                        width={productDateWidth}
                        paddingLeft={"16px"}
                        color={Colors.GRAY_7}>
                        {item.createDate}
                    </NewP2>
                    <FlexBox
                        cursor={"pointer"}
                        paddingLeft={"16px"}
                        width={productMoreButtonWidth}>
                        <Popover
                            trigger={"click"}
                            placement={"left"}
                            content={<FlexBox
                                padding={"10px"}
                                minWidth={"80px"}
                                alignItems={"center"}
                                flexDirection={"column"}>
                                <NewP2
                                    onClick={() => {
                                        handleUpdateProductStatus(item, ProductStatus.PRODUCT_DISPLAY)
                                    }}
                                    colorOnHover={Colors.MAIN}
                                    cursor={"pointer"}
                                    color={Colors.GRAY_7}>
                                    {t('do_product_display')}
                                </NewP2>
                                <NewP2
                                    onClick={() => {
                                        handleUpdateProductStatus(item, ProductStatus.PUBLICATION_SUSPENDED)
                                    }}
                                    colorOnHover={Colors.MAIN}
                                    cursor={"pointer"}
                                    marginTop={"20px"}
                                    color={Colors.GRAY_7}>
                                    {t('stop_product_display')}
                                </NewP2>
                                <NewP2
                                    onClick={() => {
                                        handleDeleteItemOnClick(item)
                                    }}
                                    colorOnHover={Colors.MAIN}
                                    cursor={"pointer"}
                                    marginTop={"20px"}
                                    color={Colors.GRAY_7}>
                                    {t('delete')}
                                </NewP2>
                            </FlexBox>}>
                            <Image
                                width={'24px'}
                                height={'24px'}
                                src={require('assets/images/ic-more-24px.svg').default}
                                alt={`ic-more-${index}`}/>
                        </Popover>
                    </FlexBox>
                </FlexBox>
            })}
        </FlexBox>
    </FlexBox>
}

export default ProductManageFragment
