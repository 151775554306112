/**
 * @author jaeho.lee104 on 2023. 11. 18..
 */
import {NetworkCommon} from "./NetworkCommon";
import {AxiosResponse} from "axios";
import {ResponseCodes} from "./ResponseCodes";
import {CompanyDetailSaveModel} from "../model/CompanyDetailSaveModel";
import {CompanyDetailModel} from "../model/CompanyDetailModel";
import {ProductAddModel} from "../model/ProductAddModel";
import {ProductListItem} from "../model/ProductListItem";
import {ProductDetailModel} from "../fragments/ProductDetailModel";
import {BusinessUserState} from "model/BusinessUserState";

const instance = NetworkCommon.createDefaultAxiosInstance()

export class SolutionAPI {
    static getBusinessUserState(): Promise<BusinessUserState> {
        return instance.get("/solution/user/is-business-user")
            .then((response) => {
                let responseCode = ResponseCodes.of(response?.data?.code)
                if (responseCode.isSuccess()) {
                    return BusinessUserState.ACQUIRED
                } else if (responseCode.isBusinessAccountReviewing()) {
                    return BusinessUserState.REVIEWING
                } else if (responseCode.isNotRegisteredBusinessAccount()) {
                    return BusinessUserState.NOT_REGISTERED
                } else {
                    return BusinessUserState.UNKNOWN
                }
            }).catch((e) => {
                return BusinessUserState.UNKNOWN
            })
    }

    static getCompanyDetail(): Promise<AxiosResponse<BaseResponse<CompanyDetailModel>>> {
        return instance.get("/solution/user/companies")
    }

    static saveCompanyDetail(companyDetailSaveModel: CompanyDetailSaveModel, companyImageFiles: Array<File>, companyIcon: File | null): Promise<AxiosResponse<BaseResponse<null>>> {
        let formData = new FormData()
        formData.append("company", new Blob([JSON.stringify(companyDetailSaveModel)], {
            type: "application/json"
        }))
        companyImageFiles.forEach((item) => {
            formData.append("companyShow", item)
        })
        if (companyIcon) {
            formData.append("companyIcon", companyIcon)
        }
        return instance.post("/solution/user/companies", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    static getProduct(itemId: string): Promise<AxiosResponse<BaseResponse<ProductDetailModel>>> {
        return instance.get(`/solution/item/${itemId}`)
    }

    static getProducts(): Promise<AxiosResponse<BaseResponse<Array<ProductListItem>>>> {
        return instance.get("/solution/item");
    }

    static saveProduct(itemId: string,
                       productModel: ProductAddModel,
                       itemImages: Array<File>,
                       catalogs: Array<File>,
                       packingPictures: Array<File>,
                       specificationImages: Array<File>) {
        let formData = new FormData()
        formData.append("item", new Blob([JSON.stringify(productModel)], {
            type: "application/json"
        }))
        itemImages.forEach((item) => {
            formData.append("itemImages", item)
        })
        catalogs.forEach((item) => {
            formData.append("catalogs", item)
        })
        packingPictures.forEach((item) => {
            formData.append("packingPictures", item)
        })
        specificationImages.forEach((item) => {
            formData.append("specificationImages", item)
        })
        if (itemId.length === 0) {
            return instance.post("/solution/item", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        } else {
            return instance.put(`/solution/item/${itemId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
        }
    }

    static updateProductStatus(itemId: string, itemStatus: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.put(`/solution/item/status?itemId=${itemId}&itemStatus=${itemStatus}`)
    }

    static deleteProductItem(itemId: string): Promise<AxiosResponse<BaseResponse<null>>> {
        return instance.delete(`/solution/item/${itemId}`)
    }

}