import FlexBox from "../styledcomponents/FlexBox";
import CompanyManageFragment from "./CompanyManageFragment";
import NewH1 from "../styledcomponents/text/NewH1";
import {Colors} from "../styledcomponents/Styles";
import NewP2 from "../styledcomponents/text/NewP2";
import NewH7 from "../styledcomponents/text/NewH7";
import {NavigationMenu} from "../model/NavigationMenu";
import {ReactNode, useEffect, useState} from "react";
import ProductManageFragment from "./ProductManageFragment";
import NewButton from "../styledcomponents/NewButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

/**
 * @author jaeho.lee104 on 2023. 11. 20..
 */
export interface MyStoreFragmentProps {
    selectedMenu: NavigationMenu
}

const MyStoreFragment = (props: MyStoreFragmentProps) => {

    const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const {t} = useTranslation()

    useEffect(() => {
        setSelectedMenu(props.selectedMenu)
    }, [props.selectedMenu])

    function geFragmentBySelectedMenu(): ReactNode {
        if (selectedMenu === NavigationMenu.PRODUCT_MANAGE) {
            return <ProductManageFragment/>
        } else if (selectedMenu === NavigationMenu.COMPANY_MANAGE) {
            return <CompanyManageFragment/>
        } else {
            <></>
        }
    }

    return (
        <FlexBox
            width={"1200px"}
            flexDirection={"column"}
            margin={"40px auto"}>
            <FlexBox
                justifyContent={"space-between"}>
                <NewH1
                    color={Colors.GRAY_9}>
                    My Store
                </NewH1>
                <NewButton
                    padding={"14px 16px"}
                    visible={selectedMenu === NavigationMenu.PRODUCT_MANAGE}
                    borderRadius={"12px"}
                    fontSize={"16px"}
                    width={"fit-content"}
                    onClick={() => {
                        navigate(`?page=${NavigationMenu.PRODUCT_ADD}`)
                    }}>
                    {t('add_product')}
                </NewButton>
            </FlexBox>
            <NewP2
                marginTop={"16px"}
                color={Colors.GRAY_7}>
                {t('product_and_company_manage')}
            </NewP2>
            <FlexBox
                marginTop={"40px"}
                marginBottom={"40px"}
                alignItems={"flex-start"}>
                <NewH7
                    paddingBottom={"5px"}
                    onClick={() => {
                        navigate(`?page=${NavigationMenu.PRODUCT_MANAGE}`)
                    }}
                    cursor={"pointer"}
                    fontWeight={selectedMenu === NavigationMenu.PRODUCT_MANAGE ? 700 : undefined}
                    borderBottom={selectedMenu === NavigationMenu.PRODUCT_MANAGE ? `3px solid ${Colors.GRAY_9}` : undefined}
                    color={selectedMenu === NavigationMenu.PRODUCT_MANAGE ? Colors.GRAY_9 : Colors.GRAY_6}>
                    {t('product_manage')}
                </NewH7>
                <NewH7
                    cursor={"pointer"}
                    marginLeft={"24px"}
                    paddingBottom={"5px"}
                    onClick={() => {
                        navigate(`?page=${NavigationMenu.COMPANY_MANAGE}`)
                    }}
                    fontWeight={selectedMenu === NavigationMenu.COMPANY_MANAGE ? 700 : undefined}
                    borderBottom={selectedMenu === NavigationMenu.COMPANY_MANAGE ? `3px solid ${Colors.GRAY_9}` : undefined}
                    color={selectedMenu === NavigationMenu.COMPANY_MANAGE ? Colors.GRAY_9 : Colors.GRAY_6}>
                    {t('company_manage')}
                </NewH7>
                {/*<NewH7*/}
                {/*    paddingBottom={"5px"}*/}
                {/*    marginLeft={"24px"}*/}
                {/*    color={Colors.GRAY_6}>*/}
                {/*    문의 내역*/}
                {/*</NewH7>*/}
            </FlexBox>
            {geFragmentBySelectedMenu()}
        </FlexBox>
    )
}
export default MyStoreFragment