/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import {Dispatch} from "redux";
import {AdminAPI} from "../network/AdminAPI";
import {ResponseCodes} from "../network/ResponseCodes";

enum AdminActionType {
    LOGIN = "users/LOGIN",
}

interface AdminLoginAction {
    type: AdminActionType.LOGIN
    signedIn: boolean
}

type AdminActions = AdminLoginAction

type AdminState = {
    signedIn: boolean
}

const initialState: AdminState = {
    signedIn: false
};

export class Admins {
    static adminLogin = (id: string, password: string) => {
        return (dispatch: Dispatch<AdminActions>) => {
            return AdminAPI.signIn({
                userId: id,
                password: password
            }).then((response) => {
                const responseCode = ResponseCodes.of(response.data.code);
                if (responseCode.isSuccess()) {
                    dispatch({
                        type: AdminActionType.LOGIN,
                        signedIn: true
                    } as AdminLoginAction)
                } else {
                    alert(`[login failed] code: ${responseCode.code}, message: ${responseCode.message}`)
                    dispatch({
                        type: AdminActionType.LOGIN,
                        signedIn: false
                    } as AdminLoginAction)
                }
            }).catch((e) => {
                dispatch({
                    type: AdminActionType.LOGIN,
                    signedIn: false
                } as AdminLoginAction)
            })
        };
    }
}

function adminReducer(state: AdminState = initialState, action: AdminActions): AdminState {
    switch (action.type) {
        case AdminActionType.LOGIN:
            return {
                signedIn: action.signedIn
            }
        default:
            return state;
    }
}

export default adminReducer;
