import {ContentContainer} from "../styledcomponents/Containers";
import React, {useRef, useState} from "react";
import Text from "../styledcomponents/Text";
import {Colors, Fonts} from "../styledcomponents/Styles";
import {useMediaQuery} from "react-responsive";
import Input from "../styledcomponents/Input";
import Button from "../styledcomponents/Button";
import {UsersAPI} from "../network/UsersAPI";
import {ResponseCodes} from "../network/ResponseCodes";
import {RegexUtils} from "../constants/RegexUtils";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

/**
 * @author jaeho.lee104 on 2023. 04. 07..
 */
const InputErrorText = styled(Text)`
  margin-top: 4px;
  font-size: 12px;
  text-align: left;
  color: ${Colors.INPUT_ERROR};
`
const PasswordFindFragment = () => {

    const isMobile = useMediaQuery({
        query: "(max-width:767px)",
    });

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const emailRef = useRef<HTMLInputElement>(null)
    const {t} = useTranslation()

    function handleSendEmailClick() {
        if (!RegexUtils.checkEmail(email)) {
            setEmailError(true)
            emailRef?.current?.focus()
            return
        }
        UsersAPI.findPassword(email)
            .then((response) => {
                const responseCode = ResponseCodes.of(response.data.code)
                if (responseCode.isSuccess()) {
                    alert(t('alert_password_change_link_has_been_sent', {email: email}))
                } else {
                    alert(t('alert_a_temporary_error_has_occurred'))
                }
            })
            .catch((e) => {
                alert(t('alert_a_temporary_error_has_occurred'))
            })
    }

    return (
        <ContentContainer
            width={"100%"}
            maxWidth={"480px"}
            paddingLeft={"20px"}
            paddingRight={"20px"}
            alignItems={"flex-start"}>
            <Text
                marginTop={"160px"}
                fontWeight={Fonts.WEIGHT_700}
                fontSize={"16px"}
                color={Colors.GRAY_900}>
                비밀번호 재설정
            </Text>
            <Text
                marginTop={"20px"}
                fontSize={isMobile ? "24px" : "32px"}
                color={Colors.GRAY_900}
                fontWeight={Fonts.WEIGHT_700}
                textAlign={"left"}>
                비밀번호를 재설정할<br/>이메일을 입력해주세요.
            </Text>
            <Text
                marginTop={"40px"}
                fontSize={"16px"}
                color={Colors.GRAY_900}
                textAlign={"left"}>
                이메일
            </Text>
            <Input
                marginTop={"20px"}
                width={"100%"}
                alignSelf={"center"}
                value={email}
                error={emailError}
                ref={emailRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSendEmailClick()
                    }
                }}
                onChange={(e) => {
                    if (emailError) {
                        setEmailError(false)
                    }
                    setEmail(e.target.value)
                }}/>
            {emailError && <InputErrorText>이메일 형식에 맞게 입력해주세요.</InputErrorText>}
            <Button
                marginTop={"36px"}
                width={"100%"}
                marginBottom={"200px"}
                alignSelf={"center"}
                onClick={handleSendEmailClick}>
                이메일로 보내기
            </Button>
        </ContentContainer>
    )
}
export default PasswordFindFragment;