import {CommonProps} from "../styledcomponents/CommonComponent";
import React, {FC} from "react";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Text from "../styledcomponents/Text";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Shadows} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import NewH6 from "../styledcomponents/text/NewH6";
import {CodeModel} from "../model/CodeModel";

/**
 * @author jaeho.lee104 on 2023. 11. 23..
 */

const theme = createTheme({
    shadows: Array(25).fill("0px 4px 16px 0px rgba(0, 0, 0, 0.08);") as Shadows,
    components: {
        MuiPaper: {
            defaultProps: {
                sx: {
                    background: Colors.WHITE,
                    padding: "4px",
                    marginTop: "4px",
                    borderRadius: "12px",
                    boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.05);",
                    border: `1px solid ${Colors.GRAY_3}`
                }
            }
        },
        MuiList: {
            defaultProps: {
                sx: {
                    padding: "0px",
                }
            }
        },
    },
    palette: {
        action: {
            selected: Colors.MAIN,
        }
    }
});

export interface SelectBoxProps extends CommonProps {
    items: Array<CodeModel>;
    labelVisible?: boolean;
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    itemFontSize?: string
    width?: string;
    height?: string;

    //input
    onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
    value: string
}

const SelectBox: FC<SelectBoxProps> = ({
                                           items = [],
                                           labelVisible = true,
                                           label = "",
                                           labelGap = "8px",
                                           labelFontSize = "14px",
                                           labelColor = Colors.GRAY_7,
                                           labelFontWight = 500,
                                           itemFontSize = "16px",
                                           width = "120px",
                                           height = "52px",
                                           ...props
                                       }) => {

    // @ts-ignore
    return <FlexBox flexDirection={"column"}
                    alignItems={"flex-start"}
                    width={width}
                    {...props}>
        {labelVisible &&
            <Text fontSize={labelFontSize}
                  color={labelColor}
                  lineHeight={"150%"}
                  marginBottom={labelGap}
                  fontWeight={labelFontWight}>{label}</Text>}
        <ThemeProvider theme={theme}>
            <FormControl sx={{
                minWidth: width,
                alignSelf: "flex-start",
            }}>
                {!labelVisible && <InputLabel
                    sx={{
                        fontSize: labelFontSize,
                        fontWeight: labelFontWight
                    }}
                    id="select-label-id">{label}</InputLabel>}
                <Select<string>
                    IconComponent={(props) => (<ExpandMoreOutlined {...props}/>)}
                    labelId={"select-label-id"}
                    input={labelVisible ? undefined : <OutlinedInput label={label}/>}
                    value={props.value}
                    placeholder={'Choose One'}
                    displayEmpty={true}
                    onChange={props.onChange}
                    renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                            return <NewH6
                                visible={labelVisible}
                                width={"100%"}
                                textAlign={"center"}>Choose One</NewH6>
                        }
                        return <NewH6
                            width={"100%"}
                            textAlign={"center"}
                            alignSelf={"center"}>
                            {items.find((item) => item.code === selected)?.name || selected}
                        </NewH6>
                    }}
                    sx={{
                        fontSize: itemFontSize,
                        borderRadius: "12px",
                        color: Colors.GRAY_8,
                        fontWeight: 500,
                        height: height,
                        "&& fieldset": {
                            border: `1px solid ${Colors.GRAY_4}`
                        },
                        "&:hover": {
                            "&& fieldset": {
                                border: `1px solid ${Colors.CTA_HOVER}`
                            }
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: `2px solid ${Colors.MAIN}`
                        },
                        "&& MuiList-root": {
                            border: "1px solid"
                        }

                    }}>
                    {items.map((item, index) => (
                        <MenuItem
                            sx={{
                                margin: "4px",
                                backgroundColor: undefined,
                                fontSize: itemFontSize,
                                borderRadius: "12px",
                                color: Colors.GRAY_8,
                                fontWeight: 500,
                                height: height
                            }}
                            value={item.code}>{item.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </ThemeProvider>
    </FlexBox>
}

export default SelectBox