/**
 * @author jaeho.lee104 on 2023. 11. 08..
 */
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import FlexBox from "../styledcomponents/FlexBox";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../modules";
import {Colors, Sizes} from "../styledcomponents/Styles";
import NewH4 from "../styledcomponents/text/NewH4";
import VerticalDivider from "../styledcomponents/VerticalDivider";
import MyStoreFragment from "./MyStoreFragment";
import {NavigationMenu} from "../model/NavigationMenu";
import ProductAddFragment from "./ProductAddFragment";
import {Users} from "../modules/Users";
import {UsersAPI} from "../network/UsersAPI";
import {useTranslation} from "react-i18next";
import {BusinessUserState} from "model/BusinessUserState";

const NewHomeFragment = () => {

    const navigate = useNavigate()
    const {businessUserState, accessToken} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const [selectedMenu, setSelectedMenu] = useState(NavigationMenu.COMPANY_MANAGE)
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch<any>()
    const {t} = useTranslation()

    useEffect(() => {
        if (!accessToken) {
            dispatch(Users.refreshToken(navigate))
            return
        }
    }, [accessToken])
    useEffect(() => {
        if (accessToken) {
            UsersAPI.getUserOrNull()
                .then((user) => {
                    dispatch(Users.updateUser(user))
                })
        }
    }, [accessToken])
    useEffect(() => {
        if (accessToken) {
            dispatch(Users.checkBusinessUser())
        }
    }, [accessToken])

    useEffect(() => {
        if (businessUserState !== BusinessUserState.INIT && businessUserState !== BusinessUserState.ACQUIRED) {
            navigate("/login")
        }
    }, [businessUserState])

    useEffect(() => {
        let param = searchParams.get('page') || NavigationMenu.PRODUCT_MANAGE
        setSelectedMenu(Number(param))
    }, [searchParams])

    function getFragmentBySelectedMenu(): React.ReactNode {
        if (!accessToken || businessUserState !== BusinessUserState.ACQUIRED) {
            return <></>
        }
        if (selectedMenu === NavigationMenu.PRODUCT_ADD) {
            return <ProductAddFragment/>
        } else if (selectedMenu === NavigationMenu.PRODUCT_MANAGE
            || selectedMenu === NavigationMenu.COMPANY_MANAGE) {
            return <MyStoreFragment selectedMenu={selectedMenu}/>
        } else {
            return <></>
        }
    }

    return (
        <FlexBox
            width={"100%"}
            minWidth={Sizes.CONTENT_WIDTH}
            height={"100%"}>
            <FlexBox
                flexDirection={"column"}
                width={"240px"}
                maxWidth={"240px"}
                backgroundColor={Colors.WHITE}
                padding={"48px 40px"}>
                <FlexBox
                    flexDirection={"column"}>
                    {/*<NewH4*/}
                    {/*    color={Colors.GRAY_8}>*/}
                    {/*    HOME*/}
                    {/*</NewH4>*/}
                    {/*<FlexBox*/}
                    {/*    marginTop={"24px"}>*/}
                    {/*    <VerticalDivider*/}
                    {/*        backgroundColor={Colors.GRAY_3}*/}
                    {/*        height={"100%"}*/}
                    {/*        width={"4px"}/>*/}
                    {/*    <FlexBox*/}
                    {/*        flexDirection={"column"}>*/}
                    {/*        <NewH4*/}
                    {/*            color={Colors.GRAY_4}*/}
                    {/*            marginLeft={"16px"}>*/}
                    {/*            대시보드*/}
                    {/*        </NewH4>*/}
                    {/*        <NewH4*/}
                    {/*            color={Colors.GRAY_4}*/}
                    {/*            marginTop={"16px"}*/}
                    {/*            marginLeft={"16px"}>*/}
                    {/*            업데이트*/}
                    {/*        </NewH4>*/}
                    {/*        <NewH4*/}
                    {/*            color={Colors.GRAY_4}*/}
                    {/*            marginTop={"16px"}*/}
                    {/*            marginLeft={"16px"}>*/}
                    {/*            바이어 찾기*/}
                    {/*        </NewH4>*/}
                    {/*        <NewH4*/}
                    {/*            color={Colors.GRAY_4}*/}
                    {/*            marginTop={"16px"}*/}
                    {/*            marginLeft={"16px"}>*/}
                    {/*            더보기*/}
                    {/*        </NewH4>*/}
                    {/*    </FlexBox>*/}
                    {/*</FlexBox>*/}
                    <NewH4
                        marginTop={"12px"}
                        color={Colors.GRAY_8}>
                        MY STORE
                    </NewH4>
                    <FlexBox
                        marginTop={"24px"}>
                        <VerticalDivider
                            backgroundColor={Colors.GRAY_3}
                            height={"100%"}
                            width={"4px"}/>
                        <FlexBox
                            flexDirection={"column"}>
                            <NewH4
                                textAlign={"left"}
                                cursor={"pointer"}
                                color={selectedMenu === NavigationMenu.PRODUCT_MANAGE ? Colors.GRAY_9 : Colors.GRAY_4}
                                onClick={() => {
                                    navigate(`?page=${NavigationMenu.PRODUCT_MANAGE}`)
                                }}
                                marginLeft={"16px"}>
                                {t('product_manage')}
                            </NewH4>
                            <NewH4
                                textAlign={"left"}
                                color={selectedMenu === NavigationMenu.COMPANY_MANAGE ? Colors.GRAY_9 : Colors.GRAY_4}
                                cursor={"pointer"}
                                marginTop={"16px"}
                                onClick={() => {
                                    navigate(`?page=${NavigationMenu.COMPANY_MANAGE}`)
                                }}
                                marginLeft={"16px"}>
                                {t('company_manage')}
                            </NewH4>
                            {/*<NewH4*/}
                            {/*    color={Colors.GRAY_4}*/}
                            {/*    marginTop={"16px"}*/}
                            {/*    marginLeft={"16px"}>*/}
                            {/*    문의 내역*/}
                            {/*</NewH4>*/}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
            <FlexBox
                flexGrow={1}
                backgroundColor={Colors.GRAY_0}>
                {getFragmentBySelectedMenu()}
            </FlexBox>
        </FlexBox>
    )
}
export default NewHomeFragment;