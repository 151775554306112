import React from "react";
import Image from "../styledcomponents/Image";
import {Colors} from "../styledcomponents/Styles";
import {useLocation, useNavigate} from "react-router-dom";
import NewButton from "../styledcomponents/NewButton";
import FlexBox from "../styledcomponents/FlexBox";
import NewH3 from "styledcomponents/text/NewH3";
import NewP2 from "../styledcomponents/text/NewP2";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../modules";
import {useTranslation} from "react-i18next";

/**
 * @author jaeho.lee104 on 2023. 11. 06..
 */
const CompanySignUpDoneFragment = () => {
    const {state} = useLocation();
    const {signedIn} = useSelector((state: RootState) => state.usersReducer, shallowEqual);
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <FlexBox
            margin={"110px auto"}
            alignSelf={"center"}
            border={`1px solid ${Colors.GRAY_2}`}
            borderRadius={'12px'}
            backgroundColor={Colors.WHITE}
            boxShadow={`0px 4px 16px 0px rgba(0, 0, 0, 0.08);`}
            flexDirection={"column"}
            width={"100%"}
            alignItems={"center"}
            padding={"32px"}
            maxWidth={"480px"}>
            <Image
                width={"193px"}
                height={"147px"}
                src={require("assets/images/img-signup-done.svg").default}
                alt="sign-up-done"
                marginTop={"48px"}/>
            <NewH3
                marginTop={"40px"}
                color={Colors.GRAY_9}>
                {t('signup_done', {signupType: signedIn ? t('signup_register_request') : t('signup_register_completed')})}
            </NewH3>
            <NewP2
                textAlign={"center"}
                marginTop={"16px"}
                color={Colors.GRAY_7}>
                {t('signup_reviewing_intro')}
            </NewP2>
            <NewButton
                borderRadius={"12px"}
                margin={"32px"}
                fontSize={"16px"}
                onClick={() => {
                    navigate("/")
                }}>
                {t('return_home')}
            </NewButton>

        </FlexBox>
    )
}
export default CompanySignUpDoneFragment;