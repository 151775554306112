/**
 * @author jaeho.lee104 on 2023. 11. 06..
 */

export class CompanyUserModel {
    userId: string //required
    password: string //required
    name: string //required
    phoneNumber: string //required
    countryNumber: string //required
    signUpType: string
    companyNm: string //required
    companyAddress: string //required
    customsAuthType: string
    customsAuthNum: string
    customsAuthNumYn: string
    impExpExperience: boolean
    countryCode: string //required
    businessLicenseType: string //required
    businessLicenseNum: string //required
    majorTradingCountries: string
    website: string

    constructor(userId: string, password: string, name: string, phoneNumber: string, countryNumber: string,
                companyNm: string, companyAddress: string, customsAuthType: string, customsAuthNum: string, customsAuthNumYn: string,
                impExpExperience: boolean, countryCode: string, businessLicenseType: string, businessLicenseNum: string,
                majorTradingCountries: string, website: string) {
        this.userId = userId;
        this.password = password;
        this.name = name;
        this.phoneNumber = phoneNumber;
        this.countryNumber = countryNumber;
        this.signUpType = "COMPANY";
        this.companyNm = companyNm;
        this.companyAddress = companyAddress;
        this.customsAuthType = customsAuthType;
        this.customsAuthNum = customsAuthNum;
        this.customsAuthNumYn = customsAuthNumYn;
        this.impExpExperience = impExpExperience;
        this.countryCode = countryCode;
        this.businessLicenseType = businessLicenseType;
        this.businessLicenseNum = businessLicenseNum;
        this.majorTradingCountries = majorTradingCountries;
        this.website = website;
    }

    static DummyCompanyUser = new CompanyUserModel(
        "jaeho@thirdworks.co.kr",
        "test",
        "testname",
        "01012341234",
        "82",
        "testrealcompanyname",
        "korea",
        "P",
        "0000000000",
        "Y",
        true,
        "KR",
        "COR",
        "00000000000",
        "한국,미국,중국",
        "https://www.thirdworks.co.kr"
    )
}