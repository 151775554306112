import {ProductCategory} from "./ProductCategory";
import {ProductCertification} from "./ProductCertification";
import {ProductInfo} from "./ProductInfo";
import {ProductPacking} from "./ProductPacking";
import {ProductSourcing} from "./ProductSourcing";
import {ProductSpecification} from "./ProductSpecification";
import {ProductSummary} from "./ProductSummary";

/**
 * @author jaeho.lee104 on 2023. 12. 05..
 */
export type ProductAddModel = {
    categoryList: Array<ProductCategory>
    certificationList: Array<ProductCertification>
    info: ProductInfo
    itemStatus: string
    keyword?: string
    packing?: ProductPacking
    sourcing?: ProductSourcing
    specification?: ProductSpecification
    summary?: ProductSummary
}

export const EMPTY_PRODUCT_ADD_MODEL: ProductAddModel = {
    categoryList: [],
    certificationList: [],
    info: {
        itemHsCode: "",
        itemName: "",
        itemPrice: 0,
        itemPriceUnitCode: "",
        sampleAvailable: false,
        samplePrice: 0,
        samplePriceUnitCode: ""
    },
    itemStatus: "TEMP_DRAFT",
    keyword: "",
    packing: {
        loadingCapacity: "",
        loadingCapacityUnitCode: "",
        loadingPort: "",
        loadingPortCountryCode: "",
        packingUnitCode: "",
        productPacking: ""
    },
    sourcing: {
        approvedExporterId: "",
        buyersCostCode: "",
        koreanLabel: false,
        odm: false,
        oem: false,
        productGradeCode: ""
    },
    specification: {
        allergens: false,
        allergensMore: "",
        appearance: "",
        application: "",
        ingredients: "",
        shelfLife: "",
        taste: ""
    },
    summary: {
        leadTime: "",
        moq: "",
        moqUnitCode: "",
        originCode: ""
    }
}