import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "modules";
import ScrollToTop from "./components/ScrollToTop";
import * as Sentry from "@sentry/react";
import {LocalStorageManager} from "./LocalStorageManager";
// noinspection ES6UnusedImports
import i18n from "./i18n";

Sentry.init({
    dsn: "https://c8e8e34966734e9d9b0c538d31e1911e@o4505131409473536.ingest.sentry.io/4505131410980864",
    debug: true,
    integrations: function (integrations) {
        // integrations will be all default integrations
        return integrations.filter(function (integration) {
            return integration.name !== "Dedupe";
        });
    },
    environment: "testing", //testing, development, staging, production.
    release: "0.1.0",
    maxBreadcrumbs: 50,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    initialScope: (scope) => {
        scope.setUser({
            id: LocalStorageManager.getUserId() || "notLogin"
        })
        return scope
    },
    // denyUrls: ["localhost"],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    beforeSend: (event) => {
        console.log("beforeSendSentry", event) //production 에서는 제거
        return event
    }
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop/>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);
