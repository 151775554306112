/**
 * @author jaeho.lee104 on 2023. 11. 23..
 */
import {InputAdornment, TextField} from "@mui/material";
import React, {ChangeEventHandler, FC, HTMLInputTypeAttribute} from "react";
import {CommonProps} from "../styledcomponents/CommonComponent";
import {Colors} from "../styledcomponents/Styles";
import FlexBox from "../styledcomponents/FlexBox";
import Text from "../styledcomponents/Text";
import {createTheme, ThemeProvider} from '@mui/material/styles';

export interface NTextFieldProps extends CommonProps {
    hint?: string;
    error?: boolean;
    errorText?: string;
    errorTextGap?: string;
    errorFontSize?: string;
    label?: string;
    labelGap?: string;
    labelFontSize?: string;
    labelColor?: string;
    labelFontWight?: number | "normal";
    labelVisible?: boolean;
    maxLength?: number;
    disabled?: boolean;
    enabledClearButton?: boolean;
    onClear?: () => void
    enabledEndAdornment?: boolean
    endAdornment?: React.ReactNode

    // input
    type?: HTMLInputTypeAttribute | undefined;
    value?: string | ReadonlyArray<string> | number | undefined;
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}


const theme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    paddingLeft: "8px",
                }
            },
            defaultProps: {
                sx: {
                    borderRadius: "12px",
                    height: "52px",
                    "&& fieldset": {
                        border: `1px solid ${Colors.GRAY_4}`
                    },
                    "&:hover": {
                        "&& fieldset": {
                            border: `1px solid ${Colors.CTA_HOVER}`
                        },
                    },
                    "&.Mui-focused": {
                        "&:hover": {
                            "&& fieldset": {
                                border: `2px solid ${Colors.MAIN}`
                            },
                        },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${Colors.MAIN}`
                    },
                }
            },
        },
    },
});

const NTextField: FC<NTextFieldProps> = ({
                                             endAdornment = null,
                                             enabledEndAdornment = false,
                                             disabled = undefined,
                                             maxLength = undefined,
                                             labelVisible = true,
                                             label = "",
                                             enabledClearButton = false,
                                             error = false,
                                             errorText = "",
                                             errorTextGap = "12px",
                                             errorFontSize = "14px",
                                             labelGap = "8px",
                                             labelFontSize = "14px",
                                             labelColor = Colors.GRAY_7,
                                             labelFontWight = 500,
                                             hint = "",
                                             onClear = () => {
                                             },
                                             ...props
                                         }) => {

    return <FlexBox
        flexDirection={"column"}
        alignItems={"flex-start"}
        {...props}>
        {labelVisible && <Text fontSize={labelFontSize}
                               color={labelColor}
                               lineHeight={"150%"}
                               marginBottom={labelGap}
                               fontWeight={labelFontWight}>{label}</Text>}
        <ThemeProvider theme={theme}>
            <TextField
                value={props.value}
                fullWidth={true}
                maxRows={1}
                multiline={false}
                error={error}
                helperText={errorText}
                placeholder={hint}
                onChange={props.onChange}
                InputProps={{
                    endAdornment: (
                        <>
                            {enabledEndAdornment &&
                                <InputAdornment
                                    position="end">
                                    {endAdornment}
                                </InputAdornment>}
                        </>
                    ),
                }}
            />
        </ThemeProvider>
    </FlexBox>
}

export default NTextField